


































































































































































































































































































































































































































import { AlertBanner, FileDropzone, FormBlock, JsonParser, SvgImage, TwButton, TwProgressBar } from '@/app/components';
import { useErrors, useFilters, useQuery, useResult } from '@/app/composable';
import { requiredValidator } from '@/app/validators';
import { DocumentIcon } from '@vue-hero-icons/outline';
import { computed, defineComponent, ref, watch } from '@vue/composition-api';
import * as R from 'ramda';
import { ValidationObserver, ValidationProvider, extend } from 'vee-validate';
import { parseString, processors } from 'xml2js';
import { ResponseHandling, SampleUpload } from '../../components';
import { useHarvester } from '../../composable';
import GET_JOB_WITH_STEPS from '../../graphql/getJobWithEnabledSteps.graphql';

extend('required', requiredValidator);

extend('filepath', {
    message: (field, args) => {
        const [filetype] = args ? args[0].split(',') : '';
        return `The specified ${field} is not a valid path\n Sample path for linux: /home/my_data/my_file${filetype}\n Sample path for Windows: C:\\Documents\\my_data\\my_file${filetype}`;
    },
    validate: (value, args) => {
        let filetype: string = args[0];
        filetype = filetype.split(',').join('|');
        const winPath = new RegExp(`([a-zA-Z]:)?(\\\\[a-z  A-Z0-9_.-]+)+\\\\?(${filetype})$`);

        const linuxPath = new RegExp(`^(/[^/]*)+/?(${filetype})$`);

        return winPath.test(value) || linuxPath.test(value);
    },
});

export interface FilesData {
    sample: File | null;
    data: File[] | null;
}

export default defineComponent({
    name: 'FilesConfiguration',
    model: {
        prop: 'configuration',
    },
    components: {
        FormBlock,
        ValidationProvider,
        FileDropzone,
        TwButton,
        ValidationObserver,
        JsonParser,
        SvgImage,
        TwProgressBar,
        ResponseHandling,
        SampleUpload,
        AlertBanner,
        DocumentIcon,
    },
    props: {
        jobId: {
            type: Number,
            required: true,
        },
        jobConfig: {
            type: Object,
        },
        configuration: {
            type: Object,
            required: true,
        },
        sample: {
            type: [Object, Array],
            required: false,
        },
        processedSample: {
            type: Array,
            required: false,
        },
        parsedSample: {
            type: [Object, Array],
        },
        files: {
            type: Object,
            required: true,
        },
        activeTab: {
            type: Number,
            required: true,
        },
        completed: {
            type: Boolean,
            default: true,
        },
        canUploadMore: {
            type: Boolean,
            default: false,
        },
        isJobCompleted: {
            type: Boolean,
            default: false,
        },
        isOnPremise: {
            type: Boolean,
            default: false,
        },
        basePath: {
            type: String,
            required: true,
        },
        isOnUpdate: {
            type: Boolean,
            default: false,
        },
        skipSampleRun: {
            type: Boolean,
            default: false,
        },
    },
    setup(props, { emit, root }) {
        const CSV_SAMPLE_LIMIT = 10;
        const { formatBytes } = useFilters();
        const filesValidationRef = ref<any>(null);
        const sampleRef = ref<any>(null);
        const fileRef = ref<any>(null);
        const inputFilename = ref<any>(null);
        const dropzoneRef = ref<any>(null);
        const necessaryFields = ref<any>(null);
        const extraInvalidFields = ref<any>(null);
        const loading = ref<any>(false);
        const validating = ref<boolean>(false);
        const separator = '||';
        const finalSample = ref<any>(props.sample);

        const errorAlert: any = ref({
            title: null,
            body: {
                necessary: null,
                invalid: null,
            },
        });

        const sampleFile = computed(() => props.files.sample);
        const uploadFile = computed(() => props.files.data?.[0]);
        const disableFileTypeChange = computed(() => props.completed || props.isOnUpdate);

        const fileTypeRef = computed(() => props.configuration.fileType);

        const {
            checkInvalidXML,
            reduceSampleValues,
            changeFinalSample,
            invalidFormat,
            emptyFile,
            valPercentage,
            noCSVData,
            parseCSV,
            acceptedFiles,
            checkInvalidJSON,
            checkInvalidCSV,
            checkInvalidParquet,
            showValidationBar,
        } = useHarvester(root, emit, fileTypeRef, validating);

        const { checkGQLAuthentication } = useErrors(root.$route);
        const { result: jobResult, onError } = useQuery(
            GET_JOB_WITH_STEPS,
            { id: props.jobId },
            { fetchPolicy: 'no-cache' },
        );
        onError(checkGQLAuthentication);
        const job = useResult(jobResult, null, (data: any) => data.job);
        const mappingStepExists = computed(() => {
            if (
                job.value &&
                job.value.dataCheckinJobSteps.find((step: any) => step.dataCheckinStepType.name === 'mapping')
            ) {
                return true;
            }
            return false;
        });

        const fileBlockInfo = computed(() => {
            if (props.canUploadMore && !props.isOnPremise) {
                return {
                    title: 'Upload Additional File(s)',
                    description: 'Upload additional file(s) to be processed (if in csv, json, xml format).',
                };
            }
            if (props.isOnPremise)
                return {
                    title: 'File(s) path',
                    description: 'Provide your file(s) paths to be processed (if in csv, json, xml, parquet format)',
                };
            return {
                title: 'Upload File(s)',
                description: 'Upload your file(s) to be processed (if in csv, json, xml, parquet format)',
            };
        });
        const imageBasedOnFiletype = computed(() =>
            props.configuration.fileType === 'other' ? '/img/files_uploaded.svg' : '/img/no_data.svg',
        );

        const messageBasedOnFiletype = computed(() =>
            props.configuration.fileType === 'other' ? 'Uploaded successfully' : 'No data sample uploaded',
        );

        const clearErrorAlert = () => {
            errorAlert.value = {
                title: null,
                body: {
                    necessary: null,
                    invalid: null,
                },
            };
        };

        const validateAndProceed = async () => {
            if (filesValidationRef.value) {
                const valid = await filesValidationRef.value.validate();
                if (valid) {
                    if (props.configuration.isSampleCropped && errorAlert.value.title) {
                        await clearErrorAlert();
                    }
                    if (!props.processedSample && props.configuration.fileType === 'parquet') {
                        emit('execute-sample-run', true);
                    } else {
                        emit('next-tab');
                    }
                }
            }
        };

        const validate = async () => {
            return filesValidationRef.value.validate();
        };

        const getKeys = (obj: any, path = ''): any => {
            if (!obj || typeof obj !== 'object') return path;
            return Object.keys(obj)
                .map((key) => getKeys(obj[key], path ? [path, key].join('.') : key))
                .concat(Number.isNaN(Number(path)) ? [path] : []);
        };

        const getAllPaths = (obj: any) => {
            const allKeys = getKeys(obj).toString().split(',');
            const keys = allKeys.map((key: string) =>
                key
                    .split('.')
                    .filter((keyPart: string) => Number.isNaN(Number(keyPart)))
                    .join('.'),
            );
            return [...new Set(keys)];
        };

        /**
         * Compares the csv headers (columns) of the sample file with the csv headers of the full file to be uploaded.
         * If a header/ column in either csv file (sample or full file) is empty, then in the error displayed to the user,
         * it is written as 'empty column header'
         * @param data CSV text data to be parsed
         */
        const calculateInconsistenciesCSV = async (file: any) => {
            const results = await parseCSV(file, false, 10);
            const headers = results.meta.fields;
            if (!R.isNil(props.sample) && !R.isEmpty(props.sample)) {
                const sampleHeaders = props.configuration.params.fields;
                necessaryFields.value = sampleHeaders.filter((h: any) => headers.indexOf(h) === -1);
                necessaryFields.value.forEach((w: any) => {
                    if (!w) {
                        const idx = necessaryFields.value.indexOf(w);
                        necessaryFields.value[idx] = 'empty column header';
                    }
                });

                extraInvalidFields.value = headers.filter((h: any) => sampleHeaders.indexOf(h) === -1);
                extraInvalidFields.value.forEach((w: any) => {
                    if (!w) {
                        const idx = extraInvalidFields.value.indexOf(w);
                        extraInvalidFields.value[idx] = 'empty column header';
                    }
                });

                // duplicate headers of file are considered as extra invalid fields
                const duplicateHeadersFile = headers.filter(
                    (header: any, idx: number) => headers.indexOf(header) !== idx,
                );
                if (duplicateHeadersFile.length) {
                    extraInvalidFields.value = extraInvalidFields.value.concat(duplicateHeadersFile);
                }
            }
        };

        const calculateFilteredPaths = (paths: any) => {
            const filteredPaths: any = [];
            paths.forEach((p: any) => {
                let pathWithoutSeparator = p.split('||');
                pathWithoutSeparator = pathWithoutSeparator.filter((v: any) => v !== '');
                if (!filteredPaths.find((fp: any) => fp.toString() === pathWithoutSeparator.toString())) {
                    filteredPaths.push(pathWithoutSeparator);
                }
            });
            return filteredPaths;
        };

        /**
         * Compares the structure of the uploaded sample with the full file to be uploaded
         * @param file Full file to be uploaded
         * @param fileType Format of full file to be uploaded (json, xml, csv)
         */
        const compareSampleAndFile = async (file: any, fileType: string) => {
            let data = null;

            // extract/ parse the text data from the file
            if (fileType === 'xml') {
                // parse XML
                const addAt = (attrName: string) => {
                    return `@${attrName}`;
                };
                const xmlData = await file.text();
                parseString(
                    xmlData,
                    {
                        attrkey: '@',
                        charkey: '$',
                        explicitCharkey: false,
                        trim: true,
                        emptyTag: {},
                        explicitArray: false,
                        mergeAttrs: true,
                        attrNameProcessors: [addAt],
                        attrValueProcessors: [processors.parseNumbers, processors.parseBooleans],
                        valueProcessors: [processors.parseNumbers, processors.parseBooleans],
                    },
                    (err, result) => {
                        if (!err) {
                            if (R.type(result) === 'Object') {
                                data = [result];
                            } else {
                                data = result;
                            }
                        }
                    },
                );
            } else {
                data = await file.text();
            }

            if (fileType === 'csv') {
                await calculateInconsistenciesCSV(file);
            }

            if (fileType === 'json' || fileType === 'xml') {
                let fileUpload: any = fileType === 'json' ? JSON.parse(data) : data;
                if (props.configuration.isSampleCropped) {
                    fileUpload = await reduceSampleValues(fileUpload);
                }

                // remove any paths which are identical
                const paths: any = getAllPaths(props.sample);
                const invalidPaths: any = getAllPaths(fileUpload);

                necessaryFields.value = [];
                extraInvalidFields.value = [];

                const filteredPaths = await calculateFilteredPaths(paths);
                const filteredInvalidPaths = await calculateFilteredPaths(invalidPaths);

                // calculate which fields exist in the sample, but not in the full file to be uploaded
                filteredPaths.forEach((fp: any) => {
                    if (!filteredInvalidPaths.find((ip: any) => ip.toString() === fp.toString())) {
                        necessaryFields.value.push(fp);
                    }
                });

                // calculate which fields exist in the full file to be uploaded, but not in the sample
                filteredInvalidPaths.forEach((fp: any) => {
                    if (!filteredPaths.find((ip: any) => ip.toString() === fp.toString())) {
                        extraInvalidFields.value.push(fp);
                    }
                });

                // remove any paths which are identical
                necessaryFields.value = [...new Set(necessaryFields.value)];
                extraInvalidFields.value = [...new Set(extraInvalidFields.value)];
            }

            if (necessaryFields.value && necessaryFields.value.length) {
                invalidFormat.value = true;
                errorAlert.value.title = `Inconsistencies detected between the Sample and the File "${file.name}"`;
                (root as any).$toastr.e('File and Sample do not have the same structure!', 'Error');
            } else if (props.configuration.isSampleCropped) {
                invalidFormat.value = false;
                errorAlert.value.title = `Inconsistencies may appear between the Sample and the File "${file.name}", because the Sample has been cropped.`;
            } else {
                invalidFormat.value = false;
                errorAlert.value.title = null;
                errorAlert.value.body = {
                    necessary: null,
                    invalid: null,
                };
            }
        };

        const filesAdded = (data: File[]) => {
            emit('files-changed', { data });
        };

        const clearActualFile = (resetConfiguration = false) => {
            emit('files-changed', { data: null });
            props.configuration.files = [];
            if (resetConfiguration) {
                props.configuration.response = {
                    basePath: null,
                    multiple: false,
                    selectedItems: [],
                };
            }
        };

        const filetypeValidation = async (filetype: string, files: any) => {
            if (emptyFile.value) {
                clearActualFile();
                (root as any).$toastr.e(`Empty ${filetype} file!`, 'Error');
            } else if (invalidFormat.value) {
                clearActualFile();
                if (!errorAlert.value.title) {
                    (root as any).$toastr.e(`Invalid ${filetype} format!`, 'Error');
                }
            } else if (noCSVData.value) {
                (root as any).$toastr.e('The CSV file contains no data!', 'No Data!');
                clearActualFile();
            } else {
                emit('files-changed', { data: files });
            }
        };

        const fileUploaded = async (event: any) => {
            const { files } = event.target;
            const uploadedFile = files[0];
            const filename = uploadedFile.name;
            clearErrorAlert();

            if (!props.configuration.files.includes(filename)) {
                await emit('set-loading', true);
                loading.value = true;
                if (props.configuration.fileType === 'json') {
                    await checkInvalidJSON(uploadedFile);

                    if (!invalidFormat.value && !emptyFile.value) {
                        await compareSampleAndFile(R.clone(uploadedFile), 'json');
                    }
                    await filetypeValidation('JSON', files);
                } else if (props.configuration.fileType === 'xml') {
                    await checkInvalidXML(R.clone(uploadedFile));
                    if (!invalidFormat.value && !emptyFile.value) {
                        await compareSampleAndFile(uploadedFile, 'xml');
                    }
                    await filetypeValidation('XML', files);
                } else if (props.configuration.fileType === 'csv') {
                    validating.value = true;
                    emit('files-changed', { data: null });
                    await checkInvalidCSV(uploadedFile);
                    if (!invalidFormat.value && !emptyFile.value) {
                        await compareSampleAndFile(uploadedFile, 'csv');
                    }
                    await filetypeValidation('CSV', files);
                } else if (props.configuration.fileType === 'parquet') {
                    await checkInvalidParquet(uploadedFile);
                    await filetypeValidation('parquet', files);
                } else {
                    emit('files-changed', { data: files });
                }
            } else {
                (root as any).$toastr.e(`File with filname "${filename} already uploaded!`, 'Error');
                emit('files-changed', { data: null });
            }
            await validate();
            await emit('set-loading', false);
            loading.value = false;
        };

        const modifyFinalSample = (sample: any) => {
            finalSample.value = sample;
            changeFinalSample(sample, props.configuration.source);
        };

        const changeConfig = (value: any) => {
            emit('job-config-change', value);
        };

        const setLoading = (loadingValue: boolean) => {
            loading.value = loadingValue;
            emit('set-loading', loadingValue);
        };

        const setErrorAlert = (value: string | null) => {
            errorAlert.value.title = value;
        };

        // Checks if the CSV sample contains columns with only invalid dates
        const invalidSampleColumns = computed(() => {
            const invalidColumns: string[] = [];
            if (props.configuration.fileType !== 'csv') return [];
            let croppedSample: any | any[] = R.clone(props.sample);

            if (!R.is(Array, croppedSample)) {
                croppedSample = [croppedSample];
            }

            croppedSample = croppedSample?.slice(0, CSV_SAMPLE_LIMIT);

            const reversedSample: any = croppedSample?.reduce((acc: any, row: any) => {
                Object.keys(row).forEach((key: string) => {
                    if (!acc[key]) acc[key] = [];
                    acc[key].push(row[key]);
                });
                return acc;
            }, {});

            if (reversedSample)
                Object.keys(reversedSample).forEach((key: string) => {
                    if (reversedSample[key].every((value: any) => value instanceof Date && isNaN(value as any))) {
                        invalidColumns.push(key);
                    }
                });

            return invalidColumns;
        });

        watch(dropzoneRef, () => {
            if (dropzoneRef.value && props.files.data) {
                const files = R.clone(props.files.data);
                files.forEach((file: File) => {
                    dropzoneRef.value.addFile(file);
                });
            }
        });

        return {
            CSV_SAMPLE_LIMIT,
            fileBlockInfo,
            acceptedFiles,
            disableFileTypeChange,
            dropzoneRef,
            fileRef,
            filesAdded,
            fileUploaded,
            filesValidationRef,
            formatBytes,
            sampleFile,
            sampleRef,
            uploadFile,
            validate,
            validateAndProceed,
            mappingStepExists,
            errorAlert,
            necessaryFields,
            extraInvalidFields,
            imageBasedOnFiletype,
            messageBasedOnFiletype,
            loading,
            inputFilename,
            valPercentage,
            validating,
            showValidationBar,
            separator,
            modifyFinalSample,
            finalSample,
            changeConfig,
            noCSVData,
            clearErrorAlert,
            setLoading,
            setErrorAlert,
            invalidSampleColumns,
            clearActualFile,
        };
    },
});
