



























































































































































































































import * as R from 'ramda';
import {
    ConfirmModal,
    CopyToClipboardButton,
    FormBlock,
    JsonParser,
    SvgImage,
    TwButton,
    AlertBanner,
} from '@/app/components';
import { ChevronRightIcon } from '@vue-hero-icons/solid';
import { useAxios, useFilters } from '@/app/composable';
import { MqttAPI } from '@/modules/data-checkin/api';
import { RefreshIcon } from '@vue-hero-icons/outline';
import { computed, defineComponent, PropType, reactive, ref, watch } from '@vue/composition-api';
import { OrbitSpinner } from 'epic-spinners';
import { ValidationObserver } from 'vee-validate';
import { SampleUpload, RetrievalSettings } from '../../components';
import { ProcessingOptions } from '../../constants/processing-options.constants';
import TopicHierarchy from './streaming/TopicHierarchy.vue';
import TopicParameter from './streaming/TopicParameter.vue';
import { MqttTopic, Streaming, StreamingConnectionDetails } from '../../types/streaming.interface';
import dayjs from 'dayjs';

const { formatBytes } = useFilters();

export default defineComponent({
    name: 'MqttConfiguration',
    components: {
        FormBlock,
        JsonParser,
        ValidationObserver,
        TwButton,
        ConfirmModal,
        OrbitSpinner,
        SvgImage,
        CopyToClipboardButton,
        SampleUpload,
        RefreshIcon,
        AlertBanner,
        RetrievalSettings,
        TopicHierarchy,
        TopicParameter,
        ChevronRightIcon,
    },
    model: {
        prop: 'configuration',
    },
    props: {
        configuration: {
            type: Object as PropType<Streaming>,
            required: true,
        },
        sample: {
            type: [Object, Array],
            required: false,
        },
        files: {
            type: Object as PropType<{ data: []; sample: File }>,
            required: true,
        },
        activeTab: {
            type: Number,
            required: true,
        },
        jobId: {
            type: Number,
            required: true,
        },
        completed: {
            type: Boolean,
            default: true,
        },
        initializing: {
            type: Boolean,
            default: false,
        },
        pipelineFinalized: {
            type: Boolean,
            required: true,
        },
        isFinalized: {
            type: Boolean,
            default: false,
        },
        loadingFinalization: {
            type: Boolean,
            default: false,
        },
        running: {
            type: Boolean,
            default: false,
        },
    },
    setup(props, { root, emit }) {
        const topicParameter = ref<any>(null);
        const isSampleArray = computed(() => R.is(Array, props.sample));
        const { exec } = useAxios(true);
        const sampleRef = ref<any>();
        const mqttValidationRef = ref<any>();
        const showResetPasswordModal = ref<boolean>(false);
        const resettingPassword = ref<boolean>(false);
        const completedStep = computed(() => props.completed);
        const invalidFormat = ref<boolean>(false);
        const initializationFailed = ref<boolean>(false);
        const resetButton = computed(() => (resettingPassword.value ? 'Resetting Password...' : 'Reset Password'));
        const topic = computed(() => props.configuration.connectionDetails.topic);

        const connectionDetails: StreamingConnectionDetails = reactive({
            url: '',
            topic: '',
            clientId: null,
            username: '',
            password: null,
        });

        const protocolVersionOptions = [
            { label: 'v5.0', value: '5' },
            { label: 'v3.1.1', value: '3.1.1' },
            { label: 'v3.1', value: '3.1' },
        ];

        const hasSameSubtopics = computed(() => {
            const subtopics: any = props.configuration.connectionDetails?.topics
                ?.filter((subtopic: MqttTopic) => subtopic.name !== `${props.configuration.connectionDetails.topic}/`)
                .map((subtopic: MqttTopic) => subtopic.name);
            return !!subtopics.filter((subtopic: MqttTopic, index: number) => subtopics.indexOf(subtopic) !== index)
                .length;
        });

        const validateAndProceed = async () => {
            if (mqttValidationRef.value) {
                const valid = await mqttValidationRef.value.validate();
                if (valid) {
                    const inclusiveDate = dayjs(props.configuration.retrieval.endDate).add(1, 'day'); // add 1 extra day in order to make it inclusive
                    if (!props.pipelineFinalized && inclusiveDate.isBefore(dayjs().utc()))
                        (root as any).$toastr.e(
                            'Retrieve Until Date is in the past. Please update it accordingly to continue.',
                            'Invalid Retrieve Until Date',
                        );
                    else emit('next-tab');
                }
            }
        };

        const validate = async () =>
            mqttValidationRef.value.validate() && topicParameter.value.isParameterValid() && !hasSameSubtopics.value;

        const confirmResetPassword = () => {
            showResetPasswordModal.value = true;
        };

        const resetPassword = async () => {
            showResetPasswordModal.value = false;
            resettingPassword.value = true;
            exec(MqttAPI.resetPassword(connectionDetails.username as string, props.jobId))
                .then((res) => {
                    if (res) connectionDetails.password = res.data.password;
                    resettingPassword.value = false;
                    (root as any).$toastr.s('MQTT password has been reset!', 'Success');
                })
                .catch(() => {
                    (root as any).$toastr.e('Failed to reset MQTT password', 'Failed');
                    resettingPassword.value = false;
                });
        };

        const displaySampleIsArrayBanner = computed(
            () => isSampleArray.value && !props.isFinalized && !props.loadingFinalization,
        );

        if (!props.initializing && !props.configuration.connectionDetails.username) {
            emit('initialize-step');
        }

        watch(
            () => props.configuration.connectionDetails,
            (details: StreamingConnectionDetails) => {
                if (details) {
                    initializationFailed.value = false;
                    if (details.username) {
                        let brokers = details.url.split(',').map((broker: string) => broker.trim());
                        connectionDetails.url = brokers.join(', ');
                        connectionDetails.topic = details.topic;
                        connectionDetails.clientId = details.clientId;
                        connectionDetails.username = details.username;
                        connectionDetails.password = details.password;
                        if (!props.configuration.connectionDetails.topics?.length) {
                            props.configuration.connectionDetails.topics?.push({ name: details.topic, qos: 0 });
                        }
                        if (props.configuration.connectionDetails.password) {
                            props.configuration.connectionDetails.password = null; // eslint-disable-line no-param-reassign
                            emit('save-step');
                        }
                    } else {
                        initializationFailed.value = true;
                    }
                }
            },
            { immediate: true },
        );

        return {
            resetButton,
            showResetPasswordModal,
            connectionDetails,
            mqttValidationRef,
            formatBytes,
            sampleRef,
            validate,
            validateAndProceed,
            confirmResetPassword,
            resetPassword,
            resettingPassword,
            invalidFormat,
            initializationFailed,
            completedStep,
            isSampleArray,
            displaySampleIsArrayBanner,
            protocolVersionOptions,
            ProcessingOptions,
            topic,
            topicParameter,
            hasSameSubtopics,
        };
    },
});
