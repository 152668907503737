































































































































































































































































































import { useFilters } from '@/app/composable';
import { ExclamationIcon } from '@vue-hero-icons/outline';
import { PropType, computed, defineComponent, ref } from '@vue/composition-api';
import { FieldPath } from '../../components';
import { useMappingTransformations } from '../../composable';
import { StatsPerField } from '../../types/step-stats.interface';
import TransformationDetails from './TransformationDetails.vue';

export default defineComponent({
    name: 'ConceptOverviewView',
    components: { TransformationDetails, FieldPath, ExclamationIcon },
    props: {
        concept: {
            type: Object,
            required: true,
        },
        isFinalized: {
            type: Boolean,
            default: false,
        },
        successfulTransformations: {
            type: Object as PropType<StatsPerField>,
            required: false,
            default: null,
        },
        failedTransformations: {
            type: Object,
            required: false,
            default: null,
        },
        failedTransformationReasons: {
            type: String,
            required: false,
            default: null,
        },
        message: {
            type: Object,
            required: false,
        },
        validationErrorPerId: {
            type: Object,
            required: true,
        },
        noTransformations: {
            type: Boolean,
            default: false,
        },
    },
    setup(props) {
        const { formatLargeNumber } = useFilters();
        const expanded = ref<boolean>(false);
        const { getTransformations, getTransformationText, getTransformationIcon } = useMappingTransformations();
        const conceptClicked = () => {
            expanded.value = !expanded.value;
        };

        const trimPath = () => {
            props.concept.target.path.splice(-1, 1);
            props.concept.target.parentIds.splice(-1, 1);
        };

        const transformations = computed(() => {
            return getTransformations(props.concept);
        });

        const failedTransformationReason = computed(() => {
            if (
                props.message &&
                props.message.stats &&
                props.message.failedTransformations &&
                props.concept.source.id
            ) {
                let fieldId: any = null;
                Object.entries(props.message.stats).forEach((entry: any) => {
                    const [key, value]: any = entry;
                    if (props.concept.source.id === value.source_id) {
                        fieldId = key;
                    }
                });

                if (fieldId) {
                    return props.message.failedTransformations[fieldId];
                }
            }
            return null;
        });

        const sameSourceTargetType = computed(() => {
            const targetType = ['double', 'integer', 'float'].includes(props.concept.target.type)
                ? 'number'
                : props.concept.target.type;
            return targetType === props.concept.source.type;
        });

        return {
            expanded,
            trimPath,
            conceptClicked,
            transformations,
            getTransformationText,
            failedTransformationReason,
            formatLargeNumber,
            sameSourceTargetType,
            getTransformationIcon,
        };
    },
});
