
















































































































































import { Scrollbar, Toggle } from '@/app/components';
import { computed, defineComponent, ref } from '@vue/composition-api';
import * as R from 'ramda';
import WizardActions from '../../components/WizardActions.vue';
import { useSampleFields } from '../../composable';
import { StatusCode } from '../../constants';
import FieldView from './FieldView.vue';

export default defineComponent({
    name: 'EncryptionConfiguration',
    model: {
        prop: 'configuration',
    },
    components: {
        FieldView,
        Scrollbar,
        WizardActions,
        Toggle,
    },
    props: {
        configuration: {
            type: Object,
            required: true,
        },
        sample: {
            type: Array,
            required: true,
        },
        mode: {
            type: String,
            default: null,
        },
        isFinalized: {
            type: Boolean,
            default: false,
        },
        hasChanges: {
            type: Boolean,
            required: true,
        },
        isLoading: {
            type: Boolean,
            default: false,
        },
        canRestart: {
            type: Boolean,
            default: false,
        },
        stepStatus: {
            type: String,
            default: 'configuration',
        },
        queryParams: {
            type: String,
            default: '{}',
        },
        alternateNames: {
            type: Object,
            default: null,
        },
    },
    setup(props, { emit }) {
        const scrollUp = ref<boolean>(false);
        const constraintsRef = ref<any>(null);
        const selectedFields = ref<any[]>([]);
        const indexAllFields = ref<boolean | null>(null);
        const showAlternateNaming = ref<boolean>(false);

        const hasAlternateNames = computed(() => props.alternateNames && Object.keys(props.alternateNames).length);

        const updateSelected = (value: any, multiple = false) => {
            const idx = selectedFields.value.findIndex((obj) => obj === value);
            if (multiple) {
                if (~idx) {
                    selectedFields.value.splice(idx, 1);
                } else {
                    selectedFields.value.push(value);
                }
            } else if (~idx && selectedFields.value.length === 1) {
                selectedFields.value.splice(0);
            } else {
                selectedFields.value = [value];
            }
        };

        const updateIndex = () => {
            indexAllFields.value = null;

            emit('changed');
        };

        const { extractFieldSample } = useSampleFields();

        const selectedFieldsSample = computed(() => {
            if (props.sample) {
                return selectedFields.value.map((field: any) => {
                    const col: any = R.pick(['title', 'path', 'order', 'type'], field);
                    col.sample = extractFieldSample(props.sample, field.originalName, field.originalPath);
                    return col;
                });
            }
            return [];
        });

        const hasIndex = computed(() => {
            return props.configuration.fields.filter((field: any) => field.index === true).length > 0;
        });

        const indexAll = (index: boolean) => {
            indexAllFields.value = index;
            const fields = props.configuration.fields.map((field: any) => {
                const indexedField = R.clone(field);
                if (indexedField.canBeIndexed) {
                    indexedField.index = indexAllFields.value;
                }
                return indexedField;
            });
            emit('update-index', fields);
        };

        const saveConstraints = () => {
            scrollUp.value = true;
            emit('changed');
            emit('change-edit-mode', null);
            scrollUp.value = false;
        };

        return {
            constraintsRef,
            selectedFields,
            updateSelected,
            selectedFieldsSample,
            saveConstraints,
            emit,
            scrollUp,
            indexAllFields,
            indexAll,
            updateIndex,
            hasIndex,
            StatusCode,
            showAlternateNaming,
            hasAlternateNames,
        };
    },
});
