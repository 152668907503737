























































































import * as R from 'ramda';
import { defineComponent, ref, computed } from '@vue/composition-api';
import { FormBlock } from '@/app/components';
import ClickOutside from 'vue-click-outside';
import { TrashIcon, PencilAltIcon, CheckIcon, XIcon } from '@vue-hero-icons/outline';
import { extend, ValidationProvider } from 'vee-validate';
import { maxLengthValidator, minLengthValidator, regexValidator, requiredValidator } from '@/app/validators';

extend('required', requiredValidator);
extend('min', minLengthValidator);
extend('max', maxLengthValidator);
extend('regex', {
    ...regexValidator,
    message:
        'Parameter Name must contain only alphanumeric characters, dashes, underscores, spaces and at least one letter.',
});

export default defineComponent({
    name: 'TopicParameter',
    directives: {
        ClickOutside,
    },
    components: {
        FormBlock,
        ValidationProvider,
        PencilAltIcon,
        CheckIcon,
        XIcon,
        TrashIcon,
    },
    props: {
        topicNameField: {
            type: String,
        },
        sample: {
            type: [Object, Array],
            required: false,
        },
        isFinalized: {
            type: Boolean,
            default: false,
        },
    },
    setup(props, { emit }) {
        const topicParameterName = ref(props.topicNameField || null);
        const topicNameFieldValidator = ref<any>(null);
        const editingParameterName = ref(!props.topicNameField);
        const previousParameterValue = ref(props.topicNameField || null);
        const isSampleArray = computed(() => R.is(Array, props.sample));

        const sampleKeys = computed(() => {
            const keys: string[] = [];

            if (isSampleArray.value) {
                (props.sample as any).forEach((record: any) =>
                    Object.keys(record).forEach((key: string) => {
                        if (!keys.includes(key)) keys.push(key);
                    }),
                );
            } else {
                Object.keys(props.sample as any).forEach((key: string) => {
                    if (!keys.includes(key)) keys.push(key);
                });
            }
            return keys;
        });

        const parameterExistsInSampleKeys = computed(
            () => !!(topicParameterName.value && sampleKeys.value.includes(topicParameterName.value)),
        );

        const isParameterValid = () =>
            !(parameterExistsInSampleKeys.value && previousParameterValue.value !== topicParameterName.value);

        const setParameterToSample = (parameter: string | null) => {
            let updatedSample: any = R.clone(props.sample);

            editingParameterName.value = false;
            if (isSampleArray.value) {
                updatedSample.forEach((row: any) => {
                    if (previousParameterValue.value && (!parameter || previousParameterValue.value))
                        delete row[previousParameterValue.value];
                    if (parameter) row[parameter] = 'SUB_TOPIC';
                });
            } else {
                if (previousParameterValue.value && (!parameter || previousParameterValue.value))
                    delete updatedSample[previousParameterValue.value];
                if (parameter) updatedSample[parameter] = 'SUB_TOPIC';
            }
            previousParameterValue.value = parameter;
            emit('set-parameter-name', parameter);
            emit('sample-uploaded', updatedSample);
        };

        const deleteParameterName = () => {
            topicParameterName.value = null;
            setParameterToSample(topicParameterName.value);
            editingParameterName.value = true;
        };

        const cancelEditingParameter = () => {
            editingParameterName.value = false;
            topicParameterName.value = previousParameterValue.value;
        };

        const errorTooltip = (errors: any) => {
            if (errors && errors.length > 0) return errors[0];
            if (parameterExistsInSampleKeys.value && previousParameterValue.value !== topicParameterName.value)
                return 'The provided Parameter Name already exists as a field in the Sample. Please define a different Parameter Name.';
            return null;
        };

        const resetValidationRef = () => {
            if (!topicParameterName.value) topicNameFieldValidator.value.reset();
        };

        return {
            topicNameFieldValidator,
            editingParameterName,
            previousParameterValue,
            isSampleArray,
            topicParameterName,
            deleteParameterName,
            resetValidationRef,
            cancelEditingParameter,
            parameterExistsInSampleKeys,
            setParameterToSample,
            isParameterValid,
            errorTooltip,
        };
    },
});
