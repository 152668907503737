



















































































































































































































































































import { Scrollbar, SvgImage } from '@/app/components';
import { computed, defineComponent, ref } from '@vue/composition-api';
import WizardActions from '../../components/WizardActions.vue';
import { useAnonymisation } from '../../composable';
import { AnonymisationType } from '../../constants';
import FieldBlock from './FieldBlock.vue';

export default defineComponent({
    name: 'ReviewAndReport',
    components: {
        FieldBlock,
        Scrollbar,
        WizardActions,
        SvgImage,
    },
    model: {
        prop: 'configuration',
    },
    props: {
        configuration: {
            type: Object,
            required: true,
        },
        sample: {
            type: Array,
            required: true,
        },
        isFinalized: {
            type: Boolean,
            default: false,
        },
        stats: {
            type: Object,
            default: null,
        },
        errorCode: {
            type: Number,
            default: 0,
        },
        isLoading: {
            type: Boolean,
            default: false,
        },
        hasChanges: {
            type: Boolean,
            required: false,
        },
        canRestart: {
            type: Boolean,
            default: false,
        },
        hasDifference: {
            type: Boolean,
            default: false,
        },
        queryParams: {
            type: String,
            default: '{}',
        },
    },
    setup(props, { root, emit }) {
        const editingKAnonymity = ref<boolean>(false);
        const editingLossLimit = ref<boolean>(false);
        const kAnonymity = ref<number>(props.configuration.kAnonymity);
        const lossLimit = ref<number>(props.configuration.lossLimit);
        const { validateInput } = useAnonymisation(root);

        const cancelChanges = () => {
            root.$router.push({ name: 'data-checkin-jobs', query: JSON.parse(props.queryParams) });
        };

        const quasiIdentifiers = computed(() => {
            return props.configuration.fields.filter(
                (field: any) => field.anonymisationType === AnonymisationType.QuasiIdentifier,
            );
        });

        const identifiers = computed(() => {
            return props.configuration.fields.filter(
                (field: any) => field.anonymisationType === AnonymisationType.Identifier,
            );
        });

        const sensitives = computed(() => {
            return props.configuration.fields.filter(
                (field: any) => field.anonymisationType === AnonymisationType.Sensitive,
            );
        });

        const insensitives = computed(() => {
            const insensitiveFields = props.configuration.fields.filter(
                (field: any) => field.anonymisationType === AnonymisationType.Insensitive,
            );
            return insensitiveFields.reduce((result: Array<any>, value: any, index: number, array: Array<any>) => {
                if (index % 4 === 0) result.push(array.slice(index, index + 4));
                return result;
            }, []);
        });

        const confirmKAnonymity = () => {
            if (validateInput(2, 10, kAnonymity.value, 'k-anonymity value')) {
                emit('change-k-anonymity', kAnonymity.value);
                editingKAnonymity.value = false;
                emit('changed');
            }
        };

        const cancelKAnonymity = () => {
            kAnonymity.value = props.configuration.kAnonymity;
            editingKAnonymity.value = false;
        };

        const confirmLossLimit = () => {
            if (validateInput(10, 100, lossLimit.value, 'acceptable information loss threshold')) {
                emit('change-loss-limit', lossLimit.value);
                editingLossLimit.value = false;
                emit('changed');
            }
        };

        const cancelLossLimit = () => {
            lossLimit.value = props.configuration.lossLimit;
            editingLossLimit.value = false;
        };

        const fieldStats = (field: any) => {
            if (props.stats) {
                if (props.stats[field.id]) return props.stats[field.id];
                const fieldName = field.name.replaceAll('__', '_');
                if (props.stats[fieldName]) return props.stats[fieldName];
                return {};
            }
            return null;
        };

        const formatNumber = (num: number) => {
            if (num && !Number.isInteger(num)) return num.toFixed(2);
            return num;
        };

        return {
            cancelChanges,
            fieldStats,
            editingKAnonymity,
            editingLossLimit,
            kAnonymity,
            lossLimit,
            quasiIdentifiers,
            identifiers,
            sensitives,
            insensitives,
            confirmKAnonymity,
            cancelKAnonymity,
            confirmLossLimit,
            cancelLossLimit,
            formatNumber,
        };
    },
});
