











































import { computed, defineComponent, ref, Ref } from '@vue/composition-api';
import { TrashIcon, XIcon } from '@vue-hero-icons/outline';

export default defineComponent({
    name: 'MappingSelectedFields',
    props: {
        selected: { type: Number, required: true },
    },
    components: { TrashIcon, XIcon },
    setup(props, { emit }) {
        const openState: Ref<boolean> = ref<boolean>(false);

        const open = computed({
            get: () => openState.value,
            set: (newState: boolean) => {
                openState.value = newState;
                emit('changed', openState.value);
            },
        });

        const clearSelection = () => {
            open.value = false;
            emit('clear-selection');
        };

        const clearMapping = () => {
            open.value = false;
            emit('clear-mapping');
        };

        return { open, clearSelection, clearMapping };
    },
});
