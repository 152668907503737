














































































































































































































import * as R from 'ramda';
import {
    ConfirmModal,
    CopyToClipboardButton,
    FormBlock,
    JsonParser,
    SvgImage,
    TwButton,
    AlertBanner,
} from '@/app/components';
import { useAxios, useFilters } from '@/app/composable';
import { KafkaAPI } from '@/modules/data-checkin/api';
import { RefreshIcon } from '@vue-hero-icons/outline';
import { computed, defineComponent, reactive, ref, watch } from '@vue/composition-api';
import { OrbitSpinner } from 'epic-spinners';
import { ProcessingOptions as ProcOptions } from '../../constants/processing-options.constants';
import { extend, ValidationObserver } from 'vee-validate';
import { SampleUpload, ProcessingOptions, RetrievalSettings } from '../../components';
import minMax from 'dayjs/plugin/minMax';
import dayjs from 'dayjs';
import { ScheduleType } from '@/modules/workflow-designer/types';
import { requiredValidator } from '@/app/validators';

extend('required', requiredValidator);

dayjs.extend(minMax);

const { formatBytes } = useFilters();

export default defineComponent({
    name: 'KafkaConfiguration',
    model: {
        prop: 'configuration',
    },
    props: {
        configuration: {
            type: Object,
            required: true,
        },
        sample: {
            type: [Object, Array],
            required: false,
        },
        files: {
            type: Object,
            required: true,
        },
        activeTab: {
            type: Number,
            required: true,
        },
        jobId: {
            type: Number,
            required: true,
        },
        completed: {
            type: Boolean,
            default: true,
        },
        running: {
            type: Boolean,
            default: true,
        },
        initializing: {
            type: Boolean,
            default: false,
        },
        isDeprecated: {
            type: Boolean,
            default: false,
        },
        workflowId: {
            type: String,
            required: true,
        },
        jobCreatedById: {
            type: Number,
        },
        pipelineFinalized: {
            type: Boolean,
            required: true,
        },
        stepId: {
            type: Number,
            required: true,
        },
        isFinalized: {
            type: Boolean,
            default: false,
        },
        loadingFinalization: {
            type: Boolean,
            default: false,
        },
    },
    components: {
        FormBlock,
        JsonParser,
        ValidationObserver,
        TwButton,
        ConfirmModal,
        OrbitSpinner,
        SvgImage,
        CopyToClipboardButton,
        SampleUpload,
        RefreshIcon,
        ProcessingOptions,
        RetrievalSettings,
        AlertBanner,
    },
    setup(props, { root, emit }) {
        const isSampleArray = computed(() => R.is(Array, props.sample));
        const { exec } = useAxios(true);
        const schedules = ref([]);
        const sampleFile = computed(() => props.files.sample);
        const sampleRef = ref<any>(null);
        const dateYesterday = computed(() => {
            const date = new Date();
            date.setDate(date.getDate() - 1);
            return date;
        });
        const kafkaValidationRef = ref<any>(null);
        const showResetPasswordModal = ref<boolean>(false);
        const resettingPassword = ref<boolean>(false);
        const completedStep = computed(() => props.completed);
        const invalidFormat = ref<boolean>(false);
        const initializationFailed = ref<boolean>(false);
        const tomorrow = computed(() => {
            const date = new Date();
            return date.setDate(date.getDate() + 1);
        });

        const resetButton = computed(() => {
            if (resettingPassword.value) return 'Resetting Password...';
            return 'Reset Password';
        });

        const connectionDetails = reactive({
            url: null,
            topic: null,
            securityProtocol: null,
            saslMechanism: null,
            username: '',
            password: null,
        });

        const updateSchedules = (updatedSchedules: any) => {
            schedules.value = updatedSchedules;
        };

        if (!props.initializing && !props.configuration.connectionDetails.username) {
            emit('initialize-step');
        }

        watch(
            () => props.configuration.connectionDetails,
            (details) => {
                if (details) {
                    initializationFailed.value = false;
                    if (details.username) {
                        let brokers = details.url.split(',').map((broker: string) => broker.trim());
                        brokers = brokers.join(', ');
                        connectionDetails.url = brokers;
                        connectionDetails.topic = details.topic;
                        connectionDetails.securityProtocol = details.securityProtocol;
                        connectionDetails.saslMechanism = details.saslMechanism;
                        connectionDetails.username = details.username;
                        connectionDetails.password = details.password;
                        if (props.configuration.connectionDetails.password) {
                            props.configuration.connectionDetails.password = null; // eslint-disable-line no-param-reassign
                            emit('save-step');
                        }
                    } else {
                        initializationFailed.value = true;
                    }
                }
            },
            { immediate: true },
        );

        const invalidSchedulesEndDate = computed(() => {
            const today = dayjs().utc();
            const invalidSchedules = schedules.value.filter((schedule: ScheduleType) =>
                dayjs(schedule.endDate).add(1, 'day').isBefore(today),
            );
            return !!invalidSchedules.length;
        });

        const validateAndProceed = async () => {
            if (kafkaValidationRef.value) {
                const valid = await kafkaValidationRef.value.validate();
                if (valid) {
                    const inclusiveDate = dayjs(props.configuration.retrieval.endDate).add(1, 'day'); // add 1 extra day in order to make it inclusive
                    if (
                        !props.pipelineFinalized &&
                        (inclusiveDate.isBefore(dayjs().utc()) || invalidSchedulesEndDate.value)
                    ) {
                        let errorMessage =
                            'Retrieve Until Date is in the past. Please update it accordingly to continue.';
                        if (props.configuration.processing === ProcOptions.TimeBased)
                            errorMessage =
                                'End Dates of Schedules are in the past. Please update them accordingly to continue.';

                        (root as any).$toastr.e(errorMessage, 'Invalid Retrieve Until Date');
                    } else {
                        emit('next-tab');
                    }
                }
            }
        };

        const validate = async () => {
            return kafkaValidationRef.value.validate();
        };

        const confirmResetPassword = () => {
            showResetPasswordModal.value = true;
        };

        const resetPassword = async () => {
            showResetPasswordModal.value = false;
            resettingPassword.value = true;
            exec(KafkaAPI.resetPassword(connectionDetails.username, undefined, props.jobId))
                .then((res) => {
                    if (res) connectionDetails.password = res.data.password;
                    resettingPassword.value = false;
                    (root as any).$toastr.s('Kafka password has been reset!', 'Success');
                })
                .catch(() => {
                    (root as any).$toastr.e('Failed to reset kafka password', 'Failed');
                    resettingPassword.value = false;
                });
        };

        const invalidNoOfSchedules = computed(
            () => props.configuration.processing === ProcOptions.TimeBased && !schedules.value.length,
        );

        const displaySampleIsArrayBanner = computed(
            () => isSampleArray.value && !props.isFinalized && !props.loadingFinalization,
        );

        return {
            tomorrow,
            resetButton,
            showResetPasswordModal,
            connectionDetails,
            dateYesterday,
            kafkaValidationRef,
            formatBytes,
            sampleFile,
            sampleRef,
            validate,
            validateAndProceed,
            confirmResetPassword,
            resetPassword,
            resettingPassword,
            invalidFormat,
            initializationFailed,
            completedStep,
            updateSchedules,
            invalidNoOfSchedules,
            schedules,
            isSampleArray,
            displaySampleIsArrayBanner,
            ProcOptions,
        };
    },
});
