




































































































































































































































































































































































































































































































































































































import { Card, ConfirmModal, FormBlock, TwButton, TwProgressBar, WizardTabs } from '@/app/components';
import { useAxios, useFeatureFlags, useFilters, useSockets } from '@/app/composable';
import { WorkflowStatus } from '@/modules/apollo/constants';
import { JobsAPI, UploadAPI } from '@/modules/data-checkin/api';
import { useSampleRun, useStep } from '@/modules/data-checkin/composable';
import { HarvesterSourceType, ProcessingOptions, StatusCode } from '@/modules/data-checkin/constants';
import { DocumentIcon } from '@vue-hero-icons/outline';
import {
    computed,
    defineComponent,
    onBeforeUnmount,
    onMounted,
    onUnmounted,
    Ref,
    ref,
    watch,
} from '@vue/composition-api';
import { OrbitSpinner } from 'epic-spinners';
import * as R from 'ramda';
import { clone, dissoc, isNil } from 'ramda';
import { LoadingSampleRunModal, StepCompletionModal, WizardActions } from '../../components';
import ApiConfiguration from './ApiConfiguration.vue';
import FilesConfiguration, { FilesData } from './FilesConfiguration.vue';
import InternalApiConfiguration from './InternalApiConfiguration.vue';
import StreamingConfiguration from './StreamingConfiguration.vue';
import ExternalStreamingConfiguration from './ExternalStreamingConfiguration.vue';
import LargeFilesConfiguration from './LargeFilesConfiguration.vue';

export default defineComponent({
    name: 'Harvester',
    metaInfo() {
        return { title: `Configure Harvester${(this as any).job ? `: ${(this as any).job.name}` : ''}` };
    },
    components: {
        ApiConfiguration,
        InternalApiConfiguration,
        Card,
        FilesConfiguration,
        LargeFilesConfiguration,
        StreamingConfiguration,
        ExternalStreamingConfiguration,
        ConfirmModal,
        FormBlock,
        OrbitSpinner,
        TwButton,
        TwProgressBar,
        WizardTabs,
        WizardActions,
        StepCompletionModal,
        LoadingSampleRunModal,
        DocumentIcon,
    },
    props: {
        id: {
            type: [String, Number],
            required: true,
        },
        queryParams: {
            type: String,
            default: '{}',
        },
    },
    setup(props, { root }) {
        const { isEnabled: isFeatureEnabled, areAnyEnabled: areAnyFeaturesEnabled } = useFeatureFlags();
        const contentRef = ref<any>(null);
        const tabs: Ref<{ title: string }[]> = ref([
            { title: 'Setup Harvest Service' },
            { title: 'Test and Review Configuration' },
        ]);
        const harvesterValidationRef = ref<any>(null);
        const jobId = parseInt(`${props.id}`, 10);
        const { loading, exec, error } = useAxios(true);
        const activeTab = ref(0);
        const showConfirmModal = ref(false);
        const showUpdateFileDataModal = ref<boolean>(false);
        const uploading = ref(false);
        const source = ref<HarvesterSourceType | undefined>();
        const job = ref<any>(null);
        const step = ref<any>(null);
        const files = ref<FilesData>({
            sample: null,
            data: null,
        });
        const { formatBytes } = useFilters();
        const progress = ref<number>(0);
        const progressId = ref<number>(-1);
        const initializingStreaming = ref(false);
        const saveInProgress = ref(false);
        const defaultBasePath = 'res';
        const { isFinalized, isDeprecated, getNextStep, lockDcj } = useStep(step, job, root);
        const initialJobStep = ref<string | null>(null);
        const showFinalizeModal = ref(false);
        const nextStep = ref<any>(null);
        const cleaningConfig = ref<any>(null);
        const hasAdditionalFile = ref<boolean>(false);
        const steps = ref<any>(null);
        const isOnpremise = ref<boolean>(false);
        const finalizing = ref<boolean>(false);
        const parsedSampleData = ref<any>(null);
        const canExecuteSampleRun = ref<boolean>(false);
        const processedSample = ref<any>(null);
        const updatedSample = ref<any>(null);
        const currentSelectedItems = ref([]);
        const workflowFinalized = computed(() => job.value?.workflow?.status === WorkflowStatus.Ready);
        const consumedSample = ref<unknown>(null);

        const updateProcessedSample = (sample: any) => {
            processedSample.value = sample;
            if (sample) {
                step.value.configuration.params['fields'] = Object.keys(sample[0]);
                step.value.processedSample = sample;
                setUploadSample(sample);
                nextTab();
            }
        };

        const { executeSampleRun, loadingSampleRun, skipSampleRun, onMessage } = useSampleRun(
            step,
            job,
            root,
            updateProcessedSample,
        );

        const hasChanges = computed(() => {
            if (R.isNil(step.value)) return false;

            if (!R.equals(step.value, initialJobStep.value)) {
                return true;
            }

            if (step.value.configuration && step.value.configuration.files && files.value.data) {
                return !R.equals(step.value.configuration.files, files.value.data);
            }
            return false;
        });

        const mappingStepExists = computed(
            () => !!(steps.value && steps.value.find((jobStep: any) => jobStep.dataCheckinStepType.name === 'mapping')),
        );

        // Initialize
        exec(JobsAPI.get(jobId))
            .then((res) => {
                job.value = res?.data;
                setParsedSample(job.value?.sample);
                setUpdatedSample(job.value?.sample);

                isOnpremise.value = job.value.runnerId !== null;

                exec(JobsAPI.getJobSteps(jobId)).then(async (stepRes) => {
                    steps.value = stepRes?.data;
                    step.value = steps.value.find((jobStep: any) => jobStep.dataCheckinStepType.name === 'harvester');
                    source.value = step.value.configuration?.source || null;
                    if (!step.value.configuration) {
                        step.value.configuration = { source: null };
                        step.value.serviceVersion = process.env.VUE_APP_HARVESTER_VERSION;
                    } else if (
                        [HarvesterSourceType.File, HarvesterSourceType.LargeFiles].includes(
                            step.value.configuration.source,
                        )
                    ) {
                        await exec(JobsAPI.getStep(jobId, 'harvester')).then((response) => {
                            if (step.value.configuration.source === HarvesterSourceType.File)
                                step.value.configuration = response?.data.configuration;
                            processedSample.value = response?.data.processedSample;
                            if (
                                [StatusCode.Configuration, StatusCode.Update].includes(response?.data?.status) &&
                                response?.data?.configuration?.files
                            )
                                files.value.data = step.value.configuration?.files;
                        });
                    }
                    if (!step.value.configuration?.source) await lockDcj(jobId);
                    initialJobStep.value = R.clone(step.value);
                    const cleaning = steps.value.find(
                        (jobStep: any) => jobStep.dataCheckinStepType.name === 'cleaning',
                    );
                    if (cleaning) cleaningConfig.value = cleaning.configuration;
                    if (isOnpremise.value) {
                        step.value.configuration.source = HarvesterSourceType.File;
                    }
                });
            })
            .catch((e) => {
                if (e.response.status === 404) {
                    (root as any).$toastr.e('The data check-in pipeline requested does not exist', 'Not Found');
                } else if (e.response.status === 403) {
                    (root as any).$toastr.e(
                        'You do not have permission to view this specific data check-in pipeline',
                        'Not Authorised',
                    );
                } else {
                    (root as any).$toastr.e(e.response.data.message);
                }
                root.$router.push({ name: 'data-checkin-jobs', query: JSON.parse(props.queryParams) });
            });

        const isJobCompleted = computed(() => {
            let allStepsCompleted = true;
            if (steps.value) {
                steps.value.forEach((jobStep: any) => {
                    if (jobStep.status !== StatusCode.Completed) allStepsCompleted = false;
                });
            }
            return allStepsCompleted;
        });

        /*
        The user can upload more data (files) in a file harvester only if:
        a) there is one processed file (the job has been executed at least once)
        b) there is no anonymisation step
        c) the cleaning step does not include rules other than 'DROP' and 'DEFAULT_VALUE'
        d) is not on premise
        */
        const canUploadMore = computed(() => {
            if (job.value && !isOnpremise.value) {
                const isSuspended = job.value?.workflow?.status === StatusCode.Suspended;
                const hasAnonymisation = steps.value.some((jobStep: any) => jobStep.order === 3);
                const executedOnce = step.value?.configuration?.files?.some((f: any) => f.status === 'PROCESSED');

                const noUploadedFiles =
                    step.value?.status !== StatusCode.Configuration &&
                    step.value?.status !== StatusCode.Update &&
                    step.value?.configuration?.files?.length === 0;

                const cleaningIncompatible =
                    !!cleaningConfig.value &&
                    cleaningConfig.value.fields
                        .flatMap((field: any) => field.constraints)
                        .some(
                            (constraint: any) =>
                                constraint.outliersRule.type !== 'DROP' &&
                                constraint.outliersRule.type !== 'DEFAULT_VALUE',
                        );

                return (executedOnce || noUploadedFiles) && !isSuspended && !hasAnonymisation && !cleaningIncompatible;
            }
            return false;
        });

        // Methods
        const getSampleFile = (stepData: any) => {
            const harvesterSource = stepData.configuration.source;
            if (
                (harvesterSource === HarvesterSourceType.InternalApi &&
                    stepData.configuration.dataType === 'textBinary') ||
                [
                    HarvesterSourceType.Api,
                    HarvesterSourceType.ExternalKafka,
                    HarvesterSourceType.ExternalMQTT,
                    HarvesterSourceType.MQTT,
                ].includes(stepData.configuration.source)
            ) {
                const json = R.clone(job.value.sample);
                return new Blob([JSON.stringify(json, null, 2)], { type: 'application/json' });
            }
            return files.value.sample;
        };

        const uploadSampleFile = async (policy: any = null) => {
            let uploadPolicy = policy;
            if (!uploadPolicy) {
                const response = await exec(UploadAPI.getPolicy(job.value.id, 'upload'));
                policy = response?.data;
            }
            const stepData = clone(step.value);
            const sampleFile = getSampleFile(stepData);
            const type = [
                HarvesterSourceType.Api,
                HarvesterSourceType.ExternalKafka,
                HarvesterSourceType.ExternalMQTT,
            ].includes(stepData.configuration.source)
                ? 'json'
                : stepData.configuration.fileType;
            const sampleConfig = {
                filename: `sample.${type}`,
                size: sampleFile?.size,
                mimeType: sampleFile?.type,
                path: policy.prefix,
            };

            await exec(UploadAPI.file(sampleFile, sampleConfig.filename, policy));
            return sampleConfig;
        };

        const saveChanges = async (clearSample = false, showToaster = true) => {
            saveInProgress.value = true;
            const stepData = clone(step.value);

            if (stepData.status === StatusCode.Deprecated) {
                if (showToaster)
                    (root as any).$toastr.e('The current job is deprecated and cannot be updated', 'Failed');
                saveInProgress.value = false;
                return;
            }

            const response = await exec(UploadAPI.getPolicy(job.value.id, 'upload'));
            const policySample = response?.data;

            switch (stepData.configuration.source) {
                case HarvesterSourceType.Api:
                    try {
                        stepData.configuration.auth.payload = JSON.parse(stepData.configuration.auth.payload);
                    } catch {
                        // do nothing
                    }
                    if (clearSample) {
                        stepData.configuration.response.data = null;
                    }
                    stepData.configuration.isSaved = true;
                    break;
                case HarvesterSourceType.File:
                    if (clearSample && stepData.configuration?.response?.data)
                        stepData.configuration.response.data = null;
                    break;
                case HarvesterSourceType.ExternalKafka:
                case HarvesterSourceType.ExternalMQTT:
                    if (!isFinalized.value) job.value.sample = stepData.configuration.processedSample;
                    stepData.configuration.processedSample = [];
                    if (clearSample) {
                        stepData.configuration.response.data = null;
                        stepData.configuration.connectionDetails.password = null;
                    }
                    stepData.configuration.isSaved = true;
                    break;
                default: // do nothing
            }

            await exec(JobsAPI.updateStep(stepData.id, stepData));

            if ([StatusCode.Configuration, StatusCode.Update].includes(stepData.status)) {
                // in the case of cloned MQTT pipeline, if the topic name field has changed, must update uploaded sample
                if (
                    stepData.configuration.source === HarvesterSourceType.MQTT &&
                    stepData.configuration.schema &&
                    !Object.keys(stepData.configuration.schema).includes(stepData.topicNameField)
                )
                    consumedSample.value = R.clone(job.value.sample);

                let sampleConfig = null;
                if (files.value.sample) sampleConfig = await uploadSampleFile(policySample);
                else if (
                    consumedSample.value &&
                    [
                        HarvesterSourceType.ExternalKafka,
                        HarvesterSourceType.ExternalMQTT,
                        HarvesterSourceType.MQTT,
                    ].includes(stepData.configuration.source)
                ) {
                    const data = JSON.stringify(consumedSample.value);
                    sampleConfig = {
                        filename: 'sample.json',
                        size: encodeURI(data).split(/%..|./).length - 1,
                        mimeType: 'application/json',
                        path: policySample.prefix,
                    };
                    await exec(UploadAPI.file(data, sampleConfig.filename, policySample));
                    consumedSample.value = null;
                }

                job.value.sampleConfig = sampleConfig;
                if (finalizing.value) {
                    if (!R.is(Array, job.value.sample)) job.value.sample = [job.value.sample];

                    if (stepData.configuration.source === HarvesterSourceType.File && updatedSample.value) {
                        if (!R.is(Array, updatedSample.value)) {
                            updatedSample.value = [updatedSample.value];
                        }
                        job.value.sample = updatedSample.value;
                    }
                }

                await exec(JobsAPI.update(job.value.id, job.value));
            }

            // TODO: Upload any data files, update configuration.files array and clear files object
            if (files.value.data && files.value.data.length > 0) {
                const resFile = await exec(
                    UploadAPI.getPolicy(job.value.id, 'upload', new Date().valueOf().toString()),
                );
                const policyFile = resFile?.data;
                uploading.value = true;
                for (let i = 0; i < files.value.data.length; i += 1) {
                    progress.value = 0;
                    progressId.value = i;
                    const file = files.value.data[i];
                    if ((file as any).status === 'PENDING') continue;
                    // eslint-disable-next-line no-await-in-loop
                    await exec(
                        UploadAPI.file(file, `${file?.name}`, policyFile, (progressEvent) => {
                            progress.value = Math.round((progressEvent.loaded * 100) / progressEvent.total);
                        }),
                    );
                    stepData.configuration.files.push({
                        filename: file.name,
                        size: file.size,
                        mimeType: file.type || 'application/octet-stream',
                        path: policyFile.prefix,
                    });
                }

                uploading.value = false;
                // Update step again, with the uploaded files
                const stepValue = dissoc('status', stepData);

                await exec(JobsAPI.updateStep(stepData.id, stepValue));
                if (stepData.configuration.source === HarvesterSourceType.File) {
                    await exec(JobsAPI.getStep(jobId, 'harvester')).then((res) => {
                        step.value.configuration = res?.data.configuration;
                    });
                }
            }
            saveInProgress.value = false;
            initialJobStep.value = R.clone(step.value);

            if (hasAdditionalFile.value) {
                (root as any).$toastr.s('File data updated successfuly', 'Success');
                root.$router.push({ name: 'data-checkin-jobs', query: JSON.parse(props.queryParams) });
            } else if (showToaster) {
                (root as any).$toastr.s('Harvester configuration saved successfuly', 'Success');
            }
        };

        const setConsumedSample = (sample: any) => {
            consumedSample.value = sample;
        };

        const confirmSaveChanges = () => {
            if (hasAdditionalFile.value) {
                showUpdateFileDataModal.value = true;
            } else {
                saveChanges(false, true);
            }
        };

        const saveStep = async () => {
            await exec(JobsAPI.updateStep(step.value.id, step.value));
        };

        const initializeStep = async () => {
            if (step.value.configuration.source !== HarvesterSourceType.LargeFiles) initializingStreaming.value = true;
            await exec(JobsAPI.updateStep(step.value.id, step.value));
            if (!error.value) {
                await exec(JobsAPI.getStep(jobId, 'harvester')).then((res) => {
                    step.value.configuration = res?.data.configuration;
                });
            }
            initializingStreaming.value = false;
        };

        const cancelSetSource = () => {
            source.value = step.value.configuration?.source;
            showConfirmModal.value = false;
        };
        const setSource = async () => {
            // Initialize configuration object for each type
            switch (source.value) {
                case HarvesterSourceType.Api:
                    step.value.configuration = {
                        source: HarvesterSourceType.Api,
                        fileType: 'json',
                        isSaved: false,
                        params: {
                            method: 'GET',
                            url: '',
                            urlParams: [],
                            headers: [],
                            payload: null,
                            parameters: [],
                            headerTags: [],
                            ignoreCertificates: false,
                        },
                        pagination: {
                            type: 'no',
                            includeValues: 'query',
                            parameters: [],
                        },
                        auth: {
                            method: 'no',
                            url: '',
                            payload: null,
                            tokenType: 'Bearer',
                            accessToken: null,
                            loginTested: false,
                            headers: [],
                            ignoreCertificates: false,
                        },
                        retrieval: {
                            type: 'periodic',
                            endDate: null,
                        },
                        response: {
                            basePath: defaultBasePath,
                            multiple: true,
                            data: null,
                            selectedItems: [],
                            additional: [],
                            jsonResponse: {},
                        },
                    };
                    break;
                case HarvesterSourceType.File:
                    step.value.configuration = {
                        source: HarvesterSourceType.File,
                        fileType: null,
                        params: {},
                        files: [],
                        isSampleCropped: false,
                        response: {
                            basePath: defaultBasePath,
                            multiple: true,
                            selectedItems: [],
                        },
                    };
                    break;
                case HarvesterSourceType.LargeFiles:
                    step.value.configuration = {
                        source: HarvesterSourceType.LargeFiles,
                        fileType: 'csv',
                        files: [],
                        isSampleCropped: false,
                        params: {},
                        connectionDetails: {
                            url: null,
                            accessKey: null,
                            secretKey: null,
                        },
                        retrieval: {
                            type: 'periodic',
                            endDate: null,
                        },
                        response: {
                            basePath: defaultBasePath,
                            multiple: true,
                            selectedItems: [],
                            additional: [],
                        },
                    };
                    break;
                case HarvesterSourceType.Kafka:
                    step.value.configuration = {
                        source: HarvesterSourceType.Kafka,
                        fileType: 'json',
                        mechanism: null,
                        processing: ProcessingOptions.RealTime,
                        params: {},
                        connectionDetails: {},
                        retrieval: {
                            endDate: null,
                        },
                        files: [],
                        isSampleCropped: false,
                        topicNameField: null,
                    };
                    break;
                case HarvesterSourceType.MQTT:
                    step.value.configuration = {
                        source: HarvesterSourceType.MQTT,
                        fileType: 'json',
                        mechanism: null,
                        processing: ProcessingOptions.RealTime,
                        params: {},
                        connectionDetails: {},
                        retrieval: {
                            endDate: null,
                        },
                        files: [],
                        isSampleCropped: false,
                        topicNameField: null,
                    };
                    break;
                case HarvesterSourceType.InternalApi:
                    step.value.configuration = {
                        source: HarvesterSourceType.InternalApi,
                        params: {
                            uploadQueryId: null,
                        },
                        dataType: 'text',
                        files: [],
                        fileType: 'json',
                        isSampleCropped: false,
                    };
                    break;
                case HarvesterSourceType.ExternalKafka:
                    step.value.configuration = {
                        source: HarvesterSourceType.ExternalKafka,
                        isSaved: false,
                        mechanism: null,
                        processing: ProcessingOptions.RealTime,
                        isSampleUploaded: false,
                        isSampleCropped: false,
                        fileType: 'json',
                        params: {},
                        connectionDetails: {},
                        retrieval: {
                            endDate: null,
                        },
                        files: [],
                        response: {
                            basePath: defaultBasePath,
                            multiple: true,
                            data: null,
                            selectedItems: [],
                        },
                        processedSample: null,
                        topicNameField: null,
                    };
                    break;
                case HarvesterSourceType.ExternalMQTT:
                    step.value.configuration = {
                        source: HarvesterSourceType.ExternalMQTT,
                        isSaved: false,
                        mechanism: null,
                        processing: ProcessingOptions.RealTime,
                        isSampleUploaded: false,
                        isSampleCropped: false,
                        fileType: 'json',
                        params: {},
                        connectionDetails: {},
                        retrieval: {
                            endDate: null,
                        },
                        files: [],
                        response: {
                            basePath: defaultBasePath,
                            multiple: true,
                            data: null,
                            selectedItems: [],
                        },
                        processedSample: null,
                        topicNameField: null,
                    };
                    break;
                default:
                    step.value.configuration = {
                        source: source.value,
                    };
            }
            showConfirmModal.value = false;

            if (
                ![HarvesterSourceType.Kafka, HarvesterSourceType.MQTT, HarvesterSourceType.LargeFiles].includes(
                    step.value.configuration.source,
                )
            ) {
                await exec(JobsAPI.updateStep(step.value.id, step.value));
            }
        };

        const setParsedSample = (parsedSample: any) => {
            parsedSampleData.value = parsedSample;
        };

        const setUploadSample = (parsedSample: any) => {
            if (
                [HarvesterSourceType.ExternalKafka, HarvesterSourceType.ExternalMQTT].includes(
                    step.value.configuration.source,
                )
            )
                step.value.configuration.isSampleUploaded = !!parsedSample;

            job.value.sample = parsedSample;
        };

        const setUpdatedSample = (parsedSample: any) => {
            updatedSample.value = parsedSample;
        };

        const setFiles = (changedFiles: any) => {
            if (changedFiles.sample !== undefined) {
                files.value.sample = changedFiles.sample;
                canExecuteSampleRun.value = true;
                processedSample.value = null;
                if (changedFiles.sample === null) {
                    job.value.sample = null;
                    canExecuteSampleRun.value = false;
                }
                // reset parameter name if sample changed
                if ([HarvesterSourceType.MQTT].includes(step.value.configuration.source)) setParameterName(null);
            }

            if (changedFiles.data !== undefined) {
                files.value.data = changedFiles.data ? Array.from(changedFiles.data) : null;
                if (canUploadMore.value) hasAdditionalFile.value = !!changedFiles.data;
            }
        };

        const removeFile = (file: any) => {
            if (files.value.data) {
                const idx = files.value.data.findIndex((f: any) => f === file);
                if (~idx) {
                    files.value.data.splice(idx, 1);
                }
            }
        };

        const finalize = async () => {
            if (harvesterValidationRef.value && step.value.status !== StatusCode.Deprecated) {
                const valid = await harvesterValidationRef.value.validate();
                if (valid) {
                    if (step.value.configuration?.response?.isReset) {
                        delete step.value.configuration.response.isReset;
                    }
                    finalizing.value = true;
                    await saveChanges(true, false);
                    // If harvester is API, upload sample file in MinIo to be available for sample run.
                    if (step.value.configuration?.source === HarvesterSourceType.Api) {
                        const sampleConfig = await uploadSampleFile();
                        await exec(JobsAPI.update(job.value.id, { ...job.value, sampleConfig }));
                    }
                    await exec(JobsAPI.finalize(step.value.id)).then(() => {
                        getNextStep()
                            .then((stepTypeResponse: any) => {
                                nextStep.value = stepTypeResponse;
                                showFinalizeModal.value = true;
                            })
                            .finally(() => {
                                finalizing.value = false;
                            });
                    });
                }
            }
        };

        const changeKafkaInitStatus = (isKafkaLoading: boolean) => {
            initializingStreaming.value = isKafkaLoading;
        };

        const scrollUp = () => {
            contentRef.value.scrollTo({ top: 0, behavior: 'smooth' });
        };

        const jobConfigChanged = (config: any) => {
            job.value.config = config;
            if (!isNil(config?.basePath)) {
                step.value.configuration.response.basePath = config.basePath;
            }
            if (!isNil(config?.multiple)) {
                step.value.configuration.response.multiple = config.multiple;
            }
            if (!isNil(config?.jsonResponse)) {
                step.value.configuration.response.jsonResponse = config.jsonResponse;
                job.value.config.jsonResponse = undefined;
            }
        };

        const setCroppedSample = (cropped: boolean) => {
            step.value.configuration.isSampleCropped = cropped;
        };

        const nextTab = () => {
            if (job.value.sample && step.value.configuration.source === HarvesterSourceType.InternalApi) {
                if (Array.isArray(job.value.sample))
                    for (let i = 0; i < job.value.sample.length; i += 1) {
                        if (step.value.configuration.dataType === 'textBinary') {
                            job.value.sample[i][
                                `${'_uploaded_file'}`
                            ] = `${process.env.VUE_APP_BACKEND_URL}/api/retrieval/RETRIEVAL-QUERY-ID/file/UPLOADED-FILE-ID`;
                        } else if (Object.keys(job.value.sample[i]).includes('_uploaded_file')) {
                            delete job.value.sample[i][`${'_uploaded_file'}`];
                        }
                    }
                else {
                    if (step.value.configuration.dataType === 'textBinary') {
                        job.value.sample[
                            `${'_uploaded_file'}`
                        ] = `${process.env.VUE_APP_BACKEND_URL}/api/retrieval/RETRIEVAL-QUERY-ID/file/UPLOADED-FILE-ID`;
                    } else if (Object.keys(job.value.sample).includes('_uploaded_file')) {
                        delete job.value.sample[`${'_uploaded_file'}`];
                    }
                }
            }
            activeTab.value += 1;
            scrollUp();
        };

        const previousTab = () => {
            activeTab.value -= 1;
            scrollUp();
        };

        const setProcessedSample = (streamingProcessedSample: any) => {
            step.value.configuration.processedSample = streamingProcessedSample;
            if (!step.value.configuration.isSampleUploaded) {
                job.value.sample = streamingProcessedSample;
            }
        };

        const updateConnectionDetails = (connectionDetails: any) => {
            step.value.configuration.connectionDetails = connectionDetails;
        };

        const resetConnectionDetails = () => {
            step.value.configuration.connectionDetails.username = null;
            step.value.configuration.connectionDetails.password = null;
        };

        const isSelectionValid = computed(() => {
            if (step.value) {
                const selectedItemsExist =
                    (step.value.configuration.source === HarvesterSourceType.Api ||
                        step.value.configuration.source === HarvesterSourceType.ExternalKafka ||
                        step.value.configuration.source === HarvesterSourceType.ExternalMQTT ||
                        (step.value.configuration.source === HarvesterSourceType.File &&
                            (step.value.configuration.fileType === 'json' ||
                                step.value.configuration.fileType === 'xml'))) &&
                    step.value.configuration.response.selectedItems.length > 0;
                const notJSONorXMLorAPIorExternalKafkaOrExternalMQTT =
                    (step.value.configuration.source !== HarvesterSourceType.Api &&
                        step.value.configuration.source !== HarvesterSourceType.ExternalKafka &&
                        step.value.configuration.source !== HarvesterSourceType.ExternalMQTT &&
                        step.value.configuration.fileType !== 'json' &&
                        step.value.configuration.fileType !== 'xml') ||
                    step.value.configuration.source === HarvesterSourceType.InternalApi ||
                    step.value.configuration.source === HarvesterSourceType.Kafka ||
                    step.value.configuration.source === HarvesterSourceType.MQTT;
                return selectedItemsExist || notJSONorXMLorAPIorExternalKafkaOrExternalMQTT;
            }
            return false;
        });

        const enableFinalize = computed(() => {
            if (
                !step.value ||
                (step.value.configuration?.source === HarvesterSourceType.Api &&
                    R.isEmpty(currentSelectedItems.value)) ||
                (step.value.configuration?.source === HarvesterSourceType.File && R.isEmpty(files.value.data))
            )
                return false;

            return (
                step.value.status !== StatusCode.Deprecated &&
                activeTab.value === tabs.value.length - 1 &&
                (isOnpremise.value || isSelectionValid.value)
            );
        });

        const setConfigurationParams = (params: any) => {
            step.value.configuration.params = params;
        };

        const changeLoadingState = (state: boolean) => {
            loading.value = state;
        };

        const runOnSample = async () => {
            uploading.value = true;
            await exec(JobsAPI.updateStep(step.value.id, step.value));
            if (files.value.sample) {
                const sampleConfig = await uploadSampleFile();
                await exec(JobsAPI.update(job.value.id, { ...job.value, sampleConfig }));
            }
            uploading.value = false;
            executeSampleRun();
        };

        const onSelectionChanged = (selection: any) => (currentSelectedItems.value = selection);
        const { subscribe, unsubscribe, WebSocketsEvents, leaveSocketRoom, WebSocketsRoomTypes } = useSockets();

        const unlockJob = async () => {
            await exec(JobsAPI.unlock(Number(props.id)));
        };

        const updateProcessingOption = (newProcessingOption: ProcessingOptions) => {
            step.value.configuration.processing = newProcessingOption;
            if (step.value.configuration.retrieval) step.value.configuration.retrieval.endDate = null;
        };

        const setParameterName = (parameterName: string | null) => {
            step.value.configuration.topicNameField = parameterName;
            if (step.value.configuration.response)
                step.value.configuration.response.additional = parameterName
                    ? [{ key: parameterName, value: 'SUB_TOPIC', type: 'static' }] // Set the topic parameter value manually as 'SUB_TOPIC'
                    : [];
        };

        const updateRetrievalType = (newRetrievalType: string) => {
            if (step.value.configuration.retrieval) {
                step.value.configuration.retrieval.endDate = null;
                step.value.configuration.retrieval.type = newRetrievalType;
            }
        };

        const resetResponseData = () => {
            step.value.configuration.response.data = null;
        };

        onMounted(() => {
            subscribe(WebSocketsEvents.Workflow, (msg: any) => onMessage(msg));
            window.addEventListener('beforeunload', unlockJob);
        });

        onUnmounted(async () => {
            unlockJob();
        });

        onBeforeUnmount(() => {
            unsubscribe(WebSocketsEvents.Workflow);
            leaveSocketRoom(WebSocketsRoomTypes.Workflow, job.value?.workflowId);
        });

        watch(
            () => source.value,
            (newSource: HarvesterSourceType | undefined, oldSource: HarvesterSourceType | undefined) => {
                if (newSource && oldSource !== undefined && newSource !== oldSource) showConfirmModal.value = true;
            },
        );

        return {
            isFeatureEnabled,
            contentRef,
            activeTab,
            error,
            files,
            finalize,
            formatBytes,
            harvesterValidationRef,
            job,
            loading,
            progress,
            progressId,
            removeFile,
            confirmSaveChanges,
            saveChanges,
            setFiles,
            setSource,
            cancelSetSource,
            setUploadSample,
            showConfirmModal,
            showUpdateFileDataModal,
            StatusCode,
            step,
            tabs,
            uploading,
            initializingStreaming,
            changeKafkaInitStatus,
            isFinalized,
            isDeprecated,
            saveStep,
            initializeStep,
            jobId,
            scrollUp,
            saveInProgress,
            defaultBasePath,
            jobConfigChanged,
            nextTab,
            previousTab,
            hasChanges,
            showFinalizeModal,
            nextStep,
            updateConnectionDetails,
            setProcessedSample,
            hasAdditionalFile,
            isJobCompleted,
            canUploadMore,
            mappingStepExists,
            enableFinalize,
            resetConnectionDetails,
            setCroppedSample,
            isOnpremise,
            finalizing,
            changeLoadingState,
            parsedSampleData,
            setParsedSample,
            loadingSampleRun,
            runOnSample,
            canExecuteSampleRun,
            updateProcessedSample,
            processedSample,
            skipSampleRun,
            onSelectionChanged,
            HarvesterSourceType,
            setUpdatedSample,
            setConfigurationParams,
            workflowFinalized,
            updateProcessingOption,
            setConsumedSample,
            setParameterName,
            resetResponseData,
            updateRetrievalType,
            source,
            areAnyFeaturesEnabled,
        };
    },
});
