














































































































































































































































































import { CopyToClipboardButton, FormBlock, JsonParser, TwAccordion, TwAccordionCard, TwButton } from '@/app/components';
import { useAxios, useFilters } from '@/app/composable';
import { AccessTokenScope } from '@/app/constants/scopes';
import { InternalAPI } from '@/modules/data-checkin/api';
import AccessTokenModal from '@/modules/user/views/AccessTokenModal.vue';
import { computed, defineComponent, reactive, ref } from '@vue/composition-api';
import 'prismjs';
import 'prismjs/components/prism-json';
import { extend, ValidationObserver, ValidationProvider } from 'vee-validate';
import { required } from 'vee-validate/dist/rules'; // eslint-disable-line
import { SampleUpload } from '../../components';

extend('required', {
    ...required,
    message: (field) => {
        const { splitLowerCase } = useFilters();

        const fieldName = splitLowerCase(field);
        return `The ${fieldName} is required`;
    },
});

export interface Param {
    key: string;
    value: string;
    sensitive: boolean;
}

export default defineComponent({
    name: 'InternalApiConfiguration',
    model: {
        prop: 'configuration',
    },
    components: {
        FormBlock,
        JsonParser,
        TwButton,
        ValidationObserver,
        ValidationProvider,
        AccessTokenModal,
        CopyToClipboardButton,
        TwAccordion,
        TwAccordionCard,
        SampleUpload,
    },
    props: {
        configuration: {
            type: Object,
            required: true,
        },
        sample: {
            type: [Object, Array],
            required: false,
        },
        files: {
            type: Object,
            required: true,
        },
        activeTab: {
            type: Number,
            required: true,
        },
        completed: {
            type: Boolean,
            default: true,
        },
        jobId: {
            type: Number,
            required: true,
        },
        workflowId: {
            type: String,
            required: true,
        },
        stepId: {
            type: Number,
            required: true,
        },
        isFinalized: {
            type: Boolean,
            default: false,
        },
    },
    setup(props, { emit }) {
        const { formatBytes } = useFilters();
        const { exec } = useAxios(true);
        const apiValidationRef = ref<any>(false);
        const showChangeScheduleModal = ref(false);
        const showAccessTokenModal = ref(false);
        const newPeriod = ref(null);
        const loginResponse = ref<any>(null);
        const loginResponseKeys = ref<string[]>([]);
        const invalidLoginBody = ref<boolean>(false);
        const invalidQueryBody = ref<boolean>(false);
        const dateYesterday = computed(() => {
            const date = new Date();
            date.setDate(date.getDate() - 1);
            return date;
        });
        const finalSample = ref<any>(props.sample);
        const placeholder = '<YOUR_EXECUTION_ID>';

        const dataTypes = {
            text: {
                label: 'Text only',
            },
            textBinary: {
                label: 'Text and Binary',
            },
        };

        const postURL = ref<any>(null);
        const sampleFile = computed(() => props.files.sample);
        const sampleRef = ref<any>(null);
        const fileRef = ref<any>(null);
        const postURLToCopy = ref<any>(null);

        if (
            props.configuration.params.uploadQueryId === null ||
            props.configuration.params.uploadQueryId.length === 0
        ) {
            exec(InternalAPI.create(props.jobId, props.stepId)).then((resCreate: any) => {
                postURL.value = `${process.env.VUE_APP_BACKEND_URL}/api/upload/${resCreate.data.id}`;
                props.configuration.params.uploadQueryId = resCreate.data.id; // eslint-disable-line no-param-reassign
                emit('save-step');
            });
        } else {
            postURL.value = `${process.env.VUE_APP_BACKEND_URL}/api/upload/${props.configuration.params.uploadQueryId}`;
        }

        const monitoringUrl = computed(
            () =>
                `${process.env.VUE_APP_BACKEND_URL}/api/monitoring/executions/data-checkin/${props.workflowId}/execution/${placeholder}/status`,
        );

        const errorAlert: any = reactive({
            title: null,
            body: null,
        });

        const validate = async () => {
            return apiValidationRef.value.validate();
        };

        const validateAndProceed = async () => {
            if (apiValidationRef.value) {
                const valid = await apiValidationRef.value.validate();
                if (valid) {
                    emit('next-tab');
                }
            }
        };

        const copyToClipboard = () => {
            postURLToCopy.value = document.querySelector('#url');
            if (postURLToCopy.value) {
                postURLToCopy.value.setAttribute('type', 'text');
                postURLToCopy.value.select();
                document.execCommand('copy');
                postURLToCopy.value.setAttribute('type', 'hidden');
            }
        };

        return {
            invalidLoginBody,
            invalidQueryBody,
            errorAlert,
            apiValidationRef,
            dateYesterday,
            finalSample,
            newPeriod,
            loginResponse,
            loginResponseKeys,
            showChangeScheduleModal,
            showAccessTokenModal,
            validate,
            dataTypes,
            fileRef,
            formatBytes,
            sampleFile,
            sampleRef,
            validateAndProceed,
            copyToClipboard,
            postURL,
            postURLToCopy,
            monitoringUrl,
            placeholder,
            AccessTokenScope,
        };
    },
});
