





















































































































































































































































import { InputErrorIcon } from '@/app/components';
import { computed, defineComponent } from '@vue/composition-api';
import { extend, ValidationProvider } from 'vee-validate';
import { GeneralizationMethod } from '../../../constants';
import { maxValueValidator, minValueValidator, requiredValidator } from '@/app/validators';

extend('required', requiredValidator);
extend('min_value', minValueValidator);
extend('max_value', maxValueValidator);

export default defineComponent({
    name: 'IntervalNumericalGroup',
    components: {
        ValidationProvider,
        InputErrorIcon,
    },
    props: {
        selectedField: {
            type: Object,
            required: true,
        },
    },
    setup(props, { emit }) {
        const changeLeveling = () => {
            if (props.selectedField.generalization === GeneralizationMethod.Interval) {
                props.selectedField.options.levels = [{ interval: null }]; // eslint-disable-line no-param-reassign
            } else {
                props.selectedField.options.levels = [[{ from: null, to: null, label: null }]]; // eslint-disable-line no-param-reassign
            }
            emit('changed');
        };

        const addNumericalGroup = (level: number) => {
            props.selectedField.options.levels[level].push({ from: null, to: null, label: null });
        };

        const removeNumericalGroup = (level: number, group: number) => {
            if (group === 0 && props.selectedField.options.levels.length === 1) {
                props.selectedField.options.levels[0][0].from = null; // eslint-disable-line no-param-reassign
                props.selectedField.options.levels[0][0].to = null; // eslint-disable-line no-param-reassign
                props.selectedField.options.levels[0][0].label = null; // eslint-disable-line no-param-reassign
            } else if (group === 0 && props.selectedField.options.levels[level].length === 1) {
                props.selectedField.options.levels.splice(level, 1);
            } else {
                props.selectedField.options.levels[level].splice(group, 1);
            }
        };

        const minLevelValue = (index: number) => {
            if (props.selectedField.options.levels && props.selectedField.options.levels.length > 1) {
                if (index > 0) {
                    if (
                        !props.selectedField.options.levels[index - 1].interval ||
                        props.selectedField.options.levels[index - 1].interval.length === 0
                    )
                        return 2;
                    return parseInt(props.selectedField.options.levels[index - 1].interval, 10) + 1;
                }
                return 2;
            }
            return 2;
        };

        const maxLevelValue = (index: number) => {
            if (props.selectedField.options.levels && props.selectedField.options.levels.length > 1) {
                if (index < props.selectedField.options.levels.length - 1) {
                    if (
                        !props.selectedField.options.levels[index + 1].interval ||
                        props.selectedField.options.levels[index + 1].interval.length === 0
                    )
                        return Number.MAX_VALUE;
                    if (props.selectedField.options.levels[index + 1].interval <= 2) return Number.MAX_VALUE;
                    return parseInt(props.selectedField.options.levels[index + 1].interval, 10) - 1;
                }
                return Number.MAX_VALUE;
            }
            return Number.MAX_VALUE;
        };

        const getIntervalErrorMessage = (index: number, value: any, leveling: string) => {
            if (value && value.length > 0) {
                if (value < 2) return 'The interval value must be greater than 1';
                if (leveling === 'custom') {
                    const minValue = minLevelValue(index);
                    const maxValue = maxLevelValue(index);
                    if (value < minValue || (value > maxValue && maxValue > 1)) {
                        return 'The interval value of this level should be higher than the previous level value and lower than the next level value, if applicable';
                    }
                }
            }
            return null;
        };

        const hasLabels = computed(() => {
            for (let i = 0; i < props.selectedField.options.levels.length; i += 1) {
                for (let j = 0; j < props.selectedField.options.levels[i].length; j += 1) {
                    if (
                        props.selectedField.options.levels[i][j].label &&
                        props.selectedField.options.levels[i][j].label.length > 0
                    )
                        return true;
                }
            }
            return false;
        });

        const getLabelPlaceholder = (group: any) => {
            if (!hasLabels.value) {
                if (group.from && group.from.length > 0 && group.to && group.to.length > 0) {
                    return `${group.from}-${group.to}`;
                }
                return 'optional';
            }
            return null;
        };

        return {
            changeLeveling,
            addNumericalGroup,
            removeNumericalGroup,
            minLevelValue,
            maxLevelValue,
            hasLabels,
            getLabelPlaceholder,
            getIntervalErrorMessage,
        };
    },
});
