
























































































































































































import { FormBlock, Scrollbar, SearchBox } from '@/app/components';
import { useFilters } from '@/app/composable';
import { ChevronDownIcon, ChevronRightIcon } from '@vue-hero-icons/outline';
import { PropType, computed, defineComponent, ref } from '@vue/composition-api';
import { SelfBuildingSquareSpinner } from 'epic-spinners';
import Fuse from 'fuse.js';
import { v4 as uuidv4 } from 'uuid';
import { AlternateNaming } from './mapping.types';

export default defineComponent({
    name: 'MappingInfo',
    model: {
        prop: 'configuration',
        event: 'change',
    },
    props: {
        domains: {
            type: Array,
            required: false,
        },
        concepts: {
            type: Array,
            required: true,
        },
        configuration: {
            type: Object,
            required: true,
        },
        locked: {
            type: Boolean,
            default: false,
        },
        loading: {
            type: Object as PropType<{ pageLoading: boolean; conceptsLoading: boolean }>,
            default: () => {
                return {
                    pageLoading: false,
                    domainLoading: false,
                    conceptsLoading: false,
                };
            },
        },
    },
    components: { FormBlock, Scrollbar, SearchBox, SelfBuildingSquareSpinner, ChevronDownIcon, ChevronRightIcon },
    setup(props, { emit }) {
        const standards = computed(() => {
            if (props.configuration.domain && props.domains) {
                const domain: any = props.domains.find((obj: any) => obj.id === props.configuration.domain.id);
                return domain ? domain.standardsMapping : [];
            }

            return [];
        });
        const searchText = ref<string>('');
        const hideConceptsPerCategory = ref<boolean>(false);
        const categoryKey = ref<any>(uuidv4());
        const toggledConceptsCategories = ref<any>([]);

        const domainConcepts = computed(() => {
            if (props.configuration.domain)
                return props.concepts.filter((conc: any) => conc.domain === props.configuration.domain.id);
            return props.concepts;
        });

        const filteredConceptsPerCategory = computed(() => {
            if (searchText.value.trim() === '') return getConceptsPerCategory(domainConcepts.value);

            const fuseSearch = new Fuse(domainConcepts.value, {
                keys: [
                    { name: 'name', weight: 3 },
                    { name: 'relatedTerms', weight: 1 },
                ],
                shouldSort: true,
                threshold: 0.1,
            });
            const filteredConcepts = fuseSearch.search(searchText.value).map((result) => result.item);
            return getConceptsPerCategory(filteredConcepts);
        });

        const getConceptsPerCategory = (currentConcepts: any) => {
            const allCategories = [...new Set(getAllCategories(currentConcepts))].sort();
            let conceptsPerCategory: any = allCategories.map((category) => ({
                category: category,
                concepts: [],
                show: true,
            }));
            conceptsPerCategory.forEach((conceptPerCategory: any) => {
                currentConcepts.forEach((concept: any) =>
                    conceptPerCategory.category ===
                    concept.relatedTerms
                        ?.filter((element: string) => element.startsWith('Category:'))[0]
                        ?.split(':')
                        ?.at(-1)
                        ? conceptPerCategory.concepts.push(concept)
                        : null,
                );
                if (!conceptPerCategory.category) conceptPerCategory.category = 'No category';
            });
            conceptsPerCategory.forEach((conceptPerCategory: any) => {
                toggledConceptsCategories.value.forEach((toggledConceptsCategory: any) =>
                    conceptPerCategory.category === toggledConceptsCategory.category
                        ? (conceptPerCategory.show = toggledConceptsCategory.show)
                        : true,
                );
            });

            return conceptsPerCategory;
        };

        const getAllCategories = (givenConcepts: any) => {
            const allCategories = givenConcepts.map((givenConcept: any) =>
                givenConcept.relatedTerms
                    ?.filter((element: string) => element.startsWith('Category:'))[0]
                    ?.split(':')
                    ?.at(-1),
            );

            return allCategories;
        };

        const toggleConcepts = (categoryConcepts: any) => {
            categoryConcepts.show = !categoryConcepts.show;
            categoryKey.value = uuidv4();
            toggledConceptsCategories.value.push(categoryConcepts);
        };

        const changeDomain = (domainId: number) => {
            searchText.value = '';
            emit('domain-changed', domainId);
        };

        const alternateNamingOptions = [
            {
                id: AlternateNaming.None,
                label: 'No alternate naming required',
                description: 'Only the naming provided by the selected data model will be available',
            },
            {
                id: AlternateNaming.Original,
                label: 'Store the source/original field names',
                description: 'The original field names that appear in the source data will be stored and available',
            },
            {
                id: AlternateNaming.Alias,
                label: 'Define an alias for selected fields',
                description: 'An alias can be defined and stored for the  fields of your choice',
            },
        ];

        const { splitCamelCase } = useFilters();

        return {
            standards,
            splitCamelCase,
            filteredConceptsPerCategory,
            searchText,
            hideConceptsPerCategory,
            toggleConcepts,
            categoryKey,
            changeDomain,
            alternateNamingOptions,
        };
    },
});
