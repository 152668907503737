














































































































































import { computed, defineComponent, PropType } from '@vue/composition-api';
import { FormBlock } from '@/app/components';
import { PlusIcon, TrashIcon } from '@vue-hero-icons/outline';
import { extend, ValidationProvider, ValidationObserver } from 'vee-validate';
import { MqttTopic } from '@/modules/data-checkin/types/streaming.interface';
import { ExclamationIcon } from '@vue-hero-icons/solid';
import { HarvesterSourceType } from '@/modules/data-checkin/constants';
import { maxLengthValidator, minLengthValidator, regexValidator, requiredValidator } from '@/app/validators';

extend('required', requiredValidator);
extend('min', minLengthValidator);
extend('max', maxLengthValidator);
extend('main_topic_regex_validator', {
    ...regexValidator,
    message: 'Topic must start with at least one alphanumeric character and not contain any special characters.',
});

extend('sub_topic_regex_validator', {
    ...regexValidator,
    message:
        'The sub-topic path must contain only alphanumeric characters and if needed, forward slashes (for dividing the sub-topic names) and each sub-topic name must have a length of at least 2 characters.',
});

export default defineComponent({
    name: 'TopicHierarchy',
    components: {
        FormBlock,
        PlusIcon,
        TrashIcon,
        ValidationProvider,
        ExclamationIcon,
        ValidationObserver,
    },
    props: {
        topic: {
            type: String,
            required: true,
        },
        topics: {
            type: Array as PropType<MqttTopic[]>,
            required: true,
        },
        hasSameSubtopics: {
            type: Boolean,
            default: false,
        },
        completed: {
            type: Boolean,
            default: false,
        },
        source: {
            type: String,
        },
    },
    setup(props, { emit }) {
        const qosOptions = [
            { value: 0, label: 'QoS0' },
            { value: 1, label: 'QoS1' },
            { value: 2, label: 'QoS2' },
        ];
        const isExternalMQTT = computed(() => props.source === HarvesterSourceType.ExternalMQTT);

        const isSubtopic = (name: string) => name.startsWith(`${props.topic}/`);

        const mainTopicNotSet = computed(() => !props.topic);

        if (!props.topics.length) {
            props.topics.push({ name: '', qos: 0 });
        }

        const addSubtopic = () => {
            if (props.topics.length === 1 && props.topics[0].name === props.topic) {
                props.topics.splice(0);
            }
            props.topics.push({ name: `${props.topic}/`, qos: 0 });
        };

        const removeSubtopic = (index: number) => {
            props.topics.splice(index, 1);
            if (props.topics.length === 0) props.topics.push({ name: props.topic, qos: 0 });
        };

        const subtopicChanged = (event: any, index: number) => {
            props.topics[index].name = `${props.topic}/${event.target.value}`;
        };

        const mainTopicChanged = (event: any) => {
            props.topics[0].name = event.target.value;
            emit('topic-changed', event.target.value);
        };

        const hasSubtopics = computed(() => props.topics.length && isSubtopic(props.topics[0].name));

        return {
            addSubtopic,
            removeSubtopic,
            isSubtopic,
            subtopicChanged,
            hasSubtopics,
            qosOptions,
            mainTopicNotSet,
            mainTopicChanged,
            isExternalMQTT,
        };
    },
});
