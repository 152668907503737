






























































































































































































































import { MonitoringAPI } from '@/app/api';
import { ConfirmModal, ProcessedSampleView, TwButton, WizardTabs } from '@/app/components';
import { useAxios, useErrors, useJsonObject, useQuery, useResult, useSockets } from '@/app/composable';
import { HarvesterSourceType, RetrievalType, StatusCode } from '@/modules/data-checkin/constants';
import { computed, defineComponent, onBeforeUnmount, onMounted, onUnmounted, ref, watch } from '@vue/composition-api';
import { OrbitSpinner } from 'epic-spinners';
import * as R from 'ramda';
import { JobsAPI } from '../../api';
import { LoadingSampleRunModal, StepCompletionModal } from '../../components';
import { useSampleFields, useSampleRun, useStep } from '../../composable';
import GET_JOB from '../../graphql/getJob.graphql';
import { StepStats } from '../../types/step-stats.interface';
import CleaningConfiguration from './CleaningConfiguration.vue';
import CleaningReview from './CleaningReview.vue';
import { CleaningConfig, ConstraintStats, JobStepResult } from './cleaning.types';

export default defineComponent({
    name: 'Cleaning',
    metaInfo() {
        return { title: `Cleaning${(this as any).job ? ` for: ${(this as any).job.name}` : ''}` };
    },
    props: {
        id: {
            type: [Number, String],
            required: true,
        },
        queryParams: {
            type: String,
            default: '{}',
        },
    },
    components: {
        CleaningConfiguration,
        CleaningReview,
        OrbitSpinner,
        TwButton,
        WizardTabs,
        StepCompletionModal,
        ConfirmModal,
        LoadingSampleRunModal,
        ProcessedSampleView,
    },
    setup(props, { root }) {
        const { loading, error, exec } = useAxios(true);
        const jobId = parseInt(`${props.id}`, 10);
        const steps = ref([
            { title: 'Configuration', key: 'configuration' },
            { title: 'Review Rules', key: 'rules' },
            { title: 'Confirm and Report', key: 'confirm' },
        ]);
        const cleaningRef = ref<HTMLElement | null>(null);
        const message = ref<any>(null);
        const stats = ref<StepStats | null>(null);
        const activeTab = ref(0);
        const hasChanges = ref<boolean>(false);
        const currentCleaningConfiguration = ref<CleaningConfig | any>(null);
        const editMode = ref<string | null>(null);
        const showFinalizeModal = ref<boolean>(false);
        const restartedStep = ref<boolean>(false);
        const nextStep = ref<any>(null);
        const showConfirmModal = ref<boolean>(false);
        const isMacOS = window.navigator.userAgent.indexOf('Mac OS') !== -1;
        const { extractMappingFieldNames, extractAlternateNames } = useSampleFields();

        const showOrderInformation = ref<boolean>(true);
        const loadingFinalization = ref<boolean>(false);

        const previousStepSample = ref<any>(null);
        const processedSample = ref<any>(null);
        const previousProcessedSample = ref<any>(null);
        const problematicSampleConstraints = ref<ConstraintStats[]>([]);
        const finalTabNumber = ref<number>(2);
        const showEmptySampleModal = ref<boolean>(false);
        const alternateNames = ref<any>(null);

        // Fetch job information
        const { checkGQLAuthentication } = useErrors(root.$route);
        const { loading: jobLoading, error: jobError, result, onError, refetch } = useQuery(
            GET_JOB,
            {
                id: jobId,
            },
            { fetchPolicy: 'no-cache' },
        );
        onError(checkGQLAuthentication);
        const job = useResult(result, null, (data: any) => data.job);

        const harvesterStep = computed(() =>
            job.value?.dataCheckinJobSteps?.find(
                (step: JobStepResult) => step.dataCheckinStepType?.name === 'harvester',
            ),
        );

        const mode = computed(() => harvesterStep.value?.configuration?.source ?? null);

        const isStreamingJob = computed(() =>
            [
                HarvesterSourceType.Kafka,
                HarvesterSourceType.ExternalKafka,
                HarvesterSourceType.MQTT,
                HarvesterSourceType.ExternalMQTT,
            ].includes(harvesterStep.value?.configuration.source),
        );

        const canRunManyTimes = computed(() => {
            if (harvesterStep.value) {
                switch (harvesterStep.value.configuration.source) {
                    case HarvesterSourceType.File:
                        return false;
                    case HarvesterSourceType.Api:
                        // check if retrieval type is not "once"
                        return harvesterStep.value.configuration?.retrieval?.type !== RetrievalType.Once;
                    default:
                        // all other cases return true (can run many times)
                        return true;
                }
            }
            return false;
        });

        // Fetch cleaning configuration
        const cleaning = ref<any>(null);
        const {
            isConfigEmpty,
            isFinalized,
            getNextStep,
            updateAssetAfterFailedStep,
            canRestart,
            setupUpdatedConfiguration,
            getPreviousProcessedSample,
        } = useStep(cleaning, job, root);

        // Default (empty) configuration
        const configuration = ref<CleaningConfig | null>(null);

        const { getFixedJSON } = useJsonObject();

        const fixedSample = computed(() => {
            if (job.value && job.value.sample) {
                return getFixedJSON(job.value.sample);
            }
            return [];
        });

        const getStep = () => {
            exec(JobsAPI.getStep(jobId, 'cleaning')).then(async (resCleaning: any) => {
                cleaning.value = resCleaning.data;
                processedSample.value = resCleaning.data.processedSample;
                if (!previousProcessedSample.value) previousProcessedSample.value = await getPreviousProcessedSample();
                if (cleaning.value.message) {
                    message.value = cleaning.value.message;
                }
                exec(JobsAPI.getStep(jobId, 'mapping')).then(async (mapping: any) => {
                    if (!isConfigEmpty(mapping.data.configuration)) {
                        alternateNames.value = extractAlternateNames(mapping.data.configuration);
                        previousStepSample.value = mapping.data.processedSample;
                        if (isConfigEmpty(resCleaning.data.configuration)) {
                            const conf: any = { fields: [] };
                            const mappingFields = extractMappingFieldNames(mapping.data.configuration.fields);
                            mappingFields.forEach((field: any) => {
                                const obj = R.clone(field);
                                obj.constraints = [];
                                conf.fields.push(obj);
                            });
                            configuration.value = R.clone(conf);
                        } else {
                            configuration.value = R.clone(resCleaning.data.configuration);
                            if (cleaning.value.status === StatusCode.Update) {
                                /**
                                 * check if any fields have been added/ removed/ modified after
                                 * revised mapping and update the cleaning configuration
                                 */
                                configuration.value = await setupUpdatedConfiguration(
                                    mapping.data.configuration.fields,
                                    configuration.value,
                                );
                                currentCleaningConfiguration.value = R.clone(configuration.value);

                                // If there is already a processed sample (e.g. after cloning),
                                // reset it if the number of cleaning fields changed or if the old
                                // cleaning fields changed
                                if (processedSample.value) {
                                    if (
                                        resCleaning.data.configuration.fields.length !==
                                        currentCleaningConfiguration.value.fields.length
                                    )
                                        processedSample.value = null;
                                    else {
                                        const unchangedFields = currentCleaningConfiguration.value.fields.filter(
                                            (cf: any) =>
                                                resCleaning.data.configuration.fields.find(
                                                    (f: any) =>
                                                        cf.id === f.id &&
                                                        cf.parentIds.every(
                                                            (id: number, idx: number) => id === f.parentIds[idx],
                                                        ),
                                                ),
                                        );
                                        if (unchangedFields.length !== currentCleaningConfiguration.value.fields.length)
                                            processedSample.value = null;
                                    }
                                }
                            }
                        }
                    }
                    if (
                        ![StatusCode.Configuration, StatusCode.Update].includes(cleaning.value.status) &&
                        !isStreamingJob.value
                    )
                        getStats();
                });
            });
        };

        const getStats = () => {
            exec(MonitoringAPI.taskStats(job.value.workflowId, cleaning.value.taskId)).then((res: any) => {
                stats.value = res.data;
            });
        };

        const next = () => {
            if (editMode.value) {
                showConfirmModal.value = true;
                return;
            }
            if (activeTab.value === 0 && !skipSampleRun.value && !processedSample.value) {
                runOnSample();
            } else {
                activeTab.value += 1;
            }
        };

        const previous = () => {
            activeTab.value -= 1;
        };

        const save = async (notify: boolean = true) => {
            try {
                await exec(
                    JobsAPI.updateStep(cleaning.value.id, {
                        configuration: configuration.value,
                        serviceVersion: process.env.VUE_APP_CLEANER_VERSION,
                        processedSample: processedSample.value,
                    }),
                );
                if (notify) (root as any).$toastr.s('Cleaning configuration saved successfuly', 'Success');
                hasChanges.value = false;
            } catch (e) {
                (root as any).$toastr.e('Saving cleaning configuration failed', 'Failed');
                hasChanges.value = true;
            }
        };

        const proceed = () => {
            editMode.value = null;
            showConfirmModal.value = false;
            next();
        };

        const finalize = () => {
            loadingFinalization.value = true;
            exec(
                JobsAPI.updateStep(cleaning.value.id, {
                    configuration: configuration.value,
                    serviceVersion: process.env.VUE_APP_CLEANER_VERSION,
                }),
            ).then(() => {
                getNextStep().then(async (stepTypeResponse: any) => {
                    nextStep.value = stepTypeResponse;

                    /**
                     * If loader step (order = 100) has a different status than "configuration",
                     * it means that the Asset has already been created
                     */
                    if (
                        cleaning.value.status === StatusCode.Update &&
                        nextStep.value.order === 100 &&
                        nextStep.value.status !== StatusCode.Configuration
                    ) {
                        refetch(); // refetch job with its steps after the cleaning step is updated
                        if (job.value?.asset && job.value.asset.id) {
                            await updateAssetAfterFailedStep(job.value);
                            await exec(JobsAPI.finalize(cleaning.value.id));
                            restartedStep.value = true;
                        } else {
                            (root as any).$toastr.e(
                                'Failed finalising revised Cleaning step due to an error',
                                'Failed',
                            );
                        }
                    } else {
                        await exec(JobsAPI.finalize(cleaning.value.id));
                        showFinalizeModal.value = true;
                    }
                    loadingFinalization.value = true;
                });
            });
        };

        const cancel = () => {
            root.$router.push({ name: 'data-checkin-jobs', query: JSON.parse(props.queryParams) });
        };

        const restartStep = async () => {
            try {
                await exec(JobsAPI.restartStep(cleaning.value.id)).then((res: any) => {
                    cleaning.value = res.data;
                    currentCleaningConfiguration.value = cleaning.value.configuration;
                });
                (root as any).$toastr.s(
                    'The configuration of the cleaning step is now available for updates.',
                    'Success',
                );
                activeTab.value = 0;
            } catch (e) {
                (root as any).$toastr.e('Revising of the configuration of the cleaning step failed', 'Failed');
            }
        };

        const hasDifferenceInConfiguration = computed(() => {
            if (
                cleaning.value &&
                cleaning.value.status === StatusCode.Update &&
                message.value &&
                currentCleaningConfiguration.value
            ) {
                return JSON.stringify(currentCleaningConfiguration.value) !== JSON.stringify(configuration.value);
            }

            return true;
        });

        const stepStatus = computed(() =>
            cleaning.value && cleaning.value.status ? cleaning.value.status : StatusCode.Configuration,
        );

        const pageLoading = computed(() => {
            return loadingFinalization.value || loading.value || jobLoading.value;
        });

        const updateProcessedSample = async (sampleData: any, sampleStats: any = null) => {
            processedSample.value = sampleData;
            if (sampleData) {
                next();
            } else {
                await save(false);
                if (sampleStats?.input_records > 0 && sampleStats?.output_records === 0) {
                    problematicSampleConstraints.value = sampleStats.stats_per_field.filter(
                        (field: any) => field.dropped > 0,
                    );
                    showEmptySampleModal.value = true;
                } else {
                    problematicSampleConstraints.value = sampleStats.stats_per_field.filter(
                        (field: any) => !!field.error_code,
                    );
                }
            }
        };

        const configurationChanged = () => {
            hasChanges.value = true;
            processedSample.value = null;
        };

        const { loadingSampleRun, executeSampleRun, skipSampleRun, onMessage } = useSampleRun(
            cleaning,
            job,
            root,
            updateProcessedSample,
        );

        const runOnSample = async () => {
            problematicSampleConstraints.value = [];
            await save(false);
            executeSampleRun();
        };

        if (skipSampleRun.value) {
            steps.value = steps.value.filter((step: any) => step.key !== 'rules');
            finalTabNumber.value = 1;
        }

        const updateSampleFailedConstraints = (constraintId: number) => {
            problematicSampleConstraints.value = problematicSampleConstraints.value.filter(
                (constraint: any) => constraint.id !== constraintId,
            );
        };

        const proceedWithEmptyProcessedSample = () => {
            processedSample.value = [];
            showEmptySampleModal.value = false;
            next();
        };

        const { subscribe, unsubscribe, WebSocketsEvents, leaveSocketRoom, WebSocketsRoomTypes } = useSockets();

        // When job is ready, retrieve cleaning step
        watch(
            () => job.value,
            async () => getStep(),
        );

        const unlockJob = async () => {
            await exec(JobsAPI.unlock(Number(props.id)));
        };

        onMounted(async () => {
            if (!isConfigEmpty(cleaning.value)) await next();
            subscribe(WebSocketsEvents.Workflow, (msg: any) => onMessage(msg));

            window.addEventListener('beforeunload', unlockJob);
        });

        onUnmounted(async () => {
            unlockJob();
        });

        onBeforeUnmount(() => {
            unsubscribe(WebSocketsEvents.Workflow);
            leaveSocketRoom(WebSocketsRoomTypes.Workflow, job.value?.workflowId);
        });

        return {
            activeTab,
            cancel,
            configuration,
            error,
            finalize,
            hasChanges,
            editMode,
            isFinalized,
            job,
            jobError,
            jobLoading,
            loading,
            cleaning,
            cleaningRef,
            next,
            previous,
            save,
            steps,
            showFinalizeModal,
            nextStep,
            fixedSample,
            mode,
            message,
            stats,
            isMacOS,
            proceed,
            showConfirmModal,
            StatusCode,
            showOrderInformation,
            canRestart,
            restartStep,
            hasDifferenceInConfiguration,
            restartedStep,
            pageLoading,
            stepStatus,
            configurationChanged,
            previousStepSample,
            loadingSampleRun,
            processedSample,
            runOnSample,
            problematicSampleConstraints,
            updateSampleFailedConstraints,
            skipSampleRun,
            finalTabNumber,
            showEmptySampleModal,
            proceedWithEmptyProcessedSample,
            alternateNames,
            previousProcessedSample,
            canRunManyTimes,
        };
    },
});
