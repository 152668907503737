




































































import { computed, defineComponent, ref, watch } from '@vue/composition-api';
import { useCleaning } from '../../../composable';

export default defineComponent({
    name: 'ConstraintText',
    props: {
        types: {
            type: Array,
            required: true,
        },
        constraint: {
            type: Object,
            required: true,
        },
        ending: {
            type: String,
            default: ',',
        },
        fields: {
            type: Array,
            default: () => [],
        },
        hasStats: {
            type: Boolean,
            default: false,
        },
        nested: {
            type: Boolean,
            default: false,
        },
    },
    setup(props) {
        const getConstraintText = ref<any>();
        const getOutliersRuleText = ref<any>();
        const typesRef = computed(() => props.types);

        watch(
            () => props.types,
            () => {
                const cleaning = useCleaning(typesRef as any, props.fields);
                getConstraintText.value = cleaning.getConstraintText;
                getOutliersRuleText.value = cleaning.getOutliersRuleText;
            },
            { immediate: true },
        );

        return { getConstraintText, getOutliersRuleText };
    },
});
