



































































































































import { useFilters } from '@/app/composable/filters';
import { ExclamationIcon } from '@vue-hero-icons/outline';
import { computed, defineComponent, ref } from '@vue/composition-api';
import { FieldPath } from '../../components';
import { useAnonymisation } from '../../composable';
import { AnonymisationType, GeneralizationMethod } from '../../constants';

export default defineComponent({
    name: 'FieldBlock',
    components: {
        FieldPath,
        ExclamationIcon,
    },
    props: {
        field: {
            type: Object,
            required: true,
        },
        selected: {
            type: Boolean,
            default: false,
        },
        disabled: {
            type: Boolean,
            default: false,
        },
        incomplete: {
            type: Array,
            default: null,
        },
        isFinalized: {
            type: Boolean,
            default: false,
        },
        sample: {
            type: Array,
            required: false,
        },
        stats: {
            type: Object,
            required: false,
        },
        modified: {
            type: Boolean,
            default: false,
        },
    },
    setup(props, { emit }) {
        const expanded = ref<boolean>(false);
        const { formatNumber } = useFilters();
        const { getNextLevel } = useAnonymisation();

        const classes = computed(() => {
            if (props.selected) return 'rounded-r border-primary-700';
            return 'rounded border-white';
        });

        const fieldClicked = () => {
            emit('toggle-selection');
            if (props.disabled) {
                expanded.value = !expanded.value;
            }
        };

        const getDatetimeText = (level: number) => {
            const datetimeParts = ['seconds', 'minutes', 'hours', 'day', 'month', 'year (decade)'];
            const dateParts = ['day', 'month', 'year (decade)'];
            const timeParts = ['seconds', 'minutes', 'hours'];
            if (props.field.type === 'datetime') {
                return datetimeParts[level - 1];
            }
            if (props.field.type === 'date') {
                return dateParts[level - 1];
            }
            return timeParts[level - 1];
        };

        const getIntervalRules = (rules: Array<Array<string>>, level: any) => {
            if (props.isFinalized && props.stats) {
                if (level) {
                    rules.push(['Field values were generalised in numerical intervals of size', `${level.interval}.`]);
                }
            } else {
                let intervalValues = '';
                for (let i = 0; i < props.field.options.levels.length; i += 1) {
                    intervalValues += `${props.field.options.levels[i].interval}, `;
                }
                intervalValues = intervalValues.slice(0, -2);
                rules.push([
                    'Field values are likely to be generalised in numerical intervals of size',
                    `${intervalValues}.`,
                ]);
                if (props.field.options.leveling === 'auto') {
                    rules.push(['Additional generalisation levels may be applied.']);
                }
            }
        };

        const getNumericalGroupRules = (rules: Array<Array<string>>, level: any) => {
            if (props.isFinalized && props.stats) {
                if (level) {
                    level.forEach((group: any) => {
                        let { label } = group;
                        if (!label || label.length === 0) {
                            label = `${group.from}-${group.to}`;
                        }
                        rules.push([
                            'Field values from',
                            group.from,
                            'to',
                            group.to,
                            'were replaced with the label',
                            `'${label}'.`,
                        ]);
                    });
                }
            } else {
                props.field.options.levels[0].forEach((group: any) => {
                    let { label } = group;
                    if (!label || label.length === 0) {
                        label = `${group.from}-${group.to}`;
                    }
                    rules.push([
                        'Field values from',
                        group.from,
                        'to',
                        group.to,
                        'are likely to be replaced with the label',
                        `'${label}'.`,
                    ]);
                });
                if (props.field.options.leveling === 'auto' || props.field.options.levels > 1) {
                    rules.push(['Additional generalisation levels may be applied.']);
                }
            }
        };

        const getMaskingRules = (rules: Array<Array<string>>, level: any) => {
            if (props.isFinalized && props.stats) {
                if (level === 1) {
                    rules.push([
                        'The',
                        'last',
                        'character of the values was replaced with the masking character',
                        `'${props.field.options.maskingChar}'.`,
                    ]);
                } else {
                    rules.push([
                        'The',
                        `last ${level}`,
                        'characters of the values were replaced with the masking character',
                        `'${props.field.options.maskingChar}'.`,
                    ]);
                }
            } else {
                rules.push([
                    'Field values are likely to be obscured by the masking character',
                    `'${props.field.options.maskingChar}'.`,
                ]);
            }
        };

        const getDatetimeRules = (rules: Array<Array<string>>, level: any) => {
            if (props.isFinalized && props.stats) {
                let datetimeValues = '';
                for (let i = 1; i <= level; i += 1) {
                    datetimeValues += `${getDatetimeText(i)}, `;
                }
                datetimeValues = datetimeValues.slice(0, -2);
                rules.push([
                    'The following parts have been',
                    'reset',
                    `from the ${props.field.type} values:`,
                    `${datetimeValues}.`,
                ]);
            } else {
                rules.push([
                    'Field values are likely to be generalised by',
                    'reseting',
                    'different parts of the datetime, starting from',
                    'seconds',
                    'until',
                    `${getDatetimeText(props.field.options.finalLevel)}.`,
                ]);
            }
        };

        const getBooleanRules = (rules: Array<Array<string>>) => {
            let verb = 'are likely to be';
            if (props.isFinalized && props.stats) {
                verb = 'were';
            }
            if (props.field.options.show) {
                rules.push([`Field values ${verb} generalised into the`, 'same group.']);
            } else {
                rules.push([`Field values ${verb}`, 'replaced', 'with the character', `'*'.`]);
            }
        };

        const anonymisationRules = computed(() => {
            const rules: Array<Array<string>> = [];
            let level = null;
            for (let i = 1; i <= props.stats?.generalizationLevel; i += 1) {
                level = getNextLevel(i, level, props.field);
            }
            if (props.field.anonymisationType === AnonymisationType.QuasiIdentifier) {
                if (props.stats && props.stats.generalizationLevel === 0) {
                    rules.push(['Field values remained unchanged.']);
                } else {
                    switch (props.field.generalization) {
                        case GeneralizationMethod.Interval:
                            getIntervalRules(rules, level);
                            break;
                        case GeneralizationMethod.NumericalGroup:
                            getNumericalGroupRules(rules, level);
                            break;
                        case GeneralizationMethod.Datetime:
                        case GeneralizationMethod.Date:
                        case GeneralizationMethod.Time:
                            getDatetimeRules(rules, level);
                            break;
                        case GeneralizationMethod.Masking:
                            getMaskingRules(rules, level);
                            break;
                        case GeneralizationMethod.BooleanGroup:
                            getBooleanRules(rules);

                            break;
                        default:
                        // do nothing
                    }
                }
            } else if (props.field.anonymisationType === AnonymisationType.Identifier) {
                if (props.stats) {
                    if (props.field.options.anonymisationMethod === 'drop') {
                        rules.push(['This column was', 'dropped', 'from the dataset.']);
                    } else {
                        rules.push(['Field values were', 'replaced', 'with the character', '*', '.']);
                    }
                } else if (props.field.options.anonymisationMethod === 'drop') {
                    rules.push(['This column will be', 'dropped', 'from the dataset.']);
                } else {
                    rules.push(['Field values will be', 'replaced', 'with the character', '*', '.']);
                }
            } else if (props.field.anonymisationType === AnonymisationType.Sensitive) {
                rules.push([
                    `Field values ${props.stats ? 'remained' : 'will remain'} unchanged. ${
                        !props.stats
                            ? 'The anonymisation algorithm will make sure to protect an individual to be identified by generalising the quasi-identifiers.'
                            : ''
                    }`,
                ]);
            }
            return rules;
        });

        const fieldMethod = computed(() => {
            if (props.field.anonymisationType === AnonymisationType.QuasiIdentifier) {
                return props.field.generalization;
            }
            if (props.field.anonymisationType === AnonymisationType.Identifier) {
                if (props.field.options.anonymisationMethod === 'drop') return 'Drop Column';
                return 'Hide Value';
            }
            return null;
        });

        return {
            classes,
            expanded,
            fieldClicked,
            anonymisationRules,
            fieldMethod,
            formatNumber,
            AnonymisationType,
        };
    },
});
