









































































import { defineComponent, PropType, ref } from '@vue/composition-api';
import { extend } from 'vee-validate';
import { HarvesterSourceType } from '@/modules/data-checkin/constants';
import ExternalKafkaConfiguration from './ExternalKafkaConfiguration.vue';
import ExternalMQTTConfiguration from './ExternalMQTTConfiguration.vue';
import { PubSubMechanismOptions } from '../../components';
import { Streaming } from '../../types/streaming.interface';
import { requiredValidator } from '@/app/validators';

extend('required', requiredValidator);

export default defineComponent({
    name: 'ExternalStreamingConfiguration',
    model: {
        prop: 'configuration',
    },
    props: {
        configuration: {
            type: Object as PropType<Streaming>,
            required: true,
        },
        sample: {
            type: [Object, Array],
            required: false,
        },
        files: {
            type: Object,
            required: true,
        },
        activeTab: {
            type: Number,
            required: true,
        },
        jobId: {
            type: Number,
            required: true,
        },
        completed: {
            type: Boolean,
            default: true,
        },
        running: {
            type: Boolean,
            default: true,
        },

        isDeprecated: {
            type: Boolean,
            default: false,
        },
        workflowId: {
            type: String,
            required: true,
        },
        jobCreatedById: {
            type: Number,
        },
        pipelineFinalized: {
            type: Boolean,
            required: true,
        },
        stepId: {
            type: Number,
            required: true,
        },
        isFinalized: {
            type: Boolean,
            default: false,
        },
        loadingFinalization: {
            type: Boolean,
            default: false,
        },
        jobConfig: {
            type: Object,
            required: false,
        },
        basePath: {
            type: String,
            required: true,
        },
        isOnUpdate: {
            type: Boolean,
            default: false,
        },
    },
    components: {
        PubSubMechanismOptions,
        ExternalKafkaConfiguration,
        ExternalMQTTConfiguration,
    },
    setup(props) {
        const externalMQTTConfigurationRef = ref<any>(null);

        const validate = async () =>
            props.configuration.mechanism === HarvesterSourceType.MQTT
                ? externalMQTTConfigurationRef.value.validate()
                : true;

        return {
            HarvesterSourceType,
            externalMQTTConfigurationRef,
            validate,
        };
    },
});
