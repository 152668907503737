





































































































































































































































































































import { ButtonGroup, TwButton } from '@/app/components';
import Scrollbar from '@/app/components/Scrollbar.vue';
import { useFilters } from '@/app/composable/filters';
import { maxValueValidator, minValueValidator, requiredIfValidator, requiredValidator } from '@/app/validators';
import { computed, defineComponent, ref } from '@vue/composition-api';
import * as R from 'ramda';
import { extend, ValidationObserver } from 'vee-validate';
import WizardActions from '../../components/WizardActions.vue';
import { useAnonymisation, useSampleFields } from '../../composable';
import { AnonymisationDescriptions, AnonymisationType, GeneralizationMethod, StatusCode } from '../../constants';
import { BooleanGroup, Datetime, HandleNullValues, IntervalNumericalGroup, Masking } from './edit-field';
import FieldBlock from './FieldBlock.vue';
import PreviewField from './preview-field/PreviewField.vue';

extend('required', requiredValidator);
extend('required_if', requiredIfValidator);
extend('min_value', minValueValidator);
extend('max_value', maxValueValidator);

export default defineComponent({
    name: 'AnonymisationConfiguration',
    components: {
        FieldBlock,
        ButtonGroup,
        Scrollbar,
        ValidationObserver,
        WizardActions,
        IntervalNumericalGroup,
        BooleanGroup,
        Masking,
        Datetime,
        PreviewField,
        HandleNullValues,
        TwButton,
    },
    model: {
        prop: 'configuration',
    },
    props: {
        jobId: {
            type: [Number, String],
            required: true,
        },
        configuration: {
            type: Object,
            required: true,
        },
        sample: {
            type: Array,
            required: true,
        },
        previousStepSample: {
            type: Array,
            required: false,
        },
        hasChanges: {
            type: Boolean,
            required: true,
        },
        isFinalized: {
            type: Boolean,
            default: false,
        },
        isLoading: {
            type: Boolean,
            default: false,
        },
        canRestart: {
            type: Boolean,
            default: false,
        },
        stepStatus: {
            type: String,
            default: 'configuration',
        },
        queryParams: {
            type: String,
            default: '{}',
        },
    },
    setup(props, { root, emit }) {
        const selectedField = ref<any>(null);
        const activeFilter = ref<string | null>('all');
        const editing = ref<boolean>(false);
        const validationErrors = ref<string[]>([]);
        const { countDecimals } = useFilters();
        const {
            getGeneralizationDescription,
            getGeneralizationMethodOptions,
            convertReplaceNullValues,
            initializeAnonymizationType,
            initializeGeneralizationMethod,
            getSampleValue,
        } = useAnonymisation();

        const saveChanges = () => {
            if (selectedField.value) {
                const fieldIndex = props.configuration.fields.findIndex(
                    (f: any) => f.name === selectedField.value.name,
                );
                props.configuration.fields[fieldIndex].anonymisationType = selectedField.value.anonymisationType; // eslint-disable-line no-param-reassign
                props.configuration.fields[fieldIndex].generalization = selectedField.value.generalization; // eslint-disable-line no-param-reassign
                props.configuration.fields[fieldIndex].options = R.clone(selectedField.value.options); // eslint-disable-line no-param-reassign
            }
            emit('save');
        };

        const cancelChanges = () => {
            root.$router.push({ name: 'data-checkin-jobs', query: JSON.parse(props.queryParams) });
        };

        const next = () => {
            if (editing.value) {
                (root as any).$toastr.i('Apply your changes before you proceed.', 'Warning');
            } else {
                const sensitives = props.configuration.fields.filter(
                    (field: any) => field.anonymisationType === AnonymisationType.Sensitive,
                );
                const quasiIdentifiers = props.configuration.fields.filter(
                    (field: any) => field.anonymisationType === AnonymisationType.QuasiIdentifier,
                );
                if (sensitives.length > 0 && quasiIdentifiers.length === 0) {
                    (root as any).$toastr.i(
                        'Using Sensitive fields, you must set at least one field as Quasi-Identifier.',
                        'Warning',
                    );
                } else {
                    emit('next');
                }
            }
        };

        const addMissingText = (text: string, fieldName: string, fields: any) => {
            if (!fields[fieldName]) {
                // eslint-disable-next-line no-param-reassign
                fields[fieldName] = [];
            }
            if (!fields[fieldName].includes(text)) {
                fields[fieldName].push(text);
            }
        };

        const removeMissingText = (text: string, fieldName: string, fields: any) => {
            if (fields[fieldName]) {
                if (fields[fieldName].length === 1 && fields[fieldName][0] === text) {
                    // eslint-disable-next-line no-param-reassign
                    delete fields[fieldName];
                } else {
                    // eslint-disable-next-line no-param-reassign
                    fields[fieldName] = fields[fieldName].filter((value: string) => value !== text);
                }
            }
        };

        const missingValues = (field: any) => {
            if (field.generalization === GeneralizationMethod.Interval) {
                for (let i = 0; i < field.options.levels.length; i += 1) {
                    if (!field.options.levels[i].interval || field.options.levels[i].interval === '') {
                        return true;
                    }
                }
                return false;
            }
            if (field.generalization === GeneralizationMethod.NumericalGroup) {
                let requiredLabels = false;
                for (let i = 0; i < field.options.levels.length; i += 1) {
                    for (let j = 0; j < field.options.levels[i].length; j += 1) {
                        if (field.options.levels[i].label && field.options.levels[i].label.length > 0)
                            requiredLabels = true;
                        break;
                    }
                    if (requiredLabels) break;
                }
                for (let i = 0; i < field.options.levels.length; i += 1) {
                    for (let j = 0; j < field.options.levels[i].length; j += 1) {
                        if (
                            !field.options.levels[i][j].from ||
                            field.options.levels[i][j].from.length === 0 ||
                            !field.options.levels[i][j].to ||
                            field.options.levels[i][j].to.length === 0
                        ) {
                            return true;
                        }
                        if (
                            requiredLabels &&
                            (!field.options.levels[i][j].label || field.options.levels[i][j].label.length === 0)
                        )
                            return true;
                    }
                }
            }
            return false;
        };

        const validateValues = (field: any) => {
            let validationError = null;
            if (field.generalization === GeneralizationMethod.NumericalGroup) {
                const sample: any = R.clone(props.sample);
                let increment = 1;
                if (field.type === 'double' || field.type === 'float') {
                    let value = getSampleValue(sample[0], field);
                    if (R.is(Array, value)) [value] = [value[0]];
                    increment = 1 / 10 ** countDecimals(value);
                }
                for (let i = 0; i < field.options.levels.length; i += 1) {
                    for (let j = 0; j < field.options.levels[i].length; j += 1) {
                        if (
                            parseInt(field.options.levels[i][j].to, 10) <= parseInt(field.options.levels[i][j].from, 10)
                        ) {
                            validationError = `<span class="font-bold">Incorrect Values:</span> Please make sure that all numerical groups have valid values ('to' values must be greater than 'from' values).`;
                        }
                        if (
                            j > 0 &&
                            parseInt(field.options.levels[i][j].from, 10) <=
                                parseInt(field.options.levels[i][j - 1].to, 10)
                        ) {
                            validationError = `<span class="font-bold">Overlapping Values:</span> Please make sure that there are no overlapping values between the numerical groups.`;
                        }
                        if (
                            j > 0 &&
                            parseInt(field.options.levels[i][j].from, 10) - increment >
                                parseInt(field.options.levels[i][j - 1].to, 10)
                        ) {
                            validationError = `<span class="font-bold">Discontinuous Numerical Groups:</span> Please make sure that there are no values outside the specified range.`;
                        }
                    }
                }
            }
            return validationError;
        };

        const incompleteFields = computed(() => {
            const fields = {};
            props.configuration.fields.forEach((field: any) => {
                if (field.anonymisationType === AnonymisationType.QuasiIdentifier) {
                    if (!field.generalization) {
                        addMissingText('Missing generalisation method', field.name, fields);
                    } else {
                        removeMissingText('Missing generalisation method', field.name, fields);
                    }
                    if (missingValues(field)) {
                        addMissingText('Missing values', field.name, fields);
                    } else {
                        removeMissingText('Missing values', field.name, fields);
                    }
                    if (validateValues(field)) {
                        addMissingText('Validation error', field.name, fields);
                    } else {
                        removeMissingText('Validation error', field.name, fields);
                    }
                } else {
                    delete fields[field.name];
                }
            });
            return fields;
        });

        const filterFields = (option: string) => {
            activeFilter.value = option;
        };

        const filteredFields = computed(() => {
            if (activeFilter.value === 'all') {
                return props.configuration.fields;
            }
            return props.configuration.fields.filter((field: any) => field.anonymisationType === activeFilter.value);
        });

        const clearSelection = () => {
            selectedField.value = null;
        };

        const updateSelected = (value: any) => {
            if (editing.value) {
                (root as any).$toastr.i('Apply your changes before you proceed.', 'Warning');
                return;
            }
            if (selectedField.value && value.name === selectedField.value.name) {
                selectedField.value = null;
            } else {
                selectedField.value = R.clone(value);
            }
        };

        const filters = computed(() => {
            const types = props.configuration.fields
                .map((field: any) => field.type)
                .filter((type: string, index: number, self: any) => self.indexOf(type) === index);
            types.unshift('all');
            return types;
        });

        const setAnonymisationType = (event: any) => {
            const init = initializeAnonymizationType(event.target.value, selectedField.value.type);
            selectedField.value.generalization = init.generalization;
            selectedField.value.options = R.clone(init.options);
            emit('changed');
        };

        const setGeneralizationMethod = (method: string) => {
            const init = initializeGeneralizationMethod(selectedField.value, method);
            selectedField.value.options = init.options;
            emit('changed');
        };

        const canApply = computed(() => {
            if (
                selectedField.value.anonymisationType === AnonymisationType.QuasiIdentifier &&
                !selectedField.value.generalization
            ) {
                return false;
            }
            return true;
        });

        const canAddGeneralizationLevel = computed(
            () =>
                selectedField.value &&
                (selectedField.value.generalization === GeneralizationMethod.Interval ||
                    selectedField.value.generalization === GeneralizationMethod.NumericalGroup) &&
                selectedField.value.options.leveling === 'custom',
        );

        const addGeneralizationLevel = () => {
            switch (selectedField.value.generalization) {
                case GeneralizationMethod.Interval:
                    selectedField.value.options.levels.push({ interval: null });
                    break;
                case GeneralizationMethod.NumericalGroup:
                    selectedField.value.options.levels.push([{ from: null, to: null, label: null }]);
                    break;
                default:
                // do nothing
            }
            emit('changed');
        };

        const removeGeneralizationLevel = (index: number) => {
            if (index === 0 && selectedField.value.options.levels.length === 1) {
                selectedField.value.options.levels[0].interval = null;
            } else {
                selectedField.value.options.levels.splice(index, 1);
            }
        };

        const enableEditing = () => {
            selectedField.value = convertReplaceNullValues(true, selectedField.value);
            editing.value = true;
            validationErrors.value.splice(0);
            if (Object.keys(incompleteFields.value).includes(String(selectedField.value.name))) {
                const error = validateValues(selectedField.value);
                if (error) validationErrors.value.push(error);
            }
        };

        const { extractFieldSample } = useSampleFields();

        const selectedFieldSample = computed(() => {
            if (props.sample) {
                const item: any = R.pick(['title', 'path', 'order'], selectedField.value);
                if (props.previousStepSample) {
                    item.sample = extractFieldSample(
                        props.previousStepSample,
                        selectedField.value.title,
                        selectedField.value.path,
                    );
                } else {
                    item.sample = extractFieldSample(
                        props.sample,
                        selectedField.value.originalName,
                        selectedField.value.originalPath,
                    );
                }
                return item;
            }
            return null;
        });

        const apply = () => {
            validationErrors.value.splice(0);
            const error = validateValues(selectedField.value);
            if (!error) {
                selectedField.value = convertReplaceNullValues(false, selectedField.value);
                const fieldIndex = props.configuration.fields.findIndex(
                    (f: any) => f.name === selectedField.value.name,
                );
                props.configuration.fields.splice(fieldIndex, 1, R.clone(selectedField.value));
                validationErrors.value.splice(0);
                editing.value = false;
                emit('changed');
            } else {
                validationErrors.value.push(error);
            }
        };

        const cancel = () => {
            const fieldIndex = props.configuration.fields.findIndex((f: any) => f.name === selectedField.value.name);
            selectedField.value.anonymisationType = props.configuration.fields[fieldIndex].anonymisationType; // eslint-disable-line no-param-reassign
            selectedField.value.generalization = props.configuration.fields[fieldIndex].generalization; // eslint-disable-line no-param-reassign
            selectedField.value.options = R.clone(props.configuration.fields[fieldIndex].options); // eslint-disable-line no-param-reassign
            editing.value = false;
            emit('changed');
        };

        const emitChanged = () => {
            emit('changed');
        };

        emit('changed');

        return {
            GeneralizationMethod,
            AnonymisationType,
            saveChanges,
            cancelChanges,
            next,
            incompleteFields,
            getGeneralizationDescription,
            getGeneralizationMethodOptions,
            AnonymisationDescriptions,
            canApply,
            activeFilter,
            filterFields,
            filteredFields,
            selectedField,
            updateSelected,
            clearSelection,
            filters,
            editing,
            setAnonymisationType,
            setGeneralizationMethod,
            canAddGeneralizationLevel,
            addGeneralizationLevel,
            removeGeneralizationLevel,
            enableEditing,
            apply,
            cancel,
            validationErrors,
            validateValues,
            selectedFieldSample,
            emitChanged,
            StatusCode,
        };
    },
});
