





















export default {
    name: 'TransformationDetails',
    props: {
        transformation: {
            type: String,
            required: true,
        },
        isFail: {
            type: Boolean,
            default: null,
        },
        icon: {
            type: Object,
            required: true,
        },
    },
};
