

































































import { defineComponent, computed } from '@vue/composition-api';
import { ExclamationIcon } from '@vue-hero-icons/outline';
import { Toggle } from '@/app/components';
import { FieldPath } from '../../components';

export default defineComponent({
    name: 'FieldView',
    components: { FieldPath, Toggle, ExclamationIcon },
    props: {
        field: {
            type: Object,
            required: true,
        },
        selected: {
            type: Boolean,
            default: false,
        },
        disabled: {
            type: Boolean,
            default: false,
        },
        isFinalized: {
            type: Boolean,
            default: false,
        },
        modified: {
            type: Boolean,
            default: false,
        },
        alternateName: {
            type: Object,
            default: null,
        },
        showAlternateNaming: {
            type: Boolean,
            default: false,
        },
    },
    setup(props, { emit }) {
        const isMacOS = window.navigator.userAgent.indexOf('Mac OS') !== -1;
        const fieldClicked = (event: MouseEvent) => {
            if (!props.isFinalized) {
                emit('toggle-selection', (event.metaKey && isMacOS) || (event.ctrlKey && !isMacOS));
            }
        };

        const updateIndex = () => {
            emit('update-index');
        };

        const tooltipMessage = computed(() => {
            if (props.field.dropped) return 'Field has been dropped in a previous step';
            if (!props.field.canBeIndexed) return `Field cannot be indexed`;
            return null;
        });

        const toggleColor = computed(() => {
            if (props.isFinalized && props.field.index) {
                return 'bg-primary-400';
            }
            if (!props.isFinalized && props.field.index) {
                return 'bg-primary-600';
            }
            if ((props.isFinalized || !props.field.canBeIndexed) && !props.field.index) {
                return 'bg-neutral-200';
            }

            if (!props.isFinalized && props.field.canBeIndexed && !props.field.index) {
                return 'bg-neutral-300';
            }
            return '';
        });

        return {
            fieldClicked,
            updateIndex,
            tooltipMessage,
            toggleColor,
        };
    },
});
