






































































































































import { useExecutionErrors } from '@/app/composable';
import { ExclamationIcon } from '@vue-hero-icons/outline';
import { computed, defineComponent, inject, PropType, ref, Ref, watch } from '@vue/composition-api';
import * as R from 'ramda';
import Draggable from 'vuedraggable';
import { FieldPath } from '../../components';
import { Stats } from '../../types/step-stats.interface';
import PredictionScore from './PredictionScore.vue';

const Dragster = require('@/app/assets/js/dragster.js'); // eslint-disable-line @typescript-eslint/no-var-requires

export default defineComponent({
    name: 'ConceptView',
    components: { PredictionScore, Draggable, FieldPath, ExclamationIcon },
    props: {
        concept: {
            type: Object,
            required: true,
        },
        selected: {
            type: Boolean,
            default: false,
        },
        latestExecutionStats: {
            type: Object as PropType<Stats>,
            required: false,
        },
        validationErrorPerId: {
            type: Object,
            required: true,
        },
        basePath: {
            type: Array,
            required: false,
            default: () => [],
        },
    },
    setup(props, { emit }) {
        const dragActive: Ref<boolean> = inject('dragging', ref(false));
        const inDropZone = ref(false);
        const dropAreaRef = ref<any>(null);

        const isMacOS = window.navigator.userAgent.indexOf('Mac OS') !== -1;

        const conceptClicked = (event: MouseEvent) => {
            emit('toggle-selection', props.concept, (event.metaKey && isMacOS) || (event.ctrlKey && !isMacOS));
        };
        const { errorMessage } = useExecutionErrors();

        const failedTransformationReason = computed(() => {
            const errorCode = props?.latestExecutionStats?.statsPerField?.find((s) => s.id === props.concept.source.id)
                ?.errorCode;
            return errorCode ? errorMessage(errorCode).error.title : null;
        });

        const droppedConcept = ref([]);
        const classes = computed(() => {
            if ((props.concept.temp && props.concept.temp.invalid) || failedTransformationReason.value) {
                if (props.selected) {
                    return 'rounded-r border-primary-700 bg-red-100';
                }
                return 'rounded bg-red-100 border-red-600';
            }
            if (props.selected) return 'rounded-r border-primary-700';
            return 'rounded border-white';
        });
        const add = () => {
            if (!inDropZone.value) {
                droppedConcept.value.splice(0);
            }

            if (droppedConcept.value.length > 0) {
                emit('concept-added', R.clone(props.concept), droppedConcept.value[0]);
                droppedConcept.value.splice(0); // Clear drop area
            }

            inDropZone.value = false;
        };

        const handleDragEnter = () => {
            inDropZone.value = true;
        };

        const handleDragLeave = () => {
            inDropZone.value = false;
        };

        const fullPath = computed(() => {
            const path: Array<string> = [...props.concept.target.path];
            if (props.concept.target.title) {
                path.push(props.concept.target.title);
            }
            return path.join(' > ');
        });

        const fullSourcePath = computed(() => {
            const path: Array<string> = [...props.concept.source.path];
            if (props.concept.source.title) {
                path.push(props.concept.source.title);
            }
            return path.join(' > ');
        });

        const sameSourceTargetType = computed(() => {
            const targetType = ['double', 'integer', 'float'].includes(props.concept.target.type)
                ? 'number'
                : props.concept.target.type;
            return targetType === props.concept.source.type;
        });

        // Adding and removing drag handlers to correctly monitor when a field is dragged inside the designated area
        // Dragster is used to provide custom drag events that ignore child elements from triggering the
        // dragleave event, making the whole process problematic
        let dragster: any = null;
        watch(dropAreaRef, (current: any, prev: any) => {
            if (current && !prev) {
                dragster = new Dragster(current.$el);
                current.$el.addEventListener('dragster:enter', handleDragEnter, false);
                current.$el.addEventListener('dragster:leave', handleDragLeave, false);
            }

            if (prev && !current && dragster) {
                dragster.removeListeners();
                dragster = null;
            }
        });

        return {
            add,
            classes,
            droppedConcept,
            dragActive,
            dropAreaRef,
            inDropZone,
            conceptClicked,
            fullPath,
            fullSourcePath,
            failedTransformationReason,
            sameSourceTargetType,
        };
    },
});
