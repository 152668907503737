









import { WorkflowType } from '@/app/constants';
import { ExecutionHistory } from '@/modules/workflow-designer/components/execution-history';
import { DataCheckinJob, DataCheckinJobStep } from '../types';
import { computed, defineComponent, ref, watch } from '@vue/composition-api';
import { useAxios } from '@/app/composable';
import { JobsAPI } from '@/modules/data-checkin/api';
import { HarvesterSourceType } from '../constants';

export default defineComponent({
    name: 'DataCheckinJobsExecutionHistory',
    props: {
        id: {
            type: String,
            required: true,
        },
        queryParams: {
            type: String,
            default: '{}',
        },
    },
    components: { ExecutionHistory },
    setup: (props: any, { root }) => {
        const { exec } = useAxios(true);
        const job = ref<DataCheckinJob | undefined>();
        const steps = ref<DataCheckinJobStep[] | undefined>();

        const processingOption = computed(() => {
            if (!steps.value) return undefined;
            const harvesterStep = steps.value.find(
                (step: DataCheckinJobStep) => step.dataCheckinStepTypeId === 1, // '1' indicates harvester step
            );

            if (
                harvesterStep &&
                [
                    HarvesterSourceType.Kafka,
                    HarvesterSourceType.ExternalKafka,
                    HarvesterSourceType.MQTT,
                    HarvesterSourceType.ExternalMQTT,
                ].includes(harvesterStep.configuration?.source)
            )
                return harvesterStep.configuration?.processing;

            return undefined;
        });

        watch(
            () => props.id,
            (newId: number) => {
                exec(JobsAPI.get(newId))
                    .then((res: any) => (job.value = res.data))
                    .catch((e) => {
                        if (e.response?.status === 403) {
                            (root as any).$toastr.e('You do not have access to view this page.', 'Access Denied');
                            root.$router.push({ name: 'data-checkin-jobs' });
                        }
                    });
                exec(JobsAPI.getJobSteps(newId)).then((res: any) => (steps.value = res.data));
            },
            { immediate: true },
        );

        return { job, processingOption, WorkflowType, steps };
    },
});
