








































































































































































































import {
    ConfirmModal,
    ESearch,
    FacetsFilters,
    Pagination,
    Scrollbar,
    SearchTerms,
    SvgImage,
    TailwindTableTabs,
    TwSelect,
} from '@/app/components';
import { useFacetsFilters, useSearch } from '@/app/composable';
import { useRoute } from '@/app/composable/router';
import store from '@/app/store';
import { useAlerts } from '@/modules/alert/composable';
import { AlertSourceType } from '@/modules/alert/constants/alert.constants';
import { FakeJobOverview, JobOverview } from '@/modules/data-checkin/components';
import { RefreshPageKeys } from '@/modules/notification/constants';
import { SearchAPI } from '@/modules/search/api';
import { FireIcon, PlusIcon, RefreshIcon } from '@vue-hero-icons/outline';
import { FilterIcon } from '@vue-hero-icons/solid';
import { computed, defineComponent, onBeforeUnmount, ref, watch } from '@vue/composition-api';
import { OrbitSpinner } from 'epic-spinners';
import * as R from 'ramda';
import { JobsAPI } from '../api';
import { useDataCheckinFilters } from '../composable/data-checkin-filters';
import { DCJSortingOption, StatusCode } from '../constants';

export default defineComponent({
    name: 'DataCheckinJobs',
    metaInfo: {
        title: 'Data Check-in Pipelines',
    },
    components: {
        ConfirmModal,
        OrbitSpinner,
        JobOverview,
        SvgImage,
        Pagination,
        FireIcon,
        PlusIcon,
        TailwindTableTabs,
        FilterIcon,
        FacetsFilters,
        TwSelect,
        SearchTerms,
        FakeJobOverview,
        Scrollbar,
        RefreshIcon,
        ESearch,
    },
    setup(props, { root }) {
        const refresh = computed(() => {
            return store.state.notificationEngine.refresh[RefreshPageKeys.DataCheckinJob];
        });

        const searchableFields = [
            {
                label: 'title',
                key: 'name.keyword',
                textColorClasses: 'text-primary-700',
                backgroundClasses: 'bg-primary-700',
                highlightClasses: 'bg-primary-300',
                widthClasses: 'pl-16 ml-2',
            },
            {
                label: 'description',
                key: 'description.keyword',
                textColorClasses: 'text-teal-700',
                backgroundClasses: 'bg-teal-700',
                highlightClasses: 'bg-teal-300',
                widthClasses: 'pl-28 ml-2',
            },
        ];

        const { checkAlert, fetchAlerts } = useAlerts(root, [AlertSourceType.DataCheckin]);
        fetchAlerts();

        const queryParams = computed(() => JSON.stringify(root.$route.query));
        const route = useRoute();
        const highlightJobId = ref<number | null>(Number(route.params.id)); // Highlights a job (when redirected from creating/updating, etc)

        // Delete confirmation modal and pointer
        const showDeleteModal = ref<boolean>(false);
        const toBeDeleted = ref<number | null>(null);
        const notificationReferenceType = ref(root.$route.params.type ?? null) as any;
        const pageSize = 10;
        const openFilters = ref<boolean>(true);
        const jobs = ref<any>([]);
        const rejectedItems = ref<number>(0);
        const totalResults = ref<number>(0);
        const user = ref(store.state.auth.user);

        const setSearchQuery = () => {
            searchQuery.value.filters =
                tab.value === 'mine'
                    ? Object.fromEntries(Object.entries(reducedDcjFilters).filter(([key]) => !key.includes('user')))
                    : reducedDcjFilters;
        };

        const error = ref<any>(null);

        const search = () => {
            setCommonSearchQueryValues();

            searchQuery.value.pipelineType = 'data-checkin';

            searchQuery.value.settings = {
                organisationId: tab.value === 'shared' && user.value.organisationId ? user.value.organisationId : null,
                userId: user.value.id,
            };

            const api = tab.value === 'mine' ? SearchAPI.myJobsSearch : SearchAPI.sharedJobsSearch;

            setQueryParams();

            exec(api(searchQuery.value))
                .then((res: any) => {
                    jobs.value = res.data?.results;
                    rejectedItems.value = res.data?.accessControl?.rejectedItems || 0;
                    totalResults.value = res.data?.total;
                    error.value = null;
                })
                .catch((e: any) => (error.value = e));
        };

        const {
            exec,
            loading,
            get,
            textNumberFilters,
            page,
            sortBy,
            tab,
            query,
            searchQuery,
            setTab,
            updateCurrentPage,
            filterChanged,
            sortByChanged,
            clearFilters,
            setCommonSearchQueryValues,
            setQueryParams,
            unsubscribeSearch,
            sortingFields,
        } = useSearch(root, 'data-checkin-jobs', pageSize, DCJSortingOption, 'mine', setSearchQuery, search);
        onBeforeUnmount(() => {
            unsubscribeSearch.value ? unsubscribeSearch.value() : undefined;
            if (refresh.value) store.dispatch.notificationEngine.disableRefreshAction(RefreshPageKeys.DataCheckinJob);
        });

        const jobTabs = computed(() => [
            { key: 'mine', label: 'My Pipelines' },
            { key: 'shared', label: 'Pipelines Shared with Me' },
        ]);

        const { dcjFilters, reducedDcjFilters } = useDataCheckinFilters(get);
        const { calculateSearchedFilters, defaultValue } = useFacetsFilters();

        const filtersInfo = computed(() => {
            switch (tab.value) {
                case 'mine':
                    return Object.fromEntries(
                        Object.entries(dcjFilters.value).filter(([key]) => !key.includes('user')),
                    );
                default:
                    return dcjFilters.value;
            }
        });

        watch(showDeleteModal, (value: boolean) => {
            if (!value) toBeDeleted.value = null;
        });

        const edit = (id: number) => {
            root.$router.push({
                name: 'data-checkin-jobs:edit',
                params: {
                    id: `${id}`,
                    queryParams: queryParams.value,
                },
            });
        };

        const openHistory = (id: number) => {
            root.$router.push({ name: 'dcj:history', params: { id: `${id}`, queryParams: queryParams.value } });
        };

        const confirmDelete = (id: number) => {
            toBeDeleted.value = id;
            showDeleteModal.value = true;
        };

        const destroy = async () => {
            if (toBeDeleted.value) {
                const idx = R.findIndex(R.propEq('id', toBeDeleted.value), jobs.value as any[]);
                if (~idx) {
                    showDeleteModal.value = false;
                    try {
                        const referenceId = toBeDeleted.value;
                        await exec(JobsAPI.delete(toBeDeleted.value));

                        store.commit.notificationEngine.SET_NOTIFICATIONS_AFTER_REFERENCED_ITEM_DELETE({
                            referenceId,
                            referenceType: notificationReferenceType.value
                                ? notificationReferenceType.value
                                : 'DataCheckinJob',
                        });
                        (root as any).$toastr.s('Data Check-in Pipeline deleted successfuly', 'Success');
                        jobs.value.splice(idx, 1);
                    } catch (e: any) {
                        if (e.response?.data?.message === 'Locked')
                            (root as any).$toastr.e('The Data Check-in Pipeline is locked', 'Error');
                        else (root as any).$toastr.e('Deleting Data Check-in Pipeline failed', 'Error');
                    }
                }
            }
        };

        const retryExecution = async (jobId: number) => {
            await exec(JobsAPI.retryExecution(jobId))
                .then(() => {
                    const foundJob = jobs.value.find((job: any) => job.id === jobId);
                    for (let i = 0; i < foundJob.dataCheckinJobSteps.length; i++) {
                        foundJob.dataCheckinJobSteps[i].status = StatusCode.Queued;
                    }
                    (root as any).$toastr.s('Job resubmitted for execution');
                })
                .catch(() => {
                    (root as any).$toastr.e('Job resubmission failed', 'Error');
                });
        };

        const clonePipeline = async (jobId: number) => {
            (root as any).$router.push({
                name: 'data-checkin-jobs:clone',
                params: { id: jobId, queryParams: queryParams.value },
            });
        };

        const currentSearchedFilters = computed(() =>
            calculateSearchedFilters(searchQuery.value.filters, filtersInfo.value),
        );

        const refreshPage = () => {
            search();
            store.dispatch.notificationEngine.disableRefreshAction(RefreshPageKeys.DataCheckinJob);
        };
        textNumberFilters.value = { pipeline: defaultValue(get, 'pipeline', false) };
        setSearchQuery();
        search();

        return {
            confirmDelete,
            destroy,
            edit,
            error,
            jobs,
            loading,
            pageSize,
            showDeleteModal,
            page,
            openHistory,
            retryExecution,
            clonePipeline,
            jobTabs,
            setTab,
            tab,
            filterChanged,
            clearFilters,
            openFilters,
            query,
            searchQuery,
            sortingFields,
            sortByChanged,
            sortBy,
            updateCurrentPage,
            currentSearchedFilters,
            totalResults,
            rejectedItems,
            filtersInfo,
            queryParams,
            textNumberFilters,
            refresh,
            refreshPage,
            highlightJobId,
            checkAlert,
            searchableFields,
        };
    },
});
