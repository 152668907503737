















































































































































import { PlusIcon } from '@vue-hero-icons/outline';
import { computed, defineComponent, PropType, ref, watch } from '@vue/composition-api';
import * as R from 'ramda';
import Draggable from 'vuedraggable';
import { useCleaningConstraints } from '../../composable';
import { Constraint, ConstraintMode, ConstraintStats, FieldConfiguration } from './cleaning.types';
import EditAdvancedConstraint from './constraints/EditAdvancedConstraint.vue';
import EditConstraint from './constraints/EditConstraint.vue';
import ViewConstraint from './constraints/ViewConstraint.vue';

export default defineComponent({
    name: 'CleaningConstraints',
    components: { Draggable, ViewConstraint, EditConstraint, EditAdvancedConstraint, PlusIcon },
    props: {
        configuration: {
            type: Object,
            required: true,
        },
        selectedFields: {
            type: Array as PropType<FieldConfiguration[]>,
            required: true,
        },
        disabled: {
            type: Boolean,
            default: true,
        },
        mode: {
            type: String,
            default: null,
        },
        problematicSampleConstraints: {
            type: Array as PropType<ConstraintStats[]>,
            default: () => [],
        },
        previousStepSample: {
            type: Array,
            default: null,
        },
        sample: {
            type: Array,
            required: true,
        },
        canRunManyTimes: {
            type: Boolean,
            default: false,
        },
    },
    setup(props, { emit }) {
        const editIndex = ref<number>(-1);
        const selectedFieldsRef = computed(() => props.selectedFields);
        const {
            selectedFieldsTypes,
            isEmpty,
            alreadyDefinedConstraintTypes,
            referencedFields,
            availableConstraintOptions,
        } = useCleaningConstraints(props.configuration, selectedFieldsRef);

        const constraintId = computed(() => {
            let constraints: number[] = [];
            props.configuration.fields.forEach((field: any) => {
                constraints = constraints.concat(field.constraints);
            });
            constraints = R.uniq(constraints.map((constraint: any) => constraint.id));
            if (constraints.length === 0) {
                return 1;
            }
            return Math.max(...constraints) + 1;
        });

        const processedConstraints = ref<Constraint[]>([]);
        const selectedFieldsConstraints = ref<Constraint[]>([]);
        const editingConstraint = ref<Constraint | null>(null);

        watch(
            () => props.selectedFields,
            (fields: any) => {
                editIndex.value = -1;
                let array: any[] = [];
                fields.forEach((field: any) => {
                    array = array.concat(
                        field.constraints.map((constraint: Constraint) => ({ ...constraint, fieldName: field.name })),
                    );
                });
                // get only the common constraints from the selected fields
                if (fields.length > 1) {
                    const finalArray: any[] = [];
                    const ids = R.uniq(array.map((constraint) => constraint.referenceId));
                    ids.forEach((id: number) => {
                        const count = array.filter((constraint: any) => constraint.referenceId === id).length;
                        if (count === fields.length) {
                            finalArray.push(array.find((constraint: any) => constraint.referenceId === id));
                        }
                    });
                    array = finalArray;
                }
                processedConstraints.value = array;
                selectedFieldsConstraints.value = R.clone(array);
                emit('change-edit-mode', null);
            },
        );

        const addConstraint = () => {
            processedConstraints.value.push({
                id: constraintId.value,
                referenceId: constraintId.value,
                type: null,
                details: null,
                outliersRule: {
                    type: null,
                    replaceValue: null,
                    secondaryRule: null,
                },
                fieldName:
                    props.selectedFields.length > 1
                        ? props.selectedFields.map((field) => field.name as string)
                        : (props.selectedFields[0].name as string),
            });
            editIndex.value = processedConstraints.value.length - 1;
        };

        const addAdvancedConstraint = () => {
            processedConstraints.value.push({
                id: constraintId.value,
                referenceId: constraintId.value,
                structure: {
                    operator: 'AND',
                    conditions: [],
                },
                outliersRule: {
                    type: null,
                    replaceValue: null,
                    secondaryRule: null,
                },
                fieldName:
                    props.selectedFields.length > 1
                        ? props.selectedFields.map((field) => field.name as string)
                        : (props.selectedFields[0].name as string),
            });
            editIndex.value = processedConstraints.value.length - 1;
        };

        const cancelAddition = () => {
            processedConstraints.value.pop();
            editIndex.value = -1;
            emit('change-edit-mode', null);
        };

        const cancelUpdate = () => {
            processedConstraints.value[editIndex.value] = editingConstraint.value as Constraint;
            editIndex.value = -1;
            editingConstraint.value = null;
            emit('change-edit-mode', null);
        };

        const saveConstraint = (mode: string, index: number) => {
            let count = 0;
            props.selectedFields.forEach((selectedField: any) => {
                const constraint = processedConstraints.value[index];
                if (mode === ConstraintMode.Create) {
                    if (count !== 0) {
                        constraint.id += 1;
                    }
                    count += 1;
                    selectedField.constraints.push(R.clone(constraint));
                } else {
                    const idx = selectedField.constraints.findIndex(
                        (field: any) => field.referenceId === constraint.referenceId,
                    );
                    if (mode === ConstraintMode.Update) {
                        constraint.id = selectedField.constraints[idx].id;
                        // eslint-disable-next-line no-param-reassign
                        selectedField.constraints[idx] = R.clone(constraint);
                        // if a common constraint is updated only for some of the fields then remove it from common
                        props.configuration.fields.forEach((field: any) => {
                            const constr = field.constraints.find(
                                (con: any) => con.referenceId === constraint.referenceId,
                            );
                            if (constr && !props.selectedFields.find((f: any) => f.id === field.id)) {
                                if (constr.referenceId === constr.id) {
                                    constr.referenceId += 1;
                                } else {
                                    constr.referenceId = constr.id;
                                }
                            }
                        });
                    } else if (mode === ConstraintMode.Delete) {
                        selectedField.constraints.splice(idx, 1);
                    }
                }
                emit('constraint-updated', constraint.id);
            });
            if (mode === ConstraintMode.Delete) {
                processedConstraints.value.splice(index, 1);
            }
            editIndex.value = -1;
            selectedFieldsConstraints.value = R.clone(processedConstraints.value);
            emit('save-constraints');
        };

        const constraintMoved = (event: any) => {
            const { oldIndex, newIndex } = event;
            props.selectedFields.forEach((selectedField: any) => {
                const oldIdx = selectedField.constraints.findIndex(
                    (field: any) => field.referenceId === processedConstraints.value[newIndex].referenceId,
                );
                const newIdx = selectedField.constraints.findIndex(
                    (field: any) => field.referenceId === processedConstraints.value[oldIndex].referenceId,
                );
                // eslint-disable-next-line no-param-reassign
                selectedField.constraints = R.move(oldIdx, newIdx, selectedField.constraints);
                emit('save-constraints');
            });
        };

        const canAddConstraint = computed(() => {
            let canAdd = false;
            let options = availableConstraintOptions.value;
            options = options.map((constraint: any) => constraint.id);
            options.forEach((option: any) => {
                if (!alreadyDefinedConstraintTypes.value.includes(option)) {
                    canAdd = true;
                }
            });
            return canAdd;
        });

        const isSimpleConstraint = (constraint: Constraint) => constraint.structure === undefined;

        const enableEditing = (index: number) => {
            editIndex.value = index;
            editingConstraint.value = R.clone(processedConstraints.value[index]);
        };

        const errorCode = (id: number) => {
            const foundConstraint: any = props.problematicSampleConstraints.find((c) => c.id === id);
            return foundConstraint?.error_code;
        };

        const isDropped = (id: number) => {
            const foundConstraint: any = props.problematicSampleConstraints.find((c) => c.id === id);
            return !foundConstraint?.error_code && foundConstraint?.dropped > 0;
        };

        const multipleOutlierFields = computed(
            () =>
                R.is(Array, props.selectedFields) &&
                (!!props.selectedFields.every((field) => !!field.multiple) ||
                    props.selectedFields.every((field) =>
                        field.path?.some((pathPart: string) => pathPart.endsWith('[]')),
                    )),
        );

        return {
            ConstraintMode,
            isEmpty,
            saveConstraint,
            processedConstraints,
            selectedFieldsConstraints,
            editIndex,
            selectedFieldsTypes,
            referencedFields,
            addConstraint,
            addAdvancedConstraint,
            constraintMoved,
            cancelAddition,
            cancelUpdate,
            availableConstraintOptions,
            alreadyDefinedConstraintTypes,
            canAddConstraint,
            emit,
            isSimpleConstraint,
            enableEditing,
            editingConstraint,
            errorCode,
            isDropped,
            multipleOutlierFields,
        };
    },
});
