




























































































































































































































































































































































































































































































import { AlertBanner, ConfirmModal, FormBlock, JsonParser, TwButton } from '@/app/components';
import { useAxios, useFilters, useJsonObject } from '@/app/composable';
import { MqttAPI } from '@/modules/data-checkin/api';
import { computed, defineComponent, PropType, reactive, ref, watch } from '@vue/composition-api';
import dayjs from 'dayjs';
import { OrbitSpinner } from 'epic-spinners';
import * as R from 'ramda';
import { DocumentIcon } from '@vue-hero-icons/outline';
import { ExclamationIcon, ChevronRightIcon } from '@vue-hero-icons/solid';
import { ValidationObserver, ValidationProvider, extend } from 'vee-validate';
import ClickOutside from 'vue-click-outside';
import { RetrievalSettings } from '../../components';
import { useHarvester } from '../../composable';
import TopicHierarchy from './streaming/TopicHierarchy.vue';
import TopicParameter from './streaming/TopicParameter.vue';
import { HarvesterSourceType, ProcessingOptions, SAMPLE_LIMIT } from '../../constants';
import { MqttTopic, Streaming } from '../../types/streaming.interface';
import { requiredValidator } from '@/app/validators';

const { formatBytes } = useFilters();

extend('required', requiredValidator);

const protocolVersionOptions = [
    { label: 'v5.0', value: '5' },
    { label: 'v3.1.1', value: '3.1.1' },
    { label: 'v3.1', value: '3.1' },
];

export default defineComponent({
    name: 'ExternalMQTTConfiguration',
    model: {
        prop: 'configuration',
    },
    props: {
        configuration: {
            type: Object as PropType<Streaming>,
            required: true,
        },
        sample: {
            type: [Object, Array],
            required: false,
        },
        files: {
            type: Object as PropType<{ data: []; sample: File }>,
            required: true,
        },
        activeTab: {
            type: Number,
            required: true,
        },
        completed: {
            type: Boolean,
            default: true,
        },
        isFinalized: {
            type: Boolean,
            default: false,
        },
        jobConfig: {
            type: Object as PropType<{ basePath: string; multiple: boolean; selectedItems: [] }>,
            required: false,
        },
        basePath: {
            type: String,
            required: true,
        },
        running: {
            type: Boolean,
            default: true,
        },

        pipelineFinalized: {
            type: Boolean,
            required: true,
        },
        isOnUpdate: {
            type: Boolean,
            default: false,
        },
        loadingFinalization: {
            type: Boolean,
            default: false,
        },
    },
    directives: {
        ClickOutside,
    },
    components: {
        FormBlock,
        ValidationProvider,
        JsonParser,
        ValidationObserver,
        TwButton,
        OrbitSpinner,
        RetrievalSettings,
        ConfirmModal,
        AlertBanner,
        TopicHierarchy,
        TopicParameter,
        DocumentIcon,
        ExclamationIcon,
        ChevronRightIcon,
    },
    setup(props, { root, emit }) {
        const topicParameter = ref<any>(null);
        const showPartialMatchModal = ref<boolean>(false);
        const showNoMatchModal = ref<boolean>(false);
        const separator = '||';
        const finalSample = ref<any>(props.configuration.processedSample);
        const { loading, exec } = useAxios(true);
        const sampleFile = computed(() => props.files.sample);
        const sampleRef = ref<any>();
        const mqttValidationRef = ref<any>();
        const completedStep = computed(() => props.completed);
        const isSampleArray = computed(() => R.is(Array, props.configuration.response.data));
        const fileTypeRef = computed(() => props.configuration.fileType);

        const { getAllPaths } = useJsonObject();

        const errorAlert: any = reactive({
            title: null,
            body: null,
            showIgnore: false,
        });

        const emptySampleResponse = ref<boolean>(false);
        const retrieveNewFileTypeSample = ref<boolean>(false);

        const securityProtocols = [
            { value: 'plain', label: 'Plain' },
            { value: 'ssl', label: 'SSL' },
        ];

        const connectionDetailsAlreadySaved = ref<any>(R.clone(props.configuration.connectionDetails));

        const {
            changeFinalSample,
            limitResponse,
            parseJSON,
            checkInvalidXML,
            parseXML,
            parseXMLString,
            clearFiles,
            reduceSampleValues,
            invalidFormat,
            acceptedFiles,
        } = useHarvester(root, emit, fileTypeRef);

        const modifyFinalSample = (sample: any) => {
            finalSample.value = sample;
            changeFinalSample(sample, props.configuration.source);
        };

        const additionalPaths = computed(() => {
            const paths = [];
            if (props.configuration.topicNameField) {
                if (isSampleArray.value) {
                    paths.push(`${props.basePath}[0]${separator}${props.configuration.topicNameField}`);
                } else {
                    paths.push(`${props.basePath}${separator}${props.configuration.topicNameField}`);
                }
            }

            return paths;
        });

        const additionalDataKeyValue = computed(() =>
            props.configuration.topicNameField ? { [props.configuration.topicNameField]: 'SUB_TOPIC' } : {},
        );

        const hasSameSubtopics = computed(() => {
            const subtopics: any = props.configuration.connectionDetails?.topics
                ?.filter((subtopic: MqttTopic) => subtopic.name !== `${props.configuration.connectionDetails.topic}/`)
                .map((subtopic: MqttTopic) => subtopic.name);
            return !!subtopics.filter((subtopic: MqttTopic, index: number) => subtopics.indexOf(subtopic) !== index)
                .length;
        });

        const selection = computed(() =>
            props.configuration.response.selectedItems.filter((item: string) => item.includes(separator)),
        );

        const connectionDetailsChanged = computed(
            () =>
                JSON.stringify(connectionDetailsAlreadySaved.value) !==
                JSON.stringify(props.configuration.connectionDetails),
        );

        const sampleFileIsRequired = computed(
            () =>
                !sampleFile.value &&
                !props.configuration.isSampleUploaded &&
                emptySampleResponse.value &&
                !connectionDetailsChanged.value,
        );

        const testCredentialsAndCreateSample = () => {
            const connectionDetails = props.configuration.connectionDetails;
            const fileType = props.configuration.fileType;

            exec(MqttAPI.testCredentialsAndCreateSample(connectionDetails, fileType))
                .then((res: any) => {
                    finalSample.value = null;
                    if (!res.data || (fileType === 'json' && !res.data.length)) {
                        emptySampleResponse.value = true;
                        errorAlert.title = 'Failed to retrieve sample.';
                        errorAlert.body =
                            'Please upload a sample in the "Sample Streaming Data Upload" section below. Important note: the sample must be an exact match of the messages that will be published in the specific MQTT topic.';
                    } else {
                        const data = fileType === 'xml' ? parseXMLString(res.data.toString()) : res.data;
                        props.configuration.response.data = reduceSampleValues(limitResponse(data, 20)); // eslint-disable-line no-param-reassign
                        emit('sample-consumed', props.configuration.response.data);
                        if (
                            (!props.isOnUpdate || props.configuration.response.isReset) &&
                            props.configuration.response.selectedItems
                        )
                            props.configuration.response.selectedItems.splice(0);
                        if (
                            props.configuration.response.data &&
                            props.isOnUpdate &&
                            selection.value.length &&
                            !props.configuration.response.isReset
                        ) {
                            const allPaths = getAllPaths(props.configuration.response.data, '', 'res', separator);
                            const missingFields = selection.value.filter((field: string) => !allPaths.includes(field));
                            if (missingFields.length === selection.value.length) {
                                showNoMatchModal.value = true;
                                return;
                            } else if (missingFields.length) {
                                showPartialMatchModal.value = true;
                                return;
                            }
                        }
                        emit('next-tab');
                    }
                    emit('save-changes');
                })
                .catch((error: any) => {
                    if (error && error.response?.data) {
                        const data = error.response.data;
                        errorAlert.title = 'Failed action';
                        errorAlert.body = data.message ? `Testing failed with message: ${data.message}` : null;
                    }

                    (root as any).$toastr.e('PubSub mechanism connection failed', 'Error');
                });
        };

        const validateAndProceed = async () => {
            if (!mqttValidationRef.value) return;

            const valid = await mqttValidationRef.value.validate();
            if (!valid) return;
            const inclusiveDate = dayjs(props.configuration.retrieval.endDate).add(1, 'day'); // add 1 extra day in order to make it inclusive
            if (!props.pipelineFinalized && inclusiveDate.isBefore(dayjs().utc())) {
                (root as any).$toastr.e(
                    'Retrieve Until Date is in the past. Please update it accordingly to continue.',
                    'Invalid Retrieve Until Date',
                );
            } else {
                if (
                    connectionDetailsChanged.value ||
                    (retrieveNewFileTypeSample.value && !props.configuration.isSampleUploaded)
                ) {
                    emit('sample-uploaded', null);
                    emit('files-changed', { sample: null });
                    emit('reset-response-data');
                    changeFinalSample(null, props.configuration.source);
                    if (
                        (!props.isOnUpdate || props.configuration.response.isReset) &&
                        props.configuration.response.selectedItems
                    )
                        props.configuration.response.selectedItems.splice(0);
                    finalSample.value = null;
                    emptySampleResponse.value = false;
                    retrieveNewFileTypeSample.value = false;
                } else if (
                    // if external streaming, on update (e.g. cloned) and no sample exists, reset sample
                    [HarvesterSourceType.ExternalMQTT, HarvesterSourceType.ExternalKafka].includes(
                        props.configuration.source,
                    ) &&
                    props.isOnUpdate &&
                    (!props.sample ||
                        (R.is(Array, props.sample) && !props.sample?.length) ||
                        !Object.keys(props.sample as Object).length)
                )
                    emit('sample-uploaded', null);
                emit('update-connection-details', props.configuration.connectionDetails);
                connectionDetailsAlreadySaved.value = R.clone(props.configuration.connectionDetails);
                errorAlert.title = null;
                errorAlert.body = null;
                emptySampleResponse.value = false;
                if (
                    !emptySampleResponse.value &&
                    !props.configuration.isSampleUploaded &&
                    !props.configuration.response.data &&
                    !props.isFinalized
                ) {
                    testCredentialsAndCreateSample();
                } else {
                    // eslint-disable-next-line no-param-reassign
                    props.configuration.response.data = limitResponse(props.configuration.response.data, 20);
                    emit('next-tab');
                }
            }
        };

        const confirmSelectionChange = (reset: boolean) => {
            if (props.configuration.isSampleUploaded)
                props.configuration.response.data = limitResponse(props.sample, 20);
            props.configuration.response.isReset = true;
            if (reset) props.configuration.response.selectedItems.splice(0);
            else {
                const allPaths = getAllPaths(props.configuration.response.data, '', 'res', separator);
                props.configuration.response.selectedItems = selection.value.filter((field: string) =>
                    allPaths.includes(field),
                );
            }
            showNoMatchModal.value = false;
            showPartialMatchModal.value = false;
            errorAlert.title = null;
            errorAlert.body = null;
            emptySampleResponse.value = false;
            emit('next-tab');
        };

        const cancelSelectionChange = () => {
            if (props.configuration.isSampleUploaded) {
                emit('files-changed', { sample: null });
                emit('sample-uploaded', null);
            }
            props.configuration.response.data = null;
            showPartialMatchModal.value = false;
            showNoMatchModal.value = false;
        };

        const validate = async () =>
            mqttValidationRef.value.validate() && topicParameter.value?.isParameterValid() && !hasSameSubtopics.value;

        const sampleUploaded = async (event: any) => {
            const file = event.target.files[0];
            if (file.size > SAMPLE_LIMIT) {
                (root as any).$toastr.e(`The sample file should not exceed 500KB.`, 'Sample file too large!');
                emit('files-changed', { sample: null });
                return;
            }
            await emit('sample-cropped', false);
            switch (props.configuration.fileType) {
                case 'json':
                    await emit('files-changed', { sample: file });
                    await parseJSON(file);
                    if (!props.sample) {
                        emptySampleResponse.value = true;
                    }
                    break;
                case 'xml':
                    await checkInvalidXML(file);
                    if (invalidFormat.value) {
                        emptySampleResponse.value = true;
                        emit('files-changed', { sample: null });
                        emit('sample-uploaded', null);
                        (root as any).$toastr.e('Invalid xml format!', 'Error');
                    } else {
                        await emit('files-changed', { sample: file });
                        await parseXML(file);
                    }
                    break;
                default:
                // Do nothing
            }
            props.configuration.response.data = props.sample;
            if (props.sample && props.isOnUpdate && selection.value.length && !props.configuration.response.isReset) {
                const allPaths = getAllPaths(props.sample, '', 'res', separator);
                const missingFields = selection.value.filter((field: string) => !allPaths.includes(field));
                if (missingFields.length === selection.value.length) {
                    showNoMatchModal.value = true;
                    return;
                } else if (missingFields.length) {
                    showPartialMatchModal.value = true;
                    return;
                }
            }
            await validate();
        };

        const jobConfig = computed(() => {
            if (props.configuration.response.data && props.basePath) {
                const { basePath }: { basePath: string | null } = props;
                return {
                    basePath,
                    multiple: isSampleArray.value,
                    selectedItems: props.configuration?.response?.selectedItems || [],
                };
            }

            return null;
        });

        const resetFileFormat = async () => {
            clearFiles();
            await emit('sample-uploaded', null);
            retrieveNewFileTypeSample.value = true;
        };

        const sampleRetrievedFromDescription = computed(() =>
            props.configuration.isSampleUploaded
                ? 'Sample uploaded by the user'
                : 'MQTT response retrieved when testing the MQTT connection',
        );

        const sampleSummaryText = computed(() =>
            props.configuration.isSampleUploaded ? 'Uploaded Sample' : 'MQTT Response',
        );

        const sampleMayBeCroppedMessage = computed(() =>
            props.configuration.fileType
                ? '- Sample may be cropped if required. Ensure that a small sample contains all necessary fields.'
                : '',
        );

        const sampleCroppedMessage = computed(() => (props.configuration.isSampleCropped ? 'cropped, ' : ''));

        const displaySampleIsArrayBanner = computed(
            () =>
                isSampleArray.value &&
                !props.isFinalized &&
                !props.loadingFinalization &&
                props.configuration.isSampleUploaded,
        );

        const selectedSecurityProtocol = computed(
            () =>
                securityProtocols.find(
                    (securityProtocol: { value: string; label: string }) =>
                        securityProtocol.value === props.configuration.connectionDetails?.securityProtocol,
                )?.label,
        );

        watch(
            () => jobConfig.value,
            (config: any) => {
                if (!props.completed) {
                    emit('job-config-change', config);
                }
            },
        );

        watch(
            () => connectionDetailsChanged.value,
            async () => {
                if (emptySampleResponse.value) await mqttValidationRef.value.validate();
            },
        );

        return {
            mqttValidationRef,
            formatBytes,
            sampleFile,
            sampleRef,
            validate,
            validateAndProceed,
            securityProtocols,
            isSampleArray,
            separator,
            finalSample,
            modifyFinalSample,
            loading,
            errorAlert,
            sampleUploaded,
            acceptedFiles,
            clearFiles,
            emptySampleResponse,
            retrieveNewFileTypeSample,
            resetFileFormat,
            sampleRetrievedFromDescription,
            sampleSummaryText,
            sampleCroppedMessage,
            sampleMayBeCroppedMessage,
            completedStep,
            showPartialMatchModal,
            showNoMatchModal,
            confirmSelectionChange,
            cancelSelectionChange,
            displaySampleIsArrayBanner,
            ProcessingOptions,
            connectionDetailsChanged,
            sampleFileIsRequired,
            protocolVersionOptions,
            hasSameSubtopics,
            topicParameter,
            selectedSecurityProtocol,
            additionalDataKeyValue,
            additionalPaths,
            R,
        };
    },
});
