























































































































































































































































































import { FormBlock, LoadingCloneModal, Scrollbar } from '@/app/components';
import { useAxios, useFeatureFlags } from '@/app/composable';
import { useRoute } from '@/app/composable/router';
import store from '@/app/store';
import { maxLengthValidator, requiredValidator } from '@/app/validators';
import { AuthzResourceType } from '@/modules/access-policy/constants';
import { AccessLevel, AccessLevelsExtensiveOptions } from '@/modules/access-policy/constants/access-levels.constants';
import { GeneralPolicy } from '@/modules/access-policy/models';
import { computed, defineComponent, onMounted, onUnmounted, ref } from '@vue/composition-api';
import { OrbitSpinner } from 'epic-spinners';
import * as R from 'ramda';
import { ValidationObserver, ValidationProvider, extend } from 'vee-validate';
import { JobsAPI, RunnerAPI } from '../api';
import { DCJStep, StatusCode } from '../constants';

extend('required', requiredValidator);
extend('max', maxLengthValidator);

interface Step {
    id: number;
    order: number;
}

export default defineComponent({
    name: 'ConfigureJob',
    metaInfo() {
        return {
            title: `${(this as any).actionText} Data Check-in Pipeline`,
        };
    },
    props: {
        id: {
            type: [Number, String],
            required: false,
        },
        queryParams: {
            type: String,
            default: '{}',
        },
    },
    components: {
        FormBlock,
        OrbitSpinner,
        ValidationObserver,
        ValidationProvider,
        Scrollbar,
        LoadingCloneModal,
    },
    setup(props, { root }) {
        const jobRef = ref<any>(null);
        const accessLevel = ref<any>(AccessLevel.OrganisationLevel);
        const accessPolicies = ref<any>({ generalPolicy: GeneralPolicy.DENY_ALL, policies: [] });
        const loadingCloning = ref<boolean>(false);

        const { flag } = useFeatureFlags();
        const isOnPremiseRunnerEnabled = flag('on-premise');

        const isNew = computed(() => props.id === undefined);
        const route = useRoute();
        const user = computed(() => store.state.auth.user);

        const isClone = computed(() => route.name === 'data-checkin-jobs:clone');
        const actionText = computed(() => (isClone.value ? 'Clone' : isNew.value ? 'Create' : 'Update'));

        const id: number | null = props.id ? parseInt(`${props.id}`, 10) : null;
        const job = ref({
            name: '',
            description: '',
            runnerId: null,
            version: 'v1.0',
            policies: [],
            accessLevel: accessLevel.value,
            createdById: null,
            assetId: null,
            workflow: null,
        }) as any;
        const stepTypes = ref<any>([]);
        const enabledSteps = ref<Step[]>([]);
        const runners = ref<any[] | null>(null);
        const execution = ref<string>('cloud');
        const selectedRunner = ref<any>(null);
        const previousAccessLevel = ref<string | null>(null);
        const previousAccessPolicies = ref<any>([]);
        const isFileUpload = ref<boolean>(false);
        const { exec, loading, error } = useAxios(true);

        const runnerId = computed(() => {
            if (execution.value === 'local') {
                return selectedRunner.value;
            }
            return null;
        });

        const isOnPremise = computed(() => {
            return execution.value === 'local';
        });

        const isUserJobCreator = computed(() => user.value.id === job.value.createdById);

        // reset selected runner in case it does not exist
        const resetRunner = () => {
            if (
                selectedRunner.value &&
                runners.value &&
                !runners.value.find((runner: any) => runner.id === selectedRunner.value)
            )
                selectedRunner.value = null;
        };

        // Load job (if an id is provided) and load enabledSteps
        if (id) {
            exec(JobsAPI.get(id))
                .then((res: any) => {
                    job.value = res.data;

                    // in case of job cloning reset policies
                    if (isClone.value) {
                        job.value.policies = [];
                    }

                    if (!isClone.value && job.value?.workflow?.status === StatusCode.Suspended) {
                        (root as any).$toastr.w(
                            `This data check-in pipeline is suspended as its output asset (dataset) was deleted. You need to edit the Loader step and configure a new output asset in order to be able to use this pipeline again`,
                            'Warning',
                        );
                        root.$router.push({ name: 'data-checkin-jobs', query: JSON.parse(props.queryParams) });
                    }

                    previousAccessLevel.value = job.value.accessLevel;
                    previousAccessPolicies.value = job.value.policies;
                    selectedRunner.value = job.value.runnerId;
                    execution.value = selectedRunner.value ? 'local' : 'cloud';
                    resetRunner();
                    exec(JobsAPI.getJobSteps(id)).then((resSteps: any) => {
                        const steps = R.sort(R.ascend<any>(R.prop('order')), resSteps.data);

                        if (steps.length && steps[0].configuration)
                            isFileUpload.value =
                                steps[0].configuration.source === 'file' && steps[0].configuration.fileType !== 'other';

                        enabledSteps.value = steps.reduce((result: Step[], obj: any) => {
                            result.push({ id: obj.dataCheckinStepTypeId, order: obj.order });
                            return result;
                        }, []);
                    });
                })
                .catch((e) => {
                    if (e.response) {
                        switch (e.response.status) {
                            case 404:
                                (root as any).$toastr.e('The Data Check-in Pipeline was not found', 'Error');
                                break;
                            case 403:
                                (root as any).$toastr.e('Access to the Data Check-in Pipeline is forbidden', 'Error');
                                break;
                            default:
                                (root as any).$toastr.e('Retrieving Data Check-in Pipeline failed', 'Error');
                        }
                    }
                    root.$router.push({ name: 'data-checkin-jobs', query: JSON.parse(props.queryParams) });
                });
        }

        // Load step types and add non-optional steps to the enabledSteps
        exec(JobsAPI.getStepTypes()).then((response: any) => {
            stepTypes.value = response.data;
            if (!id) {
                response.data.forEach((step: any) => {
                    if (!step.isOptional) {
                        enabledSteps.value.push({ id: step.id, order: step.order });
                    }
                });
            }
        });

        if (isOnPremiseRunnerEnabled.value)
            // Load registered runners
            exec(RunnerAPI.all()).then((response: any) => {
                runners.value = response.data;
                resetRunner();
            });

        // Methods
        const cancel = async () => {
            root.$router.push({ name: 'data-checkin-jobs', query: JSON.parse(props.queryParams) });
        };

        const validForms = ref<any>({
            accessLevelDetails: false,
        });

        const submitForms = () => {
            validForms.value = {
                accessLevelDetails: false,
            };
            (root as any).$formulate.submit('accessLevelDetails');
        };

        const formSubmitted = async (name: string) => {
            validForms.value[name] = true;
            await saveChanges();
        };

        const isFormulateFormsValid = computed(() =>
            Object.values(validForms.value).every((value: any) => value === true),
        );

        const saveChanges = async () => {
            const valid = await jobRef.value.validate();

            if (valid && isFormulateFormsValid.value) {
                // keep previous access level and policies before updates
                previousAccessLevel.value = R.clone(job.value.accessLevel);
                previousAccessPolicies.value = R.clone(job.value.policies);

                const newJob: any = {
                    ...job.value,
                    // policies: getAccessPoliciesJSON(),
                    enabledSteps: enabledSteps.value,
                    runnerId: runnerId.value,
                    config: {},
                    accessLevel: accessLevel.value,
                    policies: accessPolicies.value?.policies?.add,
                };

                if (id) {
                    if (isClone.value) {
                        loadingCloning.value = true;
                        try {
                            await exec(JobsAPI.clone(id, newJob));
                            loadingCloning.value = false;
                        } catch (e: any) {
                            if (e?.response?.status && e.response.status === 400) {
                                error.value = e;
                            }
                            loadingCloning.value = false;
                        }
                    } else {
                        job.value.accessLevel = accessLevel.value;
                        const policies = {
                            addPolicies: accessPolicies.value.policies.add,
                            removePolicies: accessPolicies.value.policies.remove,
                        };

                        try {
                            await exec(JobsAPI.update(id, { ...job.value, ...policies } as any));
                        } catch (e: any) {
                            if (e?.response?.status && e.response.status === 400) {
                                error.value = e;
                                // on error reset pipeline's access level and policies
                                job.value.accessLevel = previousAccessLevel.value as AccessLevel;
                                job.value.policies = previousAccessPolicies.value;
                            }
                        }
                    }
                } else {
                    await exec(JobsAPI.create(newJob));
                }

                if (!error.value) {
                    root.$router.push({ name: 'data-checkin-jobs', query: JSON.parse(props.queryParams) });
                }
            }
        };

        const checkSteps = () => {
            stepTypes.value.forEach((type: any) => {
                if (
                    type.prerequisiteStepId &&
                    enabledSteps.value.find((enStep: any) => enStep.id === type.id) &&
                    !enabledSteps.value.find((enStep: any) => enStep.id === type.prerequisiteStepId)
                ) {
                    const idx = enabledSteps.value.findIndex((enStep: any) => enStep.id === type.id);
                    enabledSteps.value.splice(idx, 1);
                    checkSteps();
                }
            });
        };

        const checkOnPremiseSteps = () => {
            if (isOnPremise.value) {
                const mappingStep = stepTypes.value.find((step: any) => step.name === DCJStep.Mapping);
                const idx = enabledSteps.value.findIndex((enStep: any) => enStep.id === mappingStep.id);
                if (idx === -1) {
                    enabledSteps.value.push({ id: mappingStep.id, order: mappingStep.order });
                }
            } else {
                const encryptionStep = stepTypes.value.find((step: any) => step.name === DCJStep.Encryption);
                const idx = enabledSteps.value.findIndex((enStep: any) => enStep.id === encryptionStep.id);
                if (idx !== -1) {
                    enabledSteps.value.splice(idx, 1);
                }
            }
        };

        const isStepDisabled = (item: any) => {
            // make sure mapping option is disabled on cloning a data check-in pipeline
            if (isClone.value) {
                const mappingStep = stepTypes.value.find((step: any) => step.name === DCJStep.Mapping);

                return (
                    item.name === DCJStep.Mapping ||
                    (item.name === DCJStep.Encryption && !isOnPremise.value) ||
                    !enabledSteps.value.find((step) => step.id === mappingStep.id)
                );
            }

            return (
                !isNew.value ||
                (item.prerequisiteStepId && !enabledSteps.value.find((step) => step.id === item.prerequisiteStepId)) ||
                (item.name === DCJStep.Mapping && isOnPremise.value) ||
                (item.name === DCJStep.Encryption && !isOnPremise.value)
            );
        };

        // TODO: hide for now
        // const getAccessPoliciesJSON = () => {

        //     const json = [];

        //     if (accessLevel.value === AccessLevel.OrganisationLevel) {
        //         const policy: ExceptionPolicy = new ExceptionPolicy(true, [
        //             new IndividualCondition(Field.ORGANISATION_ID, Operant.EQUALS, [
        //                 new ConditionValue(user.value.organisationId, Field.ORGANISATION_ID.key),
        //             ]),
        //         ]);
        //         json.push(policy.toJSON());
        //     } else if (accessLevel.value === AccessLevel.SelectiveSharing) {
        //         const policy: ExceptionPolicy = new ExceptionPolicy(true, [
        //             new IndividualCondition(Field.ORGANISATION_ID, Operant.EQUALS, [
        //                 new ConditionValue(user.value.organisationId, Field.ORGANISATION_ID.key),
        //             ]),
        //         ]);
        //         json.push(policy.toJSON());
        //         accessPolicies.value.policies.forEach((exceptionPolicy: ExceptionPolicy) => {
        //             json.push(exceptionPolicy.toJSON());
        //         });
        //     } else {
        //         accessPolicies.value.policies.forEach((policy: ExceptionPolicy) => {
        //             json.push(policy.toJSON());
        //         });
        //     }

        //     return json;
        // };

        const lockDcj = async (jobId: number) => {
            exec(JobsAPI.lock(jobId))
                .then(() => {
                    return;
                })
                .catch((e: { response: { status: any } }) => {
                    if (e.response) {
                        switch (e.response.status) {
                            case 403:
                                (root as any).$toastr.e(
                                    'The Data Check-in Pipeline is locked by another user',
                                    'Error',
                                );
                                break;
                            default:
                                (root as any).$toastr.e('Retrieving Data Check-in Pipeline failed', 'Error');
                        }
                    }
                    root.$router.push({ name: 'data-checkin-jobs', query: JSON.parse(props.queryParams) });
                });
        };

        const customError = computed<{ title: string; message: string | { assetId: number } }>(() => {
            if (error.value?.response?.status === 403) {
                return {
                    title: 'Access Forbidden!',
                    message: 'You do not have access to edit the specific data checkin pipeline',
                };
            } else if (
                error.value?.response?.status === 400 &&
                error.value.response?.data?.message === 'Access Policy Restriction'
            ) {
                return {
                    title: 'Access Policy Restriction!',
                    message: {
                        assetId: job.value.assetId,
                    },
                };
            }

            return { title: 'An error has occurred!', message: error.value?.response?.data?.message };
        });

        const unlockJob = async () => {
            if (!isNew.value && !isClone.value) await exec(JobsAPI.unlock(Number(props.id)));
        };

        onMounted(async () => {
            if (!isNew.value && !isClone.value) await lockDcj(Number(props.id));

            window.addEventListener('beforeunload', unlockJob);
        });

        onUnmounted(async () => {
            unlockJob();
        });

        return {
            actionText,
            cancel,
            enabledSteps,
            error,
            isNew,
            job,
            jobRef,
            loading,
            saveChanges,
            submitForms,
            stepTypes,
            checkSteps,
            runners,
            execution,
            runnerId,
            selectedRunner,
            isOnPremise,
            checkOnPremiseSteps,
            isStepDisabled,
            isClone,
            AccessLevelsExtensiveOptions,
            accessLevel,
            AccessLevel,
            user,
            accessPolicies,
            isUserJobCreator,
            formSubmitted,
            previousAccessLevel,
            customError,
            loadingCloning,
            AuthzResourceType,
            isFileUpload,
            isOnPremiseRunnerEnabled,
        };
    },
});
