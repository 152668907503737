





















































import { defineComponent, ref } from '@vue/composition-api';
import { extend } from 'vee-validate';
import { HarvesterSourceType } from '@/modules/data-checkin/constants';
import KafkaConfiguration from './KafkaConfiguration.vue';
import MqttConfiguration from './MqttConfiguration.vue';
import { PubSubMechanismOptions } from '../../components';
import { minValueValidator, maxValueValidator, requiredValidator } from '@/app/validators';

extend('required', requiredValidator);
extend('min_value', minValueValidator);
extend('max_value', maxValueValidator);

export default defineComponent({
    name: 'StreamingConfiguration',
    model: {
        prop: 'configuration',
    },
    props: {
        configuration: {
            type: Object,
            required: true,
        },
        sample: {
            type: [Object, Array],
            required: false,
        },
        files: {
            type: Object,
            required: true,
        },
        activeTab: {
            type: Number,
            required: true,
        },
        jobId: {
            type: Number,
            required: true,
        },
        completed: {
            type: Boolean,
            default: true,
        },
        running: {
            type: Boolean,
            default: true,
        },
        initializing: {
            type: Boolean,
            default: false,
        },
        isDeprecated: {
            type: Boolean,
            default: false,
        },
        workflowId: {
            type: String,
            required: true,
        },
        jobCreatedById: {
            type: Number,
        },
        pipelineFinalized: {
            type: Boolean,
            required: true,
        },
        stepId: {
            type: Number,
            required: true,
        },
        isFinalized: {
            type: Boolean,
            default: false,
        },
        loadingFinalization: {
            type: Boolean,
            default: false,
        },
    },
    components: {
        KafkaConfiguration,
        PubSubMechanismOptions,
        MqttConfiguration,
    },
    setup(props) {
        const mqttConfigurationRef = ref<any>();

        const validate = async () =>
            props.configuration.mechanism === HarvesterSourceType.MQTT ? mqttConfigurationRef.value.validate() : true;

        return {
            mqttConfigurationRef,
            HarvesterSourceType,
            validate,
        };
    },
});
