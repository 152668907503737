




















































































import { WizardTabs } from '@/app/components';
import { useAxios, useErrors, useJsonObject, useQuery, useResult } from '@/app/composable';
import { S } from '@/app/utilities';
import { StatusCode } from '@/modules/data-checkin/constants';
import { computed, defineComponent, onMounted, onUnmounted, ref } from '@vue/composition-api';
import { OrbitSpinner } from 'epic-spinners';
import * as R from 'ramda';
import { JobsAPI } from '../../api';
import StepCompletionModal from '../../components/StepCompletionModal.vue';
import { useSampleFields, useStep } from '../../composable';
import GET_JOB from '../../graphql/getJob.graphql';
import { EncryptionConfig } from './encryption.config';
import EncryptionConfiguration from './EncryptionConfiguration.vue';

export default defineComponent({
    name: 'Encryption',
    metaInfo() {
        return { title: `Encrypting${(this as any).job ? ` for: ${(this as any).job.name}` : ''}` };
    },
    props: {
        id: {
            type: [Number, String],
            required: true,
        },
        queryParams: {
            type: String,
            default: '{}',
        },
    },
    components: {
        EncryptionConfiguration,
        OrbitSpinner,
        WizardTabs,
        StepCompletionModal,
    },
    setup(props, { root }) {
        const { loading, error, exec } = useAxios(true);
        const jobId = parseInt(`${props.id}`, 10);
        const steps = ref([{ title: 'Configuration' }]);
        const message = ref<any>(null);

        const hasChanges = ref<boolean>(false);
        const activeTab = ref(0);
        const showConfirmModal = ref<boolean>(false);
        const restartedStep = ref<boolean>(false);
        const showFinalizeModal = ref<boolean>(false);
        const loadingFinalization = ref<boolean>(false);

        const nextStep = ref<any>(null);
        const mode = ref<string | null>(null);
        const isMacOS = window.navigator.userAgent.indexOf('Mac OS') !== -1;
        const { extractMappingFieldNames, extractAlternateNames } = useSampleFields();

        // Fetch job information
        const { checkGQLAuthentication } = useErrors(root.$route);
        const { loading: jobLoading, error: jobError, result, onError, refetch } = useQuery(
            GET_JOB,
            {
                id: jobId,
            },
            { fetchPolicy: 'no-cache' },
        );
        onError(checkGQLAuthentication);
        const job = useResult(result, null, (data: any) => data.job);
        const alternateNames = ref<any>(null);

        const currentEncryptionConfiguration = ref<EncryptionConfig | any>(null);

        // Fetch encryption configuration
        const encryption = ref<any>(null);
        const {
            isConfigEmpty,
            isFinalized,
            getNextStep,
            updateAssetAfterFailedStep,
            canRestart,
            setupUpdatedConfiguration,
            getSchema,
        } = useStep(encryption, job, root);

        const { getFixedJSON } = useJsonObject();

        const fixedSample = computed(() => {
            if (job.value && job.value.sample) {
                return getFixedJSON(job.value.sample);
            }
            return [];
        });

        // Default (empty) configuration
        const configuration = ref<EncryptionConfig>({
            fields: [],
            schema: null,
        });

        const updateIndex = (fields: any) => {
            configuration.value.fields = fields;
        };

        exec(JobsAPI.getStep(jobId, 'encryption')).then((resEncryption: any) => {
            encryption.value = resEncryption.data;
            if (encryption.value.message) {
                message.value = encryption.value.message;
            }

            exec(JobsAPI.getStep(jobId, 'mapping')).then(async (mapping: any) => {
                if (!isConfigEmpty(mapping.data.configuration)) {
                    alternateNames.value = extractAlternateNames(mapping.data.configuration);
                    if (isConfigEmpty(resEncryption.data.configuration)) {
                        const conf: any = { fields: [], schema: null };
                        const mappingFields = extractMappingFieldNames(mapping.data.configuration.fields);

                        getSchema().then((resSchema: any) => {
                            const { conceptUids } = resSchema;
                            conf.schema = resSchema.schema;
                            mappingFields.forEach((field: any) => {
                                const obj = R.clone(field);
                                obj.index = false;
                                obj.uid = conceptUids[field.id].uid;
                                let currentConcept = conf.schema[field.path[0]];
                                for (let i = 1; i < field.path.length; i++) {
                                    const fieldName = field.path[i];
                                    currentConcept = currentConcept.children.find(
                                        (concept: any) =>
                                            concept.key === fieldName ||
                                            (fieldName.endsWith('[]') &&
                                                fieldName.substring(0, fieldName.length - 2) === concept.key),
                                    );
                                }
                                const fieldInSchema = currentConcept.children.find(
                                    (concept: any) =>
                                        concept.key === field.title ||
                                        (field.title.endsWith('[]') &&
                                            field.title.substring(0, field.title.length - 2) === concept.key),
                                );
                                if (fieldInSchema) {
                                    const { indexES } = fieldInSchema;
                                    obj.canBeIndexed = indexES;
                                } else {
                                    // field dropped due to anonymisation rule
                                    obj.canBeIndexed = false;
                                    obj.dropped = true;
                                }
                                conf.fields.push(obj);
                            });

                            configuration.value = R.clone(conf);
                        });
                    } else {
                        configuration.value = R.clone(resEncryption.data.configuration);

                        if (encryption.value.status === StatusCode.Update) {
                            /**
                             * check if any fields have been added/ removed/ modified after
                             * revised mapping and update the encryption configuration
                             */
                            configuration.value = await setupUpdatedConfiguration(
                                mapping.data.configuration.fields,
                                configuration.value,
                            );
                            currentEncryptionConfiguration.value = R.clone(configuration.value);
                        }
                    }
                }
                refetch(); // refetch job
            });
        });

        const save = async () => {
            try {
                await exec(
                    JobsAPI.updateStep(encryption.value.id, {
                        configuration: configuration.value,
                        serviceVersion: process.env.VUE_APP_ENCRYPTION_VERSION,
                    }),
                );
                (root as any).$toastr.s('Encryption configuration saved successfuly', 'Success');
                hasChanges.value = false;
            } catch (e) {
                (root as any).$toastr.e('Saving encryption configuration failed', 'Failed');
                hasChanges.value = true;
            }
        };

        const finalize = () => {
            loadingFinalization.value = true;
            exec(
                JobsAPI.updateStep(encryption.value.id, {
                    configuration: configuration.value,
                    serviceVersion: process.env.VUE_APP_ENCRYPTION_VERSION,
                }),
            ).then(() => {
                getNextStep().then(async (stepTypeResponse: any) => {
                    nextStep.value = stepTypeResponse;
                    /**
                     * If loader step (order = 100) has a different status than "configuration",
                     * it means that the Asset has already been created
                     */
                    if (
                        encryption.value.status === StatusCode.Update &&
                        nextStep.value.order === 100 &&
                        nextStep.value.status !== StatusCode.Configuration
                    ) {
                        refetch();
                        if (job.value?.asset && job.value.asset.id) {
                            await updateAssetAfterFailedStep(job.value);
                            await exec(JobsAPI.finalize(encryption.value.id));
                            restartedStep.value = true;
                        } else {
                            (root as any).$toastr.e(
                                'Failed finalising revised Encryption step due to an error',
                                'Failed',
                            );
                        }
                    } else {
                        await exec(JobsAPI.finalize(encryption.value.id));
                        showFinalizeModal.value = true;
                    }
                    loadingFinalization.value = false;
                });
            });
        };

        const cancel = () => {
            root.$router.push({ name: 'data-checkin-jobs', query: JSON.parse(props.queryParams) });
        };

        const restartStep = async () => {
            await exec(JobsAPI.restartStep(encryption.value.id)).then(() => {
                exec(JobsAPI.finalize(encryption.value.id))
                    .then(() => {
                        (root as any).$toastr.s(
                            `Data Check-in Pipeline "${S.sanitizeHtml(job.value.name)}" is now restarting.`,
                            'Success',
                        );
                        root.$router.push({ name: 'data-checkin-jobs', query: JSON.parse(props.queryParams) });
                    })
                    .catch(() => {
                        (root as any).$toastr.e('Restarting of the Data Check-in Pipeline failed', 'Failed');
                    });
            });
        };

        const stepStatus = computed(() =>
            encryption.value && encryption.value.status ? encryption.value.status : StatusCode.Configuration,
        );

        const pageLoading = computed(() => {
            return loadingFinalization.value || loading.value || jobLoading.value;
        });

        const unlockJob = async () => {
            await exec(JobsAPI.unlock(Number(props.id)));
        };

        onMounted(async () => {
            window.addEventListener('beforeunload', unlockJob);
        });

        onUnmounted(async () => {
            unlockJob();
        });

        return {
            activeTab,
            cancel,
            configuration,
            error,
            finalize,
            hasChanges,
            isFinalized,
            job,
            jobError,
            jobLoading,
            loading,
            encryption,
            save,
            steps,
            showFinalizeModal,
            nextStep,
            fixedSample,
            mode,
            message,
            isMacOS,
            showConfirmModal,
            StatusCode,
            updateIndex,
            canRestart,
            restartStep,
            restartedStep,
            pageLoading,
            stepStatus,
            alternateNames,
        };
    },
});
