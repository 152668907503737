












































































































































































































































































import { ButtonGroup, ModelBrowser, PathBuilder, Scrollbar, SearchBox, WaitModal } from '@/app/components';
import { useExecutionErrors, useJsonObject, useModelBrowser } from '@/app/composable';
import { Concept, Model } from '@/app/interfaces';
import store from '@/app/store';
import { MappingFilter, StatusCode } from '@/modules/data-checkin/constants';
import { PropType, Ref, computed, defineComponent, provide, ref, watch } from '@vue/composition-api';
import * as R from 'ramda';
import { v4 as uuidv4 } from 'uuid';
import { MappingSelectedFields } from '../../components/mapping';
import { useMappingPrediction, useSampleFields } from '../../composable';
import { StepStats } from '../../types/step-stats.interface';
import ConceptOverviewView from './ConceptOverviewView.vue';
import ConceptView from './ConceptView.vue';
import MappingDetails from './Details.vue';
import { FieldConfiguration, FieldPrediction, MappingConfig } from './mapping.types';

export default defineComponent({
    name: 'MappingConfiguration',
    model: {
        prop: 'configuration',
        event: 'change',
    },
    components: {
        ButtonGroup,
        ConceptView,
        MappingDetails,
        WaitModal,
        Scrollbar,
        ConceptOverviewView,
        MappingSelectedFields,
        SearchBox,
        ModelBrowser,
        PathBuilder,
    },
    props: {
        tabId: {
            type: Number,
            required: true,
        },
        configuration: {
            type: Object as PropType<MappingConfig>,
            required: true,
        },
        validationErrorPerId: {
            type: Object,
            required: true,
        },
        stats: {
            type: Object as PropType<StepStats>,
            required: false,
        },
        model: {
            type: Object,
            required: true,
        },
        sample: {
            type: Array,
            required: true,
        },
        isFinalized: {
            type: Boolean,
            default: false,
        },
        hasChanges: {
            type: Boolean,
            required: true,
        },
        isLoading: {
            type: Boolean,
            default: false,
        },
        isValid: {
            type: Boolean,
            default: true,
        },
        mappedConceptsExist: {
            type: Boolean,
            default: false,
        },
        canRestart: {
            type: Boolean,
            required: true,
        },
        mappingStatus: {
            type: String,
            default: 'configuration',
        },
        basePath: {
            type: Array as PropType<string[] | undefined>,
            required: false,
            default: () => [],
        },
        lastValidation: { type: Date },
    },
    setup(props, { emit, root }) {
        const { getFixedJSON } = useJsonObject();
        const { getConceptFromId, getConceptFromUid, getModelFromId } = useModelBrowser();
        const path: Ref<Concept[]> = ref<Concept[]>([]);
        const goToConcept: Ref<string[] | undefined> = ref<string[] | undefined>();
        const modelBrowserModel: Ref<Model | undefined> = ref<Model | undefined>();
        provide('dragging', ref(false));
        const playgroundFilters = [
            { label: MappingFilter.All, tooltip: 'All concepts that appear in the source data' },
            {
                label: MappingFilter.Predicted,
                tooltip: 'The concepts for which there is an automatic prediction that needs to be verified.',
            },
            {
                label: MappingFilter.Corrected,
                tooltip: 'The concepts for which the mapping to the Common Data Model was manually corrected.',
            },
            {
                label: MappingFilter.Unidentified,
                tooltip: ' The concepts for which a proper mapping to the Common Data Model is not yet identified.',
            },
            {
                label: MappingFilter.Invalid,
                tooltip:
                    'The concepts for which there are errors in the mapping to the Common Data Model or additional information needs to be provided. They are displayed once you validate the mapping.',
            },
            {
                label: MappingFilter.Selected,
                tooltip: 'The concepts that have been manually selected and for which the sample data are displayed.',
            },
        ];
        let searchText = ref<string>('');
        const activeFilter = ref<string>('all');
        const nodes = ref([props.model]);
        const selectedMappings = ref<any[]>([]);
        const selectedNode = ref(null);
        const dragging = ref(false);
        const detailsKey = ref(uuidv4());
        const selectNode = (node: any) => {
            selectedNode.value = node;
        };
        const dataModel = props.model;
        const selectedMappingsIds: Ref<string[]> = ref<string[]>([]);
        const domainName = computed(() => {
            if (props.configuration.domain?.name) {
                return `${props.configuration.domain.name
                    .charAt(0)
                    .toUpperCase()}${props.configuration.domain.name.substring(1)}`;
            }
            return 'None';
        });
        const { errorMessage } = useExecutionErrors();
        const statsInField = (field: FieldConfiguration) => {
            if (props.stats?.successfulStats?.statsPerField?.length) {
                return props.stats.successfulStats.statsPerField.find((s) => s.id === field.source.id);
            } else if (props.mappingStatus === StatusCode.Completed && props.stats?.latestExecutionStats) {
                // step completed successfully, but failed at a later step, so we should consider latest execution stats as successful for this step
                return props.stats.latestExecutionStats.statsPerField.find((s) => s.id === field.source.id);
            }
            return null;
        };

        const failedStatsInField = (field: FieldConfiguration) => {
            if (props.mappingStatus === StatusCode.Failed && props.stats?.latestExecutionStats) {
                return props.stats.latestExecutionStats.statsPerField.find((s) => s.id === field.source.id);
            }
            return null;
        };

        const failedReasonsInField = (field: FieldConfiguration) => {
            if (props.mappingStatus === StatusCode.Failed && props.stats?.latestExecutionStats) {
                const errorCode = props.stats.latestExecutionStats.statsPerField.find((s) => s.id === field.source.id)
                    ?.errorCode;
                return errorCode ? errorMessage(errorCode).error.title : null;
            }
            return null;
        };

        const categoryName = computed(() => {
            if (props.configuration.concept?.name) {
                return `${props.configuration.concept.name
                    .charAt(0)
                    .toUpperCase()}${props.configuration.concept.name.substring(1)}`;
            }
            return 'None';
        });

        const standardName = computed(() => {
            if (props.configuration.standard?.name) {
                return `${props.configuration.standard.name
                    .charAt(0)
                    .toUpperCase()}${props.configuration.standard.name.substring(1)}`;
            }
            return 'None';
        });

        const rootConcept = computed(() => {
            return props.configuration?.concept?.id ? getConceptFromId(props.configuration?.concept?.id) : null;
        });
        const selectedConceptId = computed<number | null>(() => {
            const selectedIds = R.uniq(R.map(R.view(R.lensPath(['target', 'parentIds', -1])), selectedMappings.value));
            if (selectedIds.length === 0) return props.configuration?.concept?.id;
            if (selectedIds.length === 1) return selectedIds[0];
            return null;
        });

        const selectedConcept = computed<Concept | null>(() =>
            selectedConceptId.value ? getConceptFromId(selectedConceptId.value) : null,
        );

        const filterMapping = (option: string) => {
            activeFilter.value = option;
        };

        const setConcept = (field: any, concept: any, prediction: any = null) => {
            emit('set-concept', field, concept, prediction);
            const idx = configuration.value.fields.findIndex(
                (f: FieldConfiguration) => f.source.id === field.source.id,
            );
            if (activeFilter.value !== MappingFilter.Unidentified && !prediction) {
                selectedMappings.value = [R.clone(props.configuration.fields[idx])];
            } else {
                const index = selectedMappings.value.findIndex(
                    (sm: FieldConfiguration) => props.configuration.fields[idx].source.id === sm.source.id,
                );
                if (index >= 0) {
                    selectedMappings.value.splice(index, 1);
                }
            }
        };

        const clearMapping = (field: any, full: boolean = true) => {
            emit('clear-mapping', field.source.id, field.target, field.transformation, full);
            const idx = selectedMappings.value.findIndex((obj: any) => obj.source?.id === field.source?.id);
            if (~idx) {
                const clearedField = props.configuration.fields.find((obj: any) => obj.source?.id === field.source?.id);
                if (clearedField) {
                    selectedMappings.value.splice(idx, 1, clearedField);
                    detailsKey.value = uuidv4();
                }
            }
        };

        const includesSearchText = (value: any) => {
            return (
                !searchText.value.trim() ||
                value.source.title.toUpperCase().includes(searchText.value.toUpperCase().trim())
            );
        };

        const filteredFields = computed(() => {
            if (props.isFinalized) {
                return props.configuration.fields.filter((obj: any) => {
                    return 'target' in obj && 'id' in obj.target && obj.target.id;
                });
            }

            return props.configuration.fields.filter((obj: any) => {
                if (!includesSearchText(obj)) return false;
                switch (activeFilter.value) {
                    case MappingFilter.Predicted:
                        return obj.target.id && obj.prediction && obj.prediction.score;
                    case MappingFilter.Corrected:
                        return obj.temp && obj.temp.userDefined;
                    case MappingFilter.Unidentified:
                        return obj.target.id === null;
                    case MappingFilter.Invalid:
                        return obj.temp && obj.temp.invalid;
                    case MappingFilter.Selected:
                        return selectedMappingsIds.value.includes(obj.source?.id);
                    default:
                        return true;
                }
            });
        });

        const updateSelected = (value: any, multiple = false) => {
            const idx = selectedMappings.value.findIndex((obj) => obj.source?.id === value.source?.id);

            if (multiple) {
                if (~idx) {
                    selectedMappings.value.splice(idx, 1);
                } else {
                    selectedMappings.value.push(value);
                }
            } else {
                if (~idx && selectedMappings.value.length === 1) {
                    selectedMappings.value.splice(0);
                } else {
                    selectedMappings.value = [value];
                }
            }
        };

        const setRestrictedConcept = (conceptId?: number) => {
            // set the restricted model and domain in model browser
            // this is need to restrict what can be dragged from the
            // model browser onto the mapping configuration pane
            // it sets the selected concept parent to what is selected
            // or by default to the main concept of the mapping
            const domain: Model | null = configuration.value?.domain?.id
                ? getModelFromId(configuration.value?.domain?.id)
                : null;

            if (domain) {
                store.dispatch.modelBrowser.setMappingSelection({
                    model: domain.uid,
                    concept: conceptId || configuration.value?.concept?.id,
                });
            }
        };

        const clearSelection = () => {
            selectedMappings.value.splice(0);
            setRestrictedConcept();
        };

        const clearAllMappings = () => {
            selectedMappings.value.forEach((field: any) => clearMapping(field));
        };

        const handleEscape = (e: KeyboardEvent) => {
            if (e.key === 'Esc' || e.key === 'Escape') {
                clearSelection();
            }
        };

        const setCustomizedConcepts = (customizedConcepts: any) => {
            emit('change', { ...props.configuration, customizedConcepts });
        };

        const countMultiple = computed(() => {
            if (selectedMappings.value.length === 1) {
                const { id, path: targetPath } = selectedMappings.value[0].target;
                return props.configuration.fields.reduce((count: number, field: any) => {
                    if (field.target.id === id && R.equals(field.target.path, targetPath)) return count + 1;
                    return count;
                }, 0);
            }

            return 0;
        });

        document.addEventListener('keydown', handleEscape);
        root.$once('hook:beforeDestroy', () => {
            document.removeEventListener('keydown', handleEscape);
        });

        const configuration: Ref<MappingConfig> = computed(() => props.configuration);
        const sample = computed(() => getFixedJSON(props.sample));
        const basePath = computed(() => props.basePath);

        const { predict, loading, cancel } = useMappingPrediction(configuration, basePath);
        const { extractFieldSample } = useSampleFields();

        const selectedFields = computed(() => {
            const diff = R.difference(props.configuration.fields, selectedMappings.value);
            return R.pluck('source' as any, R.difference(props.configuration.fields, diff)).reduce(
                (fields: any[], obj: any) => {
                    fields.push({ ...obj, sample: extractFieldSample(sample.value, obj.title, obj.path) });
                    return fields;
                },
                [],
            );
        });

        const hasChange = () => {
            emit('changed', selectedMappings.value[0].source.id);
        };

        const failedStepMessage = computed(() =>
            props.mappingStatus === StatusCode.Update ? props.stats?.latestExecutionStats : null,
        );

        const performPrediction = async () => {
            const predictionConcept = selectedConceptId.value ? getConceptFromId(selectedConceptId.value) : null;
            const predictionResponse: any = await predict(
                selectedMappings.value,
                predictionConcept && predictionConcept.referenceId
                    ? predictionConcept.referenceId
                    : selectedConceptId.value,
                selectedConceptId.value ? getConceptFromId(selectedConceptId.value)?.domain : undefined,
            );
            const selections = R.clone(selectedMappings.value);
            for (let idx = 0; idx < selections.length; idx++) {
                const field: FieldConfiguration = selections[idx];
                const fieldPrediction: FieldPrediction | null = predictionResponse[field.source.id];

                if (fieldPrediction && fieldPrediction.matchings) {
                    const concept = getConceptFromId(fieldPrediction.matchings.target);
                    const prediction = fieldPrediction.matchings;
                    setConcept(field, concept, prediction);
                    emit('changed', field.source.id);
                }
            }
        };

        const setModel = (newModel: Model | undefined) => {
            modelBrowserModel.value = newModel;
        };

        const setPath = (newPath: Concept[]) => {
            path.value = newPath;
        };

        const { restrictingConcept } = useModelBrowser();

        const clearPath = () => {
            if (restrictingConcept.value) goToConcept.value = [restrictingConcept.value.uid];
        };

        const conceptClicked = (conceptUid: string) => {
            goToConcept.value = [conceptUid];
        };

        const updateModelBrowserWithSelectedField = (field: FieldConfiguration | undefined) => {
            // When the selection changes we clear the path of the model browser
            // if a valid field is foudn the we use the path uids to construct the path in the model browser
            const goToPathOfUids = [];
            if (field?.target?.pathUids) {
                for (let p = 0; p < field.target.pathUids.length; p++) {
                    const uid = field.target.pathUids[p];
                    goToPathOfUids.push(uid);
                    const fieldConcept = getConceptFromUid(uid);
                    if (fieldConcept.referenceConceptId)
                        goToPathOfUids.push(getConceptFromId(fieldConcept.referenceConceptId).uid);
                }
            }
            // finally if there is a selected concept then we select that
            if (field && field.target.id) goToPathOfUids.push(getConceptFromId(field.target.id).uid);
            if (goToPathOfUids.length > 0) goToConcept.value = goToPathOfUids;
        };

        const noTransformations = computed(() => {
            if (props.mappingStatus !== StatusCode.Failed || !props.stats?.latestExecutionStats) return false;
            return props.stats.latestExecutionStats.transformedValues === 0;
        });

        watch(
            () => selectedMappings.value,
            (mappings: FieldConfiguration[]) => {
                selectedMappingsIds.value = mappings.map((mapping: any) => mapping.source?.id);
                if (mappings.length > 0) {
                    updateModelBrowserWithSelectedField(mappings[0]);
                    emit('change', {
                        ...props.configuration,
                        fields: props.configuration.fields.map((f: FieldConfiguration) => {
                            for (let m = 0; m < mappings.length; m++) {
                                const mapping = mappings[m];
                                if (f.source.id === mapping.source.id) {
                                    return mapping;
                                }
                            }
                            return f;
                        }),
                    });
                } else if (restrictingConcept.value) {
                    goToConcept.value = [(restrictingConcept.value as Concept).uid];
                }

                setRestrictedConcept(
                    mappings.length > 0 && mappings[0]?.target.parentIds && mappings[0].target.parentIds.length > 0
                        ? mappings[0].target.parentIds[mappings[0].target.parentIds.length - 1]
                        : undefined,
                );
            },
            { deep: true },
        );

        watch(
            () => props.lastValidation,
            () => {
                if (!props.isValid) {
                    activeFilter.value = MappingFilter.Invalid;
                } else {
                    if (activeFilter.value === MappingFilter.Invalid) {
                        activeFilter.value = MappingFilter.All;
                    }
                }
                detailsKey.value = uuidv4();
            },
        );

        return {
            activeFilter,
            clearMapping,
            clearAllMappings,
            clearSelection,
            countMultiple,
            dataModel,
            filteredFields,
            filterMapping,
            nodes,
            performPrediction,
            rootConcept,
            selectedConcept,
            selectedConceptId,
            selectedFields,
            selectedMappings,
            selectedMappingsIds,
            selectedNode,
            selectNode,
            setConcept,
            setCustomizedConcepts,
            updateSelected,
            domainName,
            categoryName,
            standardName,
            dragging,
            statsInField,
            failedStatsInField,
            failedReasonsInField,
            errorMessage,
            emit,
            hasChange,
            StatusCode,
            failedStepMessage,
            playgroundFilters,
            searchText,
            goToConcept,
            setModel,
            setPath,
            clearPath,
            conceptClicked,
            path,
            modelBrowserModel,
            detailsKey,
            loading,
            cancel,
            noTransformations,
        };
    },
});
